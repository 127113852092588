var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center mt-4 mb-4"},[_c('h1',[_vm._v(" Notentermine ")])])],1),_vm._l((_vm.schools),function(school){return _c('v-row',{key:school.name},[_c('v-card',{staticClass:"mt-20 mb-20",attrs:{"width":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center mt-4 mb-4"},[(_vm.isReady)?_c('h2',[_vm._v(" "+_vm._s(school.name)+" ")]):_vm._e()])],1),_c('v-row',[_c('v-col',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ml-3 mr-3",attrs:{"elevation":hover ? 12 : 3}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('h3',[_vm._v(" erstes Halbjahr 2022/2023 ")])])],1),(!_vm.isReady)?_c('v-row',_vm._l((6),function(i){return _c('v-col',{key:("row1_skel" + i),staticClass:"d-flex justify-center",attrs:{"md":"2","cols":"12"}},[_c('v-skeleton-loader',{staticClass:"d-flex justify-center",attrs:{"type":"table-cell"}})],1)}),1):_vm._e(),(_vm.isReady)?_c('v-row',_vm._l((school.calendarEntrys.filter(function (e) { return e.term === 'HJ'; })),function(which){return _c('v-col',{key:((school.name) + "_" + (which.name)),staticClass:"d-flex justify-center",attrs:{"md":"2","cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-dialog',{ref:((school.name) + "_" + (which.name)),refInFor:true,attrs:{"return-value":which.date,"persistent":"","width":"300px"},on:{"update:returnValue":function($event){return _vm.$set(which, "date", $event)},"update:return-value":function($event){return _vm.$set(which, "date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","success-messages":which.success,"error-messages":which.error},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v(_vm._s(which.text))])]},proxy:true}],null,true),model:{value:(which.datum),callback:function ($$v) {_vm.$set(which, "datum", $$v)},expression:"which.datum"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(which.show),callback:function ($$v) {_vm.$set(which, "show", $$v)},expression:"which.show"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","show-week":"","allowed-dates":_vm.allowedDatesHJ,"scrollable":"","min":_vm.dateRange.HJ.firstDay,"max":_vm.dateRange.HJ.lastDay},model:{value:(which.date),callback:function ($$v) {_vm.$set(which, "date", $$v)},expression:"which.date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","icon":"","disabled":which.isSending},on:{"click":function () { return _vm.cancel(which); }}},[_c('v-icon',[_vm._v("mdi-cancel")])],1),_c('v-btn',{attrs:{"color":"success","icon":"","disabled":which.isSending},on:{"click":function () { return _vm.clicked({ school: school.name, which: which }); }}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1)],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(which.tooltip)}})])],1)}),1):_vm._e()],1)]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ml-3 mt-10 mr-3 mb-10",attrs:{"elevation":hover ? 12 : 3}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('h3',[_vm._v(" zweites Halbjahr 2022/2023 ")])])],1),(!_vm.isReady)?_c('v-row',_vm._l((6),function(i){return _c('v-col',{key:("row2_skel" + i),staticClass:"d-flex justify-center",attrs:{"md":"2","cols":"12"}},[_c('v-skeleton-loader',{staticClass:"d-flex justify-center",attrs:{"type":"table-cell"}})],1)}),1):_vm._e(),(_vm.isReady)?_c('v-row',_vm._l((school.calendarEntrys.filter(function (e) { return e.term === 'EJ'; })),function(which){return _c('v-col',{key:((school.name) + "_" + (which.name)),staticClass:"d-flex justify-center",attrs:{"md":"2","cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-dialog',{ref:((school.name) + "_" + (which.name)),refInFor:true,attrs:{"return-value":which.date,"persistent":"","width":"300px"},on:{"update:returnValue":function($event){return _vm.$set(which, "date", $event)},"update:return-value":function($event){return _vm.$set(which, "date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","success-messages":which.success,"error-messages":which.error},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v(_vm._s(which.text))])]},proxy:true}],null,true),model:{value:(which.datum),callback:function ($$v) {_vm.$set(which, "datum", $$v)},expression:"which.datum"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(which.show),callback:function ($$v) {_vm.$set(which, "show", $$v)},expression:"which.show"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","show-week":"","allowed-dates":_vm.allowedDatesEJ,"scrollable":"","min":_vm.dateRange.EJ.firstDay,"max":_vm.dateRange.EJ.lastDay},model:{value:(which.date),callback:function ($$v) {_vm.$set(which, "date", $$v)},expression:"which.date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","icon":"","disabled":which.isSending},on:{"click":function () { return _vm.cancel(which); }}},[_c('v-icon',[_vm._v("mdi-cancel")])],1),_c('v-btn',{attrs:{"color":"success","icon":"","disabled":which.isSending},on:{"click":function () { return _vm.clicked({ school: school.name, which: which }); }}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1)],1)],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(which.tooltip)}})])],1)}),1):_vm._e()],1)]}}],null,true)})],1)],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }