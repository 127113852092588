<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-if="grade.value !== null"
        v-bind="attrs"
        v-on="on"
        label
        :color="getColor(isSek2 ? 11 : 0, grade.value)"
        :dark="getDark(isSek2 ? 11 : 0, grade.value)"
        class="pointer mr-1"
        >
        {{ grade.value }}
      </v-chip>
      <v-chip
        v-if="grade.value === null && grade.note === 'teilgenommen'"
        v-bind="attrs"
        v-on="on"
        label
        class="pointer mr-1"
        >
        tg
      </v-chip>
    </template>
    <GradeOverviewTable v-if="grade.meta !== undefined"
      componentType="grade"
      :topic="grade.meta.topic"
      :firstName="grade.meta.teacher.firstName"
      :lastName="grade.meta.teacher.lastName"
      :note="grade.note"
      :testedAt="grade.meta.testedAt"
      :backAt="grade.meta.backAt"
      :value="grade.value"
      :classMedian="grade.meta.median"
      :classOverview="grade.meta.overview"
      :classMedianColor="getColor(isSek2 ? 11 : 0, grade.meta.median)"
      :isSek2="isSek2"
    />
    <GradeOverviewTable v-else
      componentType="externalGrade"
    />
  </v-tooltip>
</template>

<script>
import GradeOverviewTable from '../components/GradeOverviewTable'
const { getColor, getDark } = require('../../handler/tableHandler')

export default {
  name: 'SingleGradeSquare',
  components: { GradeOverviewTable },

  props: {
    grade: Object,
    isSek2: Boolean
  },
  methods: {
    getColor (level, grade) {
      return getColor(level, grade)
    },
    getDark (level, grade) {
      return getDark(level, grade)
    }
  }
}
</script>
