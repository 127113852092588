<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="400"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        @dblclick="e => { if (isHeadMaster) on.click(e) }"
        :color="getColor(level, currentgradevalue)"
        :text-color="getTextColor(level, currentgradevalue)"
        label
        :style="isHeadMaster ? 'cursor: pointer;' : ''"

      >
        {{ showGrade(currentgradevalue) }}
      </v-chip>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="warning">
          Achtung, du bist dabei die Zeugnisnote zu ändern!
        </v-toolbar>
        <v-card-title class="text-h5">
          Fachnote {{ grade.name }} ändern
        </v-card-title>
        <v-card-subtitle>
          für {{ grade.student }}
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="changed.value"
                  :items="allReportGrades.filter(e => e.value !== savedGradeValue)"
                  :placeholder="String(currentgradevalue)"
                  @change="handleChange"
                >
                  <template #label>
                    <label>von {{ showGradeAbbr(savedGradeValue) }} zu</label>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="8">
                <v-combobox
                  v-model="changed.reason"
                  :items="changed.reasons"
                  :search-input.sync="changed.search"
                  clearable
                >
                  <template #label>
                    <label>weil ...</label>
                  </template>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Um die eigene Begründung "<strong>{{ changed.search }}</strong>" zu verwenden drücke bitte <kbd>ENTER</kbd>.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>

              </v-col>
            </v-row>
            <template v-if="changed.info.text !== ''">
              <v-alert
                :type="changed.info.color"
              >
                {{ changed.info.text }} {{ (timer.current / 1000).toFixed(0)}}
              </v-alert>
              <v-progress-linear
                :value="timer.current / (timer.max / 100)"
              />
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="error" icon :disabled="buttonsDisabled" @click="handleChange(savedGradeValue); resetDialog(dialog)">
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
          <v-btn
            color="success"
            :disabled="buttonsDisabled || changed.reason === null || changed.value === undefined "
            icon @click="() => { changeGrade(dialog) }">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)
const { getColor, getTextColor } = require('../../handler/tableHandler')

export default {
  name: 'ReportGrade',

  props: {
    isHeadMaster: Boolean,
    level: Number,
    grade: Object,
    currentgradevalue: Number
  },

  created () {
    this.resetChanged()
  },

  data () {
    return {
      savedGradeValue: this.currentgradevalue,
      allReportGrades: this.grade.allReportGrades,
      notizMap: { 7: 'nicht erteilt', 8: 'keine Benotung', 9: 'befreit', 10: 'teilgenommen' },
      abbrMap: { 7: 'ne', 8: 'kN', 9: 'befreit', 10: 'tg' },
      buttonsDisabled: false,
      changed: {},
      timer: { current: 3000, max: 3000 }
    }
  },

  methods: {
    showGrade (value) {
      if (value !== null) {
        if (value <= 6) return value
        else return this.notizMap[value]
      } else return '-'
    },
    showGradeAbbr (value) {
      if (value !== null) {
        if (value <= 6) return value
        else return this.abbrMap[value]
      } else return ' '
    },

    handleChange (e) {
      this.$emit('update:currentgradevalue', e === '' ? null : e)
    },
    getColor (level, grade) {
      return getColor(level, grade, this.noGradeText)
    },
    getTextColor (level, grade) {
      return getTextColor(level, grade)
    },
    async changeGrade (dialog) {
      // do this async Promise.all
      if (this.changed.value >= 1 && this.changed.value <= 6 && this.savedGradeValue >= 7) await this.setNote(this.grade.id, this.grade.note.split('_')[0])
      await this.setGrade(this.grade.id, this.changed.value, dialog)
    },
    hasChanged (oldValue, newValue) {
      return oldValue !== newValue
    },
    async setGrade (id, value, dialog) {
      value = value === '' ? 'null' : value
      const url = `${config.api_prisma_set_report}/${id}/${value}`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.post(url, {}, axiosConfig)
        if (res.data.success) {
          this.buttonsDisabled = true
          this.changed.info.text = 'Änderungen hochgeladen'
          this.changed.info.color = 'success'
        }
      } catch (err) {
        this.changed.info.text = 'Fehler beim hochladen'
        this.changed.info.color = 'error'
        console.error(err)
      }

      setTimeout(() => { this.resetDialog(dialog) }, this.timer.max)
      this.timer.intervalId = setInterval(() => {
        this.timer.current -= 100
        if (this.timer.current < 0) {
          clearInterval(this.timer.intervalId)
          this.timer.current = this.timer.max
        }
      }, 100)
    },

    async setNote (id, value) {
      const url = `${config.api_prisma_set_report}/${id}/notiz`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.post(url, { notiz: value }, axiosConfig)
        if (res.data.success) {
          console.warn(res.data)
          return true
        }
      } catch (err) {
        console.error(err)
        return false
      }
    },
    resetDialog (dialog) {
      this.resetChanged()
      this.buttonsDisabled = false
      this.timer.current = this.timer.max
      dialog.value = false
    },
    resetChanged () {
      this.changed = {
        id: this.grade.id,
        reason: null,
        reasons: ['Eintrag vergessen', 'Tippfehler', 'Rechenfehler'],
        search: null,
        info: { text: '', color: null }
      }
    }
  }
}
</script>

<style scoped>
</style>
