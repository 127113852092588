<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <span>
          {{ shortenTopic(getThema()) }}
        </span>
      </div>
    </template>

    <GradeOverviewTable
      componentType="meta"
      :type="getType()"
      :topic="getTopic()"
      :firstName="getFirstName()"
      :lastName="getLastName()"
      :testedAt="getTestedAt()"
      :backAt="getBackAt()"
      :classOverview="classOverview"
      :classMedian="classMedian"
      :classMedianColor="classMedianColor"
      :isSek2="isSek2"
      :isBB="isBB"
    />
  </v-tooltip>
</template>

<script>
import GradeOverviewTable from '../components/GradeOverviewTable'
const { shortenTopic } = require('../../handler/tableHandler')

export default {
  name: 'MetaGradeHeader',
  components: { GradeOverviewTable },

  props: {
    noten: Array,
    header: Object,
    thema: String,
    type: String,
    topic: String,
    teacher: String,
    firstName: String,
    lastName: String,
    testedAt: String,
    backAt: String,
    classOverview: Object,
    classMedian: String,
    classMedianColor: String,
    isSek2: Boolean,
    isBB: Boolean
  },
  methods: {
    shortenTopic (topic) {
      return shortenTopic(topic)
    },
    getThema () {
      if (this.isBB) return this.getTopic()
      else return this.thema !== undefined ? this.thema : this.header.text
    },
    getType () {
      return this.type !== undefined ? this.type : this.header.text.split(', ')[0]
    },
    getTopic () {
      return this.topic !== undefined ? this.topic : this.header.text.split(', ')[1]
    },
    getFirstName () {
      if (this.header?.teacher !== undefined) {
        return this.header.teacher.firstName
      } else {
        return this.firstName !== undefined ? this.firstName : this.teacher.split(' ')[0]
      }
    },
    getLastName () {
      if (this.header?.teacher !== undefined) {
        return this.header.teacher.lastName
      } else {
        return this.lastName !== undefined ? this.lastName : this.teacher.split(' ')[1]
      }
    },
    getTestedAt () {
      return this.testedAt !== undefined ? this.testedAt : this.header.testedAt.split('T')[0]
    },
    getBackAt () {
      return this.backAt !== undefined ? this.backAt : this.header.backAt.split('T')[0]
    }

  }
}
</script>
