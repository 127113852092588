<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="isLoading">
          <v-skeleton-loader
            type="card"
            v-if="isLoading"
            >
          </v-skeleton-loader>
          <v-container class="mb-4" v-if="!isLoading">
            <v-row class="ml-6 mt-0">
              <v-col cols="9" v-if="selectedSchool !== undefined && schools.length == 1">
                Name der Schule: {{ selectedSchool.name }}
              </v-col>
              <v-col cols="9" v-if="selectedSchool !== undefined && schools.length > 1" class="d-flex">
                <div style="padding-top: 4px;">
                  Name der Schule:
                </div>
                <v-select
                  v-model="selectedSchool"
                  :items="schools"
                  item-text="name"
                  label="Schule:"
                  return-object
                  style="margin-top: 0px; padding-top: 0px;"
                  @change="setFirstClass"
                  class="ml-3"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="ml-6 mt-0">
              <v-col class="text-h5 mb-4 text-center" v-if="!selectedTerm.isEJ">
                Halbjahres{{ (selectedClass.type.abbr === 'OS' && selectedClass.level === 10) ? 'zeugnis' : 'information' }} {{ selectedClass.type.abbr === 'OS' ? 'der' : 'des' }} {{ selectedClass.type.name }}
              </v-col>
              <v-col class="text-h5 mb-4 text-center" v-if="selectedTerm.isEJ">
                {{ (selectedClass.type.abbr === 'OS' && selectedClass.level === 10) ? 'Abschlusszeugnis' : 'Jahreszeugnis' }} {{ selectedClass.type.abbr === 'OS' ? 'der' : 'des' }} {{ selectedClass.type.name }}
              </v-col>
            </v-row>
            <v-row class="ml-6 mt-0">
              <v-col cols="3" class="text text-start pb-0">
                <div style="margin-top: 7px" v-if="!isBB()">
                  Klasse: {{ selectedClass.level }}{{ selectedClass.vintage[selectedClass.vintage.length - 1] }}
                </div>
                <div style="margin-top: 7px" v-if="isBB()">
                  Klasse: {{ selectedClass.vintage }}
                </div>
              </v-col>
              <v-col cols="3" class="pb-0">
                <table style="display: grid; justify-content: end;">
                  <tr>
                    <td style="padding-right: 6px">
                      <v-select
                        v-model="selectedClass"
                        v-if="selectedSchool.classes.length > 1"
                        :items="selectedSchool.classes"
                        item-text="vintage"
                        label="Jahrgang:"
                        return-object
                        style="margin-top: 0px; padding-top: 0px;"
                        @change="setFirstStudent"
                        >
                      </v-select>
                      <span v-else-if="selectedSchool.classes.length === 1">
                        Jahrgang: {{ selectedClass.vintage }}
                      </span>
                    </td>
                    <td style="vertical-align: top;">
                      <PrintAllReportsButton
                        :disabled="isSek2()"
                        :reportObj="createReportObj()"
                        :studentsArr="createStudentsArr(selectedClass.students)"
                      />
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
            <v-row class="ml-6 mt-0">
              <v-col cols="2" class="text text-start">
                <div style="margin-top: 7px">
                  Vorname und Name:
                </div>
              </v-col>
              <v-col cols="1" class="pr-0 pt-0">
                <div class="d-flex justify-end">
                  <ProtectedAvatar
                    v-if="!isLoading"
                    :key="selectedStudent.id"
                    :sex="selectedStudent.sex"
                    :vintage="selectedClass.vintage"
                    :oid="selectedStudent.odataPersonOid"
                    size="44"
                    :random="isPlayground"
                  />
                </div>
              </v-col>
              <v-col cols="3">
                <table style="display: grid; justify-content: end;">
                  <tr>
                    <td style="padding-right: 6px">
                      <!-- :items="classes.find(e => e.vintage === selectedClass.vintage).students" -->
                      <v-select
                        v-if="selectedSchool.classes.length > 0"
                        v-model="selectedStudent"
                        :items="selectedClass.students"
                        :error-messages="error"
                        :success-messages="success"
                        item-text="fullName"
                        label="Schüler:"
                        return-object
                        style="margin-top: 0px; padding-top: 0px;"
                      ></v-select>
                    </td>
                    <td style="vertical-align: top;">
                      <PrintReportButton
                        :disabled="isSek2()"
                        :reportObj="createReportObj()"
                        :studentObj="createStudentObj(selectedStudent)"
                      />
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col cols="3" class="text text-start pb-0">
                <v-select
                  v-model="selectedTerm"
                  :items="terms"
                  label="Schuljahr:"
                  return-object
                  style="margin-top: 0px; padding-top: 0px;"
                  >
                </v-select>
              </v-col>

            </v-row>

            <template v-for="row in [
              [{ id: 0, text: 'Betragen' }, { id: 1, text: 'Mitarbeit' }],
              [{ id: 2, text: 'Fleiß' }, { id: 3, text: 'Ordnung' }]
            ]">
              <v-row v-if="!is10OSEJ() && !isBB()" class="ml-6 mt-0" :key="`KLRow_${row[0].id}`">
                <template v-for="col in row">
                  <v-col class="text text-start pt-2 pb-1" :key="`KLName_${col.id}`">
                    <table>
                      <tr>
                        <td colspan="2">
                          {{ col.text }}<br>
                        </td>
                      </tr>
                      <tr>
                        <td width="50px">
                          {{ computedHeadGradeCount(col.text) }}
                        </td>
                        <td>
                          <v-chip
                            v-if="computedHeadGrade(col.text) !== '-'"
                            :color="getColor(selectedClass.level, computedHeadGrade(col.text))"
                            :text-color="getTextColor(selectedClass.level, computedHeadGrade(col.text))"
                          > {{ computedHeadGrade(col.text) }}</v-chip>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                  <v-col class="text text-start pt-0 pb-1" :key="`KLValue_${col.id}`">
                    <v-skeleton-loader
                      v-if="selectedStudent.grades === undefined"
                      type="chip"
                    ></v-skeleton-loader>
                    <v-select
                      v-if="getGrade(col.text, selectedStudent.grades) !== undefined"
                      :key="getGrade(col.text, selectedStudent.grades).id"
                      :error-messages="getGrade(col.text, selectedStudent.grades).error"
                      :success-messages="getGrade(col.text, selectedStudent.grades).success"
                      :value="getGrade(col.text, selectedStudent.grades).value"
                      :placeholder="getPlaceholder(col.text, selectedStudent.grades)"
                      :items="possibleHeadGrades"
                      rounded
                      outlined
                      @input="(value) => setGrade(col.text, value)"
                      :disabled="!inputAllowed()"
                      class="v-select-grades"
                    />
                  </v-col>
                </template>
              </v-row>
            </template>

            <v-row class="ml-6 mt-0 mr-6 text-start" v-if="(selectedClass.type.abbr !== 'OS' || selectedClass.level !== 10) && selectedTerm.isEJ">
              <v-col>
                <!-- value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa." -->
                <v-textarea
                  v-if="this.getGrade('Einschaetzung', this.selectedStudent.grades) !== undefined"
                  v-model="getGrade('Einschaetzung', selectedStudent.grades).note"
                  :key="this.getGrade('Einschaetzung', this.selectedStudent.grades).id"
                  :error-messages="this.getGrade('Einschaetzung', this.selectedStudent.grades).noteError"
                  :success-messages="this.getGrade('Einschaetzung', this.selectedStudent.grades).noteSuccess"
                  :value="this.getGrade('Einschaetzung', this.selectedStudent.grades).note"
                  auto-grow
                  outlined
                  counter="270"
                  rows="2"
                  label="Einschätzung"
                  @change="(value) => setNote('Einschaetzung', value)"
                  :disabled="!inputAllowed()"
                />
              </v-col>
            </v-row>

            <v-row class="text-h6 ml-6 mt-0 text-start">
              <v-col>
                Leistungen in den einzelnen Fächern: <v-btn
                  v-if="!is10OSEJ()" icon
                  @click="showFachnoten = !showFachnoten"
                >
                  <v-icon>{{ showFachnoten ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-expand-transition v-if="!is10OSEJ()">
              <div v-show="showFachnoten">
                <v-row class="text ml-6 text-start" v-for="grade in filteredGrades()" :key="`row${grade[0].courseId}`">
                  <template v-for="part in grade">
                    <v-col cols="reportColumns.width" class="pb-0" :key="`headGradesTooltip_${part.courseId}`">
                      <v-tooltip bottom v-if="part !== undefined && !isBB()">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ part.name }}
                          </span>
                        </template>
                        <table>
                          <tbody>
                            <tr v-for="row in [['Betragen', 'Mitarbeit'], ['Fleiß', 'Ordnung']]" :key="`tr${row[0]}`">
                              <template v-for="headName2 in row">
                                <td colspan="3" :key="`td1_${headName2}`">{{ headName2 }}: </td>
                                <td width="10" :key="`td2_${headName2}`"/>
                                <td colspan="3" :key="`td3_${headName2}`">{{ getHeadGrade(headName2, part.courseId) }}</td>
                                <td width="5" :key="`td4_${headName2}`"/>
                                <!-- <td colspan="3" :key="`td5_${headName2}`">({{ getHeadGradeTeacher (headName2, part.courseId) }})</td> this is more exact, because it could be another teacher -->
                                <td colspan="3" :key="`td5_${headName2}`">({{ getHeadGradeTeacher (part.teacher) }})</td>
                                <td width="5" :key="`td6_${headName2}`"/>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </v-tooltip>
                      <span v-else-if="isBB()">
                        {{ part.name }}
                      </span>
                      <v-chip
                        v-if="part.note.split('_')[0] !== ''"
                        :color="getColor(selectedClass.level, part.note.split('_')[0])"
                        :text-color="getTextColor(selectedClass.level, part.note.split('_')[0])"
                      >
                        {{ part.note.split('_')[0] }}
                      </v-chip>
                    </v-col>
                    <v-col cols="reportColumns.width" class="pb-0" :key="`valueCol_${part.courseId}`">
                      <v-tooltip bottom v-if="part !== undefined">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">

                            <ReportGrade
                              :key="part.gradeId"
                              :isHeadMaster="isHeadMaster()"
                              :level="selectedClass.level"
                              :grade="{ id: part.gradeId, name: part.name, note: part.note, student: selectedStudent.fullName, allReportGrades: allReportGrades }"
                              v-bind:currentgradevalue="part.value"
                              v-on:update:currentgradevalue="value => updateReportGrade(value, part)"
                            />
                          </span>
                        </template>
                        {{ part.teacher.lastName }}, {{ part.teacher.firstName }}
                      </v-tooltip>
                    </v-col>
                  </template>
                  <v-col cols="4" v-if="grade.length ===2"></v-col>
                  <v-col cols="8" v-if="grade.length ===1"></v-col>
                </v-row>
                <v-row class="text ml-6 text-start" >
                  <template v-if="hasGradeValues(filteredGrades())">
                    <v-col cols="reportColumns.width" class="pb-0">
                      Fachnoten &empty;
                    </v-col>
                    <v-col cols="reportColumns.width" class="pb-0">
                      <v-chip
                        :color="getColor(selectedClass.level, getMedian(filteredGrades()).toFixed(2))"
                        :text-color="getTextColor(selectedClass.level, getMedian(filteredGrades()).toFixed(2))"
                      >
                        {{ getMedian(filteredGrades()).toFixed(2) }}
                      </v-chip>
                    </v-col>
                  </template>
                  <template v-if="hasHeadGradeValues()">
                    <v-col cols="reportColumns.width">
                      Kopfnoten &empty;
                    </v-col>
                    <v-col cols="reportColumns.width">
                      <v-chip
                        :color="getColor(selectedClass.level, getMedianHead().toFixed(2))"
                        :text-color="getTextColor(selectedClass.level, getMedianHead().toFixed(2))"
                      >
                        {{ getMedianHead().toFixed(2) }}
                      </v-chip>
                    </v-col>
                  </template>
                  <template v-if="hasGradeValues(filteredGrades() || hasHeadGradeValues())">
                    <v-col cols="reportColumns.width" class="pb-0">
                      Gesamt &empty;
                    </v-col>
                    <v-col cols="reportColumns.width"  class="pb-0">
                      <v-chip
                        :color="getColor(selectedClass.level, getMedianAll(filteredGrades()).toFixed(2))"
                        :text-color="getTextColor(selectedClass.level, getMedianAll(filteredGrades()).toFixed(2))"
                      >
                        {{ getMedianAll(filteredGrades()).toFixed(2) }}
                      </v-chip>
                    </v-col>
                  </template>
                </v-row>
              </div>
            </v-expand-transition>

            <div v-if="is10OSEJ()">

              <!-- <v-row class="text ml-6 text-start" v-for="grade in filteredGrades()" :key="grade.id">
                <template v-for="part in grade">
                  <v-col v-if="part !== undefined" cols="reportColumns.width" class="pb-0" :key="`nameCol_${part.courseId}`">
                    {{ part.name }} {{ `${part.note === 'neunte' ? '(9.)' : ''}` }}
                  </v-col>
                  <v-col cols="reportColumns.width" v-if="part !== undefined" class="pb-0" :key="`valueCol_${part.courseId}`">
                    {{ `${part.note === 'befreit' ? 'befreit' : (part.value !== null ? part.value : 'tg' )}` }}
                  </v-col>
                </template>
              </v-row> -->

                <v-row class="text ml-6 text-start" v-for="grade in filteredGrades()" :key="`row${grade[0].courseId}`">
                  <template v-for="part in grade">
                    <v-col cols="reportColumns.width" class="pb-0" :key="`headGradesTooltip_${part.courseId}`">
                      <v-tooltip bottom v-if="part !== undefined">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ part.name }}
                          </span>
                        </template>
                        <table>
                          <tbody>
                            <tr v-for="row in [['Betragen', 'Mitarbeit'], ['Fleiß', 'Ordnung']]" :key="`tr${row[0]}`">
                              <template v-for="headName2 in row">
                                <td colspan="3" :key="`td1_${headName2}`">{{ headName2 }}: </td>
                                <td width="10" :key="`td2_${headName2}`"/>
                                <td colspan="3" :key="`td3_${headName2}`">{{ getHeadGrade(headName2, part.courseId) }}</td>
                                <td width="5" :key="`td4_${headName2}`"/>
                                <!-- <td colspan="3" :key="`td5_${headName2}`">({{ getHeadGradeTeacher (headName2, part.courseId) }})</td> this is more exact, because it could be another teacher -->
                                <td colspan="3" :key="`td5_${headName2}`">({{ getHeadGradeTeacher (part.teacher) }})</td>
                                <td width="5" :key="`td6_${headName2}`"/>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </v-tooltip>
                      <v-chip
                        v-if="part.note.split('_')[0] !== ''"
                        :color="getColor(selectedClass.level, part.note.split('_')[0])"
                        :text-color="getTextColor(selectedClass.level, part.note.split('_')[0])"
                      >
                        {{ part.note.split('_')[0] }}
                      </v-chip>
                    </v-col>
                    <v-col cols="reportColumns.width" class="pb-0" :key="`valueCol_${part.courseId}`">
                      <v-tooltip bottom v-if="part !== undefined">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">

                            <ReportGrade
                              :key="part.gradeId"
                              :isHeadMaster="isHeadMaster()"
                              :level="selectedClass.level"
                              :grade="{ id: part.gradeId, name: part.name, note: part.note, student: selectedStudent.fullName, allReportGrades: allReportGrades }"
                              v-bind:currentgradevalue="part.value"
                              v-on:update:currentgradevalue="value => updateReportGrade(value, part)"
                            />
                          </span>
                        </template>
                        {{ part.teacher.lastName }}, {{ part.teacher.firstName }}
                      </v-tooltip>
                    </v-col>
                  </template>
                  <v-col cols="4" v-if="grade.length ===2"></v-col>
                  <v-col cols="8" v-if="grade.length ===1"></v-col>
                </v-row>

              <!-- <v-row class="text ml-6 text-start" >
                <v-col cols="3" class="pb-0">
                  Fachnoten &empty;
                </v-col>
                <v-col cols="3" class="pb-0">
                  {{ getMedian(filteredGrades()).toFixed(2) }}
                </v-col>
              </v-row> -->

              <v-row class="text ml-6 text-start" v-if="hasGradeValues(filteredGrades())">
                <v-col :cols="3*reportColumns.width/2" class="pb-0">
                  Fachnoten &empty;
                </v-col>
                <v-col :cols="3*reportColumns.width/2" class="pb-0">
                  <v-chip
                    :color="getColor(selectedClass.level, getMedian(filteredGrades()).toFixed(2))"
                    :text-color="getTextColor(selectedClass.level, getMedian(filteredGrades()).toFixed(2))"
                  >
                    {{ getMedian(filteredGrades()).toFixed(2) }}
                  </v-chip>
                </v-col>
              </v-row>
            </div>

            <v-row class="text ml-6 mt-0 text-start" v-if="!is10OSEJ()">
              <v-col cols="2" class="pt-7">
                Bemerkungen:
              </v-col>
              <v-col cols="2" class="pt-7">
                Fehltage entschuldigt:
              </v-col>
              <v-col cols="2" class="pt-0">
                <v-text-field
                  v-if="this.getGrade('FehltageE', this.selectedStudent.grades) !== undefined"
                  :key="this.getGrade('FehltageE', this.selectedStudent.grades).id"
                  :error-messages="this.getGrade('FehltageE', this.selectedStudent.grades).error"
                  :success-messages="this.getGrade('FehltageE', this.selectedStudent.grades).success"
                  :value="this.getGrade('FehltageE', this.selectedStudent.grades).value"
                  :placeholder="getPlaceholder('FehltageE', this.selectedStudent.grades)"
                  :items="possibleHeadGrades"
                  rounded
                  outlined
                  @change="(value) => setGrade('FehltageE', value)"
                  :disabled="!inputAllowed()"
                  class="v-select-grades"
                />
              </v-col>
              <v-col cols="3" class="pt-7">
                unentschuldigt:
              </v-col>
              <v-col cols="2" class="pt-0">
                <v-text-field
                  v-if="this.getGrade('FehltageUE', this.selectedStudent.grades) !== undefined"
                  :key="this.getGrade('FehltageUE', this.selectedStudent.grades).id"
                  :error-messages="this.getGrade('FehltageUE', this.selectedStudent.grades).error"
                  :success-messages="this.getGrade('FehltageUE', this.selectedStudent.grades).success"
                  :value="this.getGrade('FehltageUE', this.selectedStudent.grades).value"
                  :placeholder="getPlaceholder('FehltageUE', this.selectedStudent.grades)"
                  :items="possibleHeadGrades"
                  rounded
                  outlined
                  @change="(value) => setGrade('FehltageUE', value)"
                  :disabled="!inputAllowed()"
                  class="v-select-grades"
                />
              </v-col>
            </v-row>

            <v-row class="text ml-6 mt-0 text-start" v-if="this.getGrade('Religion', this.selectedStudent.grades) !== undefined">
              <v-col cols="12" class="d-inline-flex align-items-center pt-0 pb-0">
                <span style="margin-top: 14px;">
                  {{ selectedStudent.firstName }} nahm am Religionsunterricht mit der Note
                </span>
                <v-select
                  :key="this.getGrade('Religion', this.selectedStudent.grades).id"
                  :error-messages="this.getGrade('Religion', this.selectedStudent.grades).error"
                  :success-messages="this.getGrade('Religion', this.selectedStudent.grades).success"
                  :value="this.getGrade('Religion', this.selectedStudent.grades).value"
                  :placeholder="getPlaceholder('Religion', this.selectedStudent.grades)"
                  :items="allReportGrades.filter(e => e.value !== this.getGrade('Religion', this.selectedStudent.grades).value)"
                  single-line
                  @change="(value) => setGrade('Religion', value)"
                  :disabled="!inputAllowed()"
                  class="v-select-grades"
                  style="width: min-content; margin-top: 0px; margin-left: 5px; padding-top: 0px"
                />
                <span style="margin-top: 14px; margin-left: 5px;">
                  teil.
                </span>
              </v-col>
            </v-row>

            <v-row class="text ml-6 mt-0 text-start" v-if="!is10OSEJ() && selectedStudent.gtas !== undefined">
              <v-col cols="6" v-if="this.selectedStudent.gtas.length > 0" class="pt-0">
                {{ createGtaText(selectedStudent) }}
              </v-col>
              <v-col cols="6" v-else class="pt-0"></v-col>
              <!-- <v-col cols="3">
                GTA:
              </v-col> -->

              <v-col cols="5" class="pt-0">
                <v-combobox
                  v-if="!isLoading && selectedStudent.gtas !== undefined"
                  :key="selectedStudent.id"
                  label="GTA auswahl"
                  v-model="selectedStudent.gtas"
                  :items="possibleGtaArr"
                  :error-messages="selectedStudent.gta_error"
                  :success-messages="selectedStudent.gta_success"
                  item-text="name"
                  item-value="id"
                  return-object
                  multiple
                  chips
                  @change="setGtas"
                  :disabled="!inputAllowed()"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item.id)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item.name.slice(0, 5) }} ...
                    </v-chip>
                  </template>

                </v-combobox>
              </v-col>
            </v-row>

            <v-row class="text ml-6 mt-0" align="center" v-if="selectedTerm.isEJ && (selectedClass.type.abbr !== 'OS' || selectedClass.level !== 10) && this.getGrade('Versetzung', this.selectedStudent.grades) !== undefined">
              <v-col cols="2">
                Versetzungsvermerk:
              </v-col>
              <v-col cols="4" align-self="start">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">

                      <v-checkbox
                        v-if="!isLoading"
                        :input-value="getGrade('Versetzung', selectedStudent.grades).value"
                        :key="getGrade('Versetzung', selectedStudent.grades).id"
                        :label="`${selectedStudent.firstName.split(' ')[0]} wird ${getGrade('Versetzung', selectedStudent.grades).value === -1 ? 'nicht' : ''} versetzt.`"
                        :error-messages="getGrade('Versetzung', selectedStudent.grades).error"
                        :success-messages="getGrade('Versetzung', selectedStudent.grades).success"
                        :value="getGrade('Versetzung', selectedStudent.grades).value"
                        :true-value=null
                        :false-value=-1
                        color="red"
                        @change="(value) => checkBox(value, 'Versetzung')"
                        :disabled="!inputAllowed()"
                      />

                    </span>
                  </template>
                  <p class="tooltip">Entferne das Häkchen, wenn ein Schüler die Klasse wiederholen soll bzw. freiwillig wiederholen will<br> oder hake das Feld an, wenn er versetzt wird.</p>
                  <!-- <p>{{ istVersetzbar(selectedClass.type.abbr, selectedStudent.grades) ? 'Kann versetzt werden.' : 'Darf nicht versetzt werden!' }}</p> -->
                  <!-- <p>{{ getRiskToMoveUp(selectedClass.type.abbr, selectedStudent.grades) }}</p> -->
                  <p v-if="!getRiskToMoveUp(selectedClass.type.abbr, selectedStudent.grades).hasRisk">
                    {{ selectedStudent.firstName }} ist <strong class="greenText">nicht versetzungsgefährdet</strong> - rechnerisch nach {{ getSchoolRules(selectedClass.type) }}.
                  </p>
                  <p v-else>
                    <span v-if="getRiskToMoveUp(selectedClass.type.abbr, selectedStudent.grades).hasRisk && !getRiskToMoveUp(selectedClass.type.abbr, selectedStudent.grades).isOut">
                      {{ selectedStudent.firstName }} ist <strong class="orangeText">versetzungsgefährdet</strong> - rechnerisch nach {{ getSchoolRules(selectedClass.type) }}.
                    </span>
                    <span v-else-if="getRiskToMoveUp(selectedClass.type.abbr, selectedStudent.grades).isOut">
                      {{ selectedStudent.firstName }} kann <strong class="redText">nicht versetzt werden</strong> - rechnerisch nach {{ getSchoolRules(selectedClass.type) }} mit der Begründung:<br> {{ getRiskToMoveUp(selectedClass.type.abbr, selectedStudent.grades).reason }}
                    </span>

                    <table class="hasRisk">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="hasRisk">Fach</th>
                          <th class="hasRisk">Ausgleich</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="whichSubject in ['major', 'minor']">
                          <template v-for="whichGrades in ['ex', 'bad']">
                            <tr v-for="subject in getRiskToMoveUp(selectedClass.type.abbr, selectedStudent.grades).sortedSubjects[whichSubject][whichGrades]"
                              :key="`${subject.name}_${whichSubject}_${whichGrades}`"
                            >
                              <td class="hasRisk" v-if="whichSubject === 'major'">Hauptfach</td>
                              <td class="hasRisk" v-if="whichSubject === 'minor'">Nebenfach</td>
                              <td class="hasRisk">{{ subject.name }} ({{ subject.value }})</td>
                              <td class="hasRisk">
                                <span v-if="subject.compensation !== undefined">{{ subject.compensation.name }} ({{ subject.compensation.value }})</span>
                                <span v-else>nicht möglich</span>
                              </td>
                            </tr>
                          </template>
                        </template>

                      </tbody>
                    </table>

                  </p>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="text ml-6 mt-0 text-start">
              <v-col>
                Datum: {{ (new Date()).toLocaleString().split(',')[0] }}
              </v-col>
            </v-row>

          </v-container>
        </v-card>
      </v-col>
      <!-- <v-col cols="2" class="d-flex">
        <v-card
          class="mb-4"
          :loading="isLoading"
        >
          <v-skeleton-loader
            type="list-item-avatar-three-line, article"
            v-if="isLoading"
            >
          </v-skeleton-loader>

          <v-card-title v-if="!isLoading">

            <v-row class="d-flex align-center">
              <v-col cols="6" class="d-flex justify-center">
                <ProtectedAvatar
                  v-if="!isLoading"
                  :key="this.selectedStudent.id"
                  :sex="this.selectedStudent.sex"
                  :vintage="this.selectedClass.vintage"
                  :oid="this.selectedStudent.odataPersonOid"
                  class="mt-1 mb-1"
                />
              </v-col>
              <v-col cols="6">
                <v-row class="d-flex align-center">
                  <v-col cols="9" v-if="this.classes.length > 1">
                    <v-select
                      v-model="selectedClass"
                      :items="classes"
                      item-text="vintage"
                      label="Klasse wählen"
                      single-line
                      return-object
                      @change="setFirstStudent"
                      >
                    </v-select>
                  </v-col>
                  <v-col cols="9" v-else></v-col>
                  <v-col cols="12">
                    <PrintAllReportsButton
                      :disabled="false"
                      :reportObj="this.createReportObj()"
                      :studentsArr="this.createStudentsArr(this.selectedClass.students)"
                    />
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                  <v-col cols="9">
                    <v-select
                      v-if="this.classes.length > 0"
                      v-model="selectedStudent"
                      :items="classes.find(e => e.vintage === this.selectedClass.vintage).students"
                      :error-messages="error"
                      :success-messages="success"
                      item-text="fullName"
                      label="Schüler wählen"
                      return-object
                      single-line
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <PrintReportButton
                      :disabled="false"
                      :reportObj="this.createReportObj()"
                      :studentObj="this.createStudentObj(this.selectedStudent)"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>

          <v-row v-if="!isLoading">
            <v-col/>
            <v-col class="d-flex justify-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-checkbox
                      v-model="gewichtet"
                      label="Kopfnotenvorschläge gewichten"
                    ></v-checkbox>
                  </span>
                </template>
                <span>Die Note der Lehrer mit mehr Wochenstunden geht mit einem größerem Gewicht ein.</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-container v-if="!isLoading">
            <v-row>
              <v-col>
                <v-container>

                  <v-row class="d-flex align-center">
                    <v-col cols="4" v-if="this.isEJ">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">

                            <v-checkbox
                              :disabled="enabled"
                              v-model="selectedGrade.einschaetzung.printSmall"
                              v-if="isFullySelected()"
                              :key="selectedGrade.einschaetzung.key"
                              label="kleiner Drucken"
                              :value="selectedGrade.einschaetzung.printSmall"
                            />
                          </span>
                        </template>
                        <span>Verkleinere die Schriftgröße der Einschätzung, falls die doch nicht auf das Zeugnis passt.</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex align-start">
                    <v-col cols="4" class="d-flex justify-end">
                      Fehltage:
                    </v-col>
                    <v-col cols="8" >
                      <v-date-picker
                        :first-day-of-week="1"
                        readonly
                        color="primary"
                        :events="getFehltage"
                        header-color="primary"
                        multiple
                        scrollable
                      >
                      </v-date-picker>
                    </v-col>
                  </v-row>
                  https://vuetifyjs.com/en/components/date-pickers/#multiple
                  <v-row class="d-flex align-center">
                    <v-col cols="4" class="d-flex align-center justify-end">
                      Fehltage:
                    </v-col>
                    <v-col cols="8" >
                      <v-menu
                        ref="menu"
                        v-model="fehltageEMenu"
                        :close-on-content-click="false"
                        :return-value.sync="fehltageE"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            v-model="fehltageE"
                            multiple
                            chips
                            small-chips
                            label="entschuldigt"
                            color="primary"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-combobox>
                        </template>
                        <v-date-picker
                          v-model="fehltageE"
                          header-color="primary"
                          multiple
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(fehltageE)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex align-center">
                    <v-col cols="4" class="d-flex align-center justify-end">
                    </v-col>
                    <v-col cols="8" >
                      <v-menu
                        ref="menu"
                        v-model="fehltageUEMenu"
                        :close-on-content-click="false"
                        :return-value.sync="fehltageUE"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            v-model="fehltageUE"
                            multiple
                            chips
                            small-chips
                            label="unentschuldigt"
                            color="red"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-combobox>
                        </template>
                        <v-date-picker
                          header-color="red"
                          v-model="fehltageUE"
                          multiple
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(fehltageUE)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row class="d-flex align-center">
                    <v-col cols="4" class="d-flex align-center justify-end">
                      Coronabefreiung Schulpflicht:
                    </v-col>
                    <v-col cols="8" >
                      <v-menu
                        ref="menu"
                        v-model="coronatageMenu"
                        :close-on-content-click="false"
                        :return-value.sync="coronatage"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox
                            v-model="coronatage"
                            multiple
                            chips
                            small-chips
                            label="Zeiträume"
                            color="primary"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-combobox>
                        </template>
                        <v-date-picker
                          header-color="primary"
                          v-model="coronatage"
                          multiple
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(coronatage)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
  <!-- </v-card> -->

</template>

<script setup>
import ProtectedAvatar from '../components/ProtectedAvatar'
import PrintReportButton from '../components/PrintReportButton'
import PrintAllReportsButton from '../components/PrintAllReportsButton'
// import ChangeReportGradeModal from '../components/ChangeReportGradeModal'
import ReportGrade from '../components/ReportGrade'
// import Timetable from '../oldForLogin/views/Timetable.vue'
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)
const { isTimeForReportGrades } = require('../../handler/dateHandler')
const { getColor, getTextColor } = require('../../handler/tableHandler')

export default {
  components: { ProtectedAvatar, PrintReportButton, PrintAllReportsButton, ReportGrade },
  data () {
    return {
      // showReportGradeModal: { show: false, changedValue: null },
      // showReportGradeModal: false,
      reportColumns: { count: 3, width: 2 },
      isPlayground: process.env.VUE_APP_IS_PLAYGROUND === 'true',
      schools: [],
      selectedSchool: {},
      selectedTerm: { text: '1. Schulhalbjahr: 2022/2023', isEJ: false, term: 'HJ', vintage: '2022/2023' },
      // terms: [{ text: '1. Schulhalbjahr: 2022/2023', isEJ: false, term: 'HJ', vintage: '2022/2023' }, { text: 'Schuljahr: 2022/2023', isEJ: true, term: 'EJ', vintage: '2022/2023' }],
      terms: [{ text: '1. Schulhalbjahr: 2022/2023', isEJ: false, term: 'HJ', vintage: '2022/2023' }],
      // classes: [],
      computed: [],
      currentKey: 0,
      workInProgress: true,
      enabled: false,
      gewichtet: false,
      rules: [this.etwasZuviel],
      isLoading: true,
      isPhone: window.innerWidth <= 480 || window.innerHeight <= 480,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      selectedClass: {},
      selectedStudent: {},
      error: '',
      computedError: '',
      computedSuccess: '',
      success: [],
      possibleHeadGrades: [1, 2, 3, 4, 5, ''],
      possibleGrades: ['', 1, 2, 3, 4, 5, 6],
      // possibleReportGrades: [1, 2, 3, 4, 5, 6, 'nicht erteilt', 'teilgenommen', 'befreit', 'keine Benotung', ''],
      allReportGrades: this.getAllReportGrades(),
      grades: [],
      showFachnoten: false,
      possibleGtaArr: [],
      fehltageE: ['2022-02-07', '2022-02-08', '2022-02-09'],
      fehltageEMenu: false,
      fehltageUE: ['2022-02-10'],
      fehltageUEMenu: false,
      coronatage: [],
      coronatageMenu: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.initialize()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
  },

  watch: {
    windowWidth (newWidth) {
      this.isPhone = newWidth <= 480
    },
    windowHeight (newHeight) {
      this.isPhone = newHeight <= 480
    }
  },

  methods: {
    isBB () {
      const schoolType = this.selectedSchool.name.split(' ')[0]
      return schoolType === 'BFS' || schoolType === 'FS' || schoolType === 'LOG' || schoolType === 'FOS'
    },
    is10OS () {
      return this.selectedClass.level === 10 &&
        this.selectedClass.type.abbr === 'OS'
    },
    is10OSEJ () {
      return this.is10OS() && this.selectedTerm.isEJ
    },
    getAbbrMapping (value) {
      const AbbrMap = { '': '', 7: 'ne', 8: 'kN', 9: 'befreit', 10: 'tg' }
      return (value >= 1 && value <= 6) ? value : AbbrMap[value]
    },
    getAllReportGrades () {
      const allReportGradeValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, '']
      return allReportGradeValues.map(e => { return { text: this.getAbbrMapping(e), value: e } })
    },
    isSek2 () {
      return this.selectedClass.level >= 11 && this.selectedClass.type.abbr === 'GYM'
    },
    updateReportGrade (value, part) {
      // part.value = value
      const termString = this.selectedTerm.term
      const currentGrade = this.selectedStudent.grades.find(e => {
        return e.gradeMeta.topic === 'Zeugnisnote' &&
          e.gradeMeta.course.courseType.id === part.courseId &&
          e.gradeMeta.gradeType === termString
      })
      currentGrade.value = value
    },
    // getFehltage (date) {
    //   if (this.fehltageE.includes(date)) return 'primary'
    //   if (this.fehltageUE.includes(date)) return 'red'
    // },
    openReportGradeModal (id) {
      if (this.isHeadMaster()) {
        console.log('open a modal to change this grade')
        console.log(id)
        this.showReportGradeModal.show = !this.showReportGradeModal.show
      }
    },
    inputAllowed () {
      // TODO - Maybe head master should be warned!
      return this.isHeadMaster() || this.isTimeForReportGrades(this.selectedTerm)
    },
    isTimeForReportGrades (selectedTerm) {
      return isTimeForReportGrades(selectedTerm)
    },
    getColor (level, grade) {
      return getColor(level, grade, this.noGradeText)
    },
    getTextColor (level, grade) {
      return getTextColor(level, grade)
    },
    isHeadMaster () {
      return this.checkRole('schulleiter')
    },
    checkRole (role) {
      return this.$keycloak.hasRealmRole(role)
    },
    getSchoolRules (schoolType) {
      return schoolType.abbr === 'GYM' ? 'SOGYA §31' : 'SOOSA §28'
    },
    getRiskToMoveUp (schoolType, grades) {
      const gradesObj = this.gradeObj(grades)
      const maxRisks = { GYM: { major: 1, minor: 2, sum: 2 }, OS: { major: 1, minor: 3, sum: 3 } }

      const sortedSubjects = this.sortSubjects(schoolType, gradesObj)
      const retObj = { isOut: false, hasRisk: false, count: 0, reason: '', sortedSubjects }
      if (sortedSubjects.major.ex.length > 0 || sortedSubjects.minor.ex.length > 0) {
        retObj.isOut = retObj.hasRisk = true
        retObj.reason = `${retObj.reason}\n${sortedSubjects.major.ex.length + sortedSubjects.minor.ex.length} mal ungenügend benotet - erlaubt 0 mal.`
      }
      if (sortedSubjects.major.bad.length > maxRisks[schoolType].major) {
        retObj.isOut = retObj.hasRisk = true
        retObj.reason = `${retObj.reason}\n${sortedSubjects.major.bad.length} mal mangelhaft im Hauptfach benotet - erlaubt ${maxRisks[schoolType].major} mal.`
      }
      if (sortedSubjects.minor.bad.length > maxRisks[schoolType].minor) {
        retObj.isOut = retObj.hasRisk = true
        retObj.reason = `${retObj.reason}\n${sortedSubjects.major.bad.length} mal mangelhaft im Nebenfach benotet - erlaubt ${maxRisks[schoolType].minor} mal.`
      }

      for (const badMajorObj of sortedSubjects.major.bad) {
        const goodMajorObj = sortedSubjects.major.goodMajor.pop()
        if (goodMajorObj !== undefined) {
          const indexToRemoveObj = sortedSubjects.minor.goodMajor.findIndex(e => e.abbr === goodMajorObj.abbr)
          sortedSubjects.minor.goodMajor.splice(indexToRemoveObj, 1)
          badMajorObj.compensation = goodMajorObj
          retObj.hasRisk = true
          retObj.count++
        } else {
          retObj.isOut = retObj.hasRisk = true
          retObj.reason = `${retObj.reason}\nKann Hauptfach ${badMajorObj.name} nicht ausgleichen.`
        }
      }
      for (const badMinorObj of sortedSubjects.minor.bad) {
        const goodMajorObj = sortedSubjects.minor.goodMajor.pop()
        if (goodMajorObj !== undefined) {
          badMinorObj.compensation = goodMajorObj
          retObj.hasRisk = true
          retObj.count++
        } else {
          const goodMinorObj = sortedSubjects.minor.goodMinor.pop()
          if (goodMinorObj !== undefined) {
            badMinorObj.compensation = goodMinorObj
            retObj.hasRisk = true
            retObj.count++
          } else {
            retObj.isOut = retObj.hasRisk = true
            retObj.reason = `${retObj.reason}\nKann Nebenfach ${badMinorObj.name} nicht ausgleichen.`
          }
        }
      }
      if (retObj.count > maxRisks[schoolType].sum) {
        retObj.isOut = retObj.hasRisk = true
        retObj.reason = `${retObj.reason}\nDarf nur höchstens ${maxRisks[schoolType].sum} ungenügende Noten ausgleichen.`
      }
      return retObj
    },
    sortSubjects (schoolType, gradesObj) {
      const majorSubjects = {
        GYM: ['de', 'ma', 'en', 'fr', 'ge', 'bio', 'ch', 'ph'],
        OS: ['de', 'ma', 'en', 'ph', 'ch', 'wth']
      }
      const sufficient = { GYM: { major: 2, minor: 3 }, OS: { major: 3, minor: 3 } }
      const sortedSubjects = { major: { ex: [], bad: [], goodMajor: [], goodMinor: null }, minor: { ex: [], bad: [], goodMajor: [], goodMinor: [] } }
      for (const courseAbbr of Object.keys(gradesObj)) {
        if (gradesObj[courseAbbr].value !== null) {
          const grade = gradesObj[courseAbbr].value
          const isMajor = majorSubjects[schoolType].includes(courseAbbr)
          const which = isMajor ? 'major' : 'minor'
          const gradeObj = gradesObj[courseAbbr]
          gradesObj.abbr = courseAbbr
          if (grade === 6) sortedSubjects[which].ex.push(gradeObj)
          if (grade === 5) sortedSubjects[which].bad.push(gradeObj)
          if (isMajor && grade <= sufficient[schoolType].major) sortedSubjects.major.goodMajor.push(gradeObj)
          if (isMajor && grade <= sufficient[schoolType].minor) sortedSubjects.minor.goodMajor.push(gradeObj)
          if (!isMajor && grade <= sufficient[schoolType].minor) sortedSubjects.minor.goodMinor.push(gradeObj)
        }
      }
      return sortedSubjects
    },
    createReligionText (firstName, grade) {
      return `${firstName} nahm am Religionsunterricht ${grade === null ? '' : `mit der Note ${grade} `}teil.`
    },
    createGtaText (student) {
      const gtaArray = student.gtas
      const fn = student.firstName
      if (gtaArray.length > 1) {
        return `${fn} nahm an den Ganztagesangeboten ${this.myJoin(gtaArray)} teil.\n`
      } else if (gtaArray.length === 1) {
        return `${fn} nahm an dem Ganztagesangebot "${gtaArray[0].name}" teil.\n`
      } else {
        return ''
      }
    },
    myJoin (arrayOfObjects) {
      if (arrayOfObjects.length > 0) {
        const vorn = String.fromCharCode(0x201e)
        const hinten = String.fromCharCode(0x201c)
        const cloneOfArrayOfObjects = [...arrayOfObjects]
        let retStr = `${vorn}${cloneOfArrayOfObjects.shift().name}${hinten}`
        const lastElement = cloneOfArrayOfObjects.pop()
        for (const e of cloneOfArrayOfObjects) {
          retStr = `${retStr}, ${vorn}${e.name}${hinten}`
        }
        retStr = `${retStr} und ${vorn}${lastElement.name}${hinten}`
        return retStr
      } else return ''
    },
    createReportObj () {
      const klasse = this.selectedClass.level
      const gymOrOs = this.selectedClass.type.abbr.toLowerCase()
      const retObj = {
        schuleName: this.selectedSchool.name,
        klasse,
        gymOrOs,
        schuljahr: this.selectedTerm.vintage,
        hj: !this.selectedTerm.isEJ,
        ej: this.selectedTerm.isEJ,
        datum: this.selectedTerm.isEJ ? 'XX.07.2023' : 'YY.02.2023'

      }
      if (klasse === 10 && gymOrOs === 'os') retObj.datum = '08.07.2022'
      if (klasse === 5 && gymOrOs === 'os') {
        retObj.klasseZusatz = this.selectedClass.vintage[this.selectedClass.vintage.length - 1]
      }
      return retObj
    },
    createStudentObj (student) {
      const obj = {
        vorname: student.firstName,
        name: student.lastName,
        ziel: 10, // TODO: get generic value from database
        gebDatum: student.dateOfBirth,
        gebOrt: student.placeOfBirth,
        strasze: student.address?.street,
        plz: student.address?.pcode,
        ort: student.address?.place,
        betragen: this.getGrade('Betragen', student.grades)?.value,
        mitarbeit: this.getGrade('Mitarbeit', student.grades)?.value,
        fleisz: this.getGrade('Fleiß', student.grades)?.value,
        ordnung: this.getGrade('Ordnung', student.grades)?.value,
        einschaetzung: this.selectedTerm.isEJ ? this.getGrade('Einschaetzung', student.grades)?.note : null,
        fachnoten: this.gradeObj(student.grades),
        fehlE: this.getGrade('FehltageE', student.grades)?.value,
        fehlUE: this.getGrade('FehltageUE', student.grades)?.value,
        versetzung: this.selectedTerm.isEJ ? this.getGrade('Versetzung', student.grades)?.value === null : null,
        gta: this.createGtaText(student)
      }
      const religion = this.getGrade('Religion', student.grades)
      if (religion !== undefined) {
        obj.rel = { name: religion.gradeMeta.topic, value: religion.value, text: this.createReligionText(student.firstName, religion.value) }
      }
      return obj
    },
    createStudentsArr (students) {
      const arr = []
      for (const student of students) {
        arr.push(this.createStudentObj(student))
      }
      return arr
    },
    getPlaceholder (type, grades) {
      const grade = this.getGrade(type, grades)
      return grade.value === null ? '' : String(grade.value)
    },
    getGrade (type, grades) {
      const termString = this.selectedTerm.term
      const retValue = grades.find(
        e => e.gradeMeta.course.courseType.name === 'Zeugnis' &&
          e.gradeMeta.gradeType === termString &&
          e.gradeMeta.topic === type
      )
      return retValue
    },
    setFirstClass (school) {
      this.selectedClass = school.classes[0]
      this.selectedStudent = school.classes[0].students[0]
    },
    setFirstStudent (aClass) {
      this.selectedStudent = aClass.students[0]
    },
    cutName (courseType) {
      return courseType.name.length > 16 ? courseType.abbr : courseType.name
    },
    getHeadGrade (type, courseId) {
      const termString = this.selectedTerm.term
      const filteredArray = this.selectedStudent.grades.filter(e => {
        return e.gradeMeta.gradeType === termString && e.gradeMeta.topic === type && e.gradeMeta.course.courseType.id === courseId
      })
      // return filteredArray[0]?.value == null ? 'nE!' : filteredArray[0].value
      return filteredArray[0]?.value ?? 'nE!'
    },
    // getHeadGradeTeacher (type, courseId) {
    //   const termString = this.selectedTerm.term
    //   const filteredArray = this.selectedStudent.grades.filter(e => {
    //     return e.gradeMeta.gradeType === termString && e.gradeMeta.topic === type && e.gradeMeta.course.courseType.id === courseId
    //   })
    //   const teacher = filteredArray[0].gradeMeta.teacher
    getHeadGradeTeacher (teacher) {
      return `${teacher.lastName.substr(0, 3)}${teacher.firstName.substr(0, 2)}`
    },

    /**
     * @param grades
     * @return {Object.<string, {name: string, value: number}} An object with grade abbreviation as keys and value objects which include the full name and the grade value.
     */
    gradeObj (grades) {
      const termString = this.selectedTerm.term
      const retObj = {}
      if (this.is10OS()) {
        for (const grade of grades) {
          const courseType = grade.gradeMeta.course.courseType
          if (grade.gradeMeta.topic === 'WTH_9') {
            retObj.wth2 = {
              name: 'Wirtschaft-Technik-Haushalt/Soziales_9',
              value: grade.value
            }
          }
          if (grade.gradeMeta.topic === 'Jahresnote' && (grade.value !== null || grade.note === 'befreit') && grade.gradeMeta.gradeType === termString) {
            const isMUorKU = courseType.abbr === 'MU' || courseType.abbr === 'KU'
            const isGGG = courseType.abbr === 'GK' || courseType.abbr === 'GEO' || courseType.abbr === 'GE'
            if (grade.note !== 'neunte') {
              if (isMUorKU) {
                retObj.mk = {
                  name: courseType.name,
                  value: grade.value
                }
              } else if (!isMUorKU) {
                retObj[courseType.abbr.toLowerCase()] = {
                  name: courseType.name,
                  value: grade.value
                }
                if (courseType.abbr === 'FR') retObj.fr.note = `(ab Klassenstufe ${grade.note})`
                if (courseType.abbr === 'SPO') {
                  retObj.spo.value = grade.note === 'befreit' ? grade.note : grade.value
                }
              }
            } else if (grade.note === 'neunte') {
              if (isMUorKU) {
                retObj.mk2 = {
                  name: courseType.name,
                  value: grade.value
                }
              } else if (isGGG) {
                retObj.ggg = {
                  name: courseType.name,
                  value: grade.value
                }
              } else if (!isMUorKU && !isGGG) {
                retObj[courseType.abbr.toLowerCase()] = {
                  name: courseType.name,
                  value: grade.value
                }
              }
            }
          }
        }
      }
      for (const grade of grades) {
        if (grade.gradeMeta.topic === 'Zeugnisnote' && grade.value !== null && grade.gradeMeta.gradeType === termString) {
        // if (grade.gradeMeta.topic === 'Zeugnisnote' && grade.gradeMeta.gradeType === termString) {
          const courseType = grade.gradeMeta.course.courseType
          retObj[courseType.abbr.toLowerCase()] = {
            name: courseType.name,
            value: grade.value
          }
        }
      }
      return retObj
    },
    hasGradeValues (gradeArray) {
      for (const pair of gradeArray) {
        for (const grade of pair) {
          if (
            grade.value !== null &&
            grade.value !== '-' && (
              grade.value <= 6 || (this.isSek2() && grade.value <= 15)
            )
          ) return true
        }
      }
      return false
    },
    hasHeadGradeValues () {
      for (const headName of ['Betragen', 'Fleiß', 'Mitarbeit', 'Ordnung']) {
        const headGrade = this.getGrade(headName, this.selectedStudent.grades)
        if (headGrade !== undefined && headGrade.value !== null) return true
      }
      return false
    },
    getMedianObj (gradeArray) {
      const medianObj = { sum: 0, count: 0 }
      for (const pair of gradeArray) {
        for (const grade of pair) {
          if (
            grade.value !== null &&
            grade.value !== '-' && (
              grade.value <= 6 || (this.isSek2() && grade.value <= 15)
            )
          ) {
            medianObj.sum += grade.value
            medianObj.count++
          }
        }
      }
      return medianObj
    },
    getMedianHeadObj () {
      const headObj = { sum: 0, count: 0 }
      for (const headName of ['Betragen', 'Fleiß', 'Mitarbeit', 'Ordnung']) {
        const headValue = this.getGrade(headName, this.selectedStudent.grades)?.value
        headObj.sum += headValue !== undefined ? headValue : 0
        if (headValue > 0) headObj.count++
      }
      return headObj
    },
    getMedian (gradeArray) {
      const medianObj = this.getMedianObj(gradeArray)
      return medianObj.count > 0 ? medianObj.sum / medianObj.count : 0
    },
    getMedianAll (gradeArray) {
      const medianObj = this.getMedianObj(gradeArray)
      const headObj = this.getMedianHeadObj()
      return medianObj.count + headObj.count > 0 ? (medianObj.sum + headObj.sum) / (medianObj.count + headObj.count) : 0
    },
    getMedianHead () {
      const headObj = this.getMedianHeadObj()
      return headObj.count > 0 ? headObj.sum / headObj.count : 0
    },
    filteredGrades () {
      const termString = this.selectedTerm.term
      let filteredArray = []
      if (!this.is10OSEJ()) {
        filteredArray = this.selectedStudent.grades.filter(e => {
          if (!(e.gradeMeta.course.courseType.abbr === 'FR' && e.value === null)) {
            return e.gradeMeta.gradeType === termString && e.gradeMeta.topic === 'Zeugnisnote'
          }
          return false
        })
      } else {
        const preFilteredArray = this.selectedStudent.grades.filter(e => {
          return e.gradeMeta.gradeType === termString && (e.gradeMeta.topic === 'Zeugnisnote' || e.gradeMeta.topic === 'Jahresnote')
        })
        for (const e of preFilteredArray) {
          if (e.value !== null && e.gradeMeta.topic === 'Zeugnisnote') {
            filteredArray.push(e)
          }
          if ((e.value !== null || e.note === 'befreit') && e.gradeMeta.topic === 'Jahresnote') {
            const valueIsNull = preFilteredArray.findIndex(
              f => f.value !== null &&
                f.gradeMeta.topic === 'Zeugnisnote' &&
                f.gradeMeta.course.courseType.id === e.gradeMeta.course.courseType.id
            ) === -1
            if (valueIsNull) filteredArray.push(e)
          }
        }
        const wth = this.selectedStudent.grades.find(e => e.gradeMeta.topic === 'WTH_9')
        if (wth !== undefined) {
          wth.gradeMeta.course.courseType.abbr = 'WTH'
          wth.gradeMeta.course.courseType.id = 19
          wth.gradeMeta.course.courseType.name = 'Wirtschaft-Technik-Haushalt/Soziales'
          wth.value = wth.value !== null ? wth.value : '-'
          wth.note = 'neunte'
          filteredArray.push(wth)
        } else console.error('Wth grade ninth class does not exist!')
      }
      filteredArray.sort((a, b) => {
        const firstName = a.gradeMeta.course.courseType.name
        const secondName = b.gradeMeta.course.courseType.name
        return firstName < secondName ? -1 : 1
      })
      const retArray = this.createReportGradeColArray(filteredArray)
      return retArray
    },
    createReportGradeColArray (filteredArray) {
      const retArray = []
      if (this.reportColumns.count === 2) {
        for (let i = 0; i < filteredArray.length; i = i + 2) {
          const left = filteredArray[i]
          const leftObj = {
            gradeId: left.id,
            courseId: left.gradeMeta.course.courseType.id,
            name: this.cutName(left.gradeMeta.course.courseType),
            value: left.value,
            currentgradevalue: left.value,
            note: left.note,
            teacher: left.gradeMeta.teacher
          }

          if (filteredArray.length === i + 1) {
            retArray.push([leftObj])
          } else {
            const right = filteredArray[i + 1]
            retArray.push([
              leftObj,
              {
                gradeId: right.id,
                courseId: right.gradeMeta.course.courseType.id,
                name: this.cutName(right.gradeMeta.course.courseType),
                value: right.value,
                currentgradevalue: right.value,
                note: right.note,
                teacher: right.gradeMeta.teacher
              }
            ])
          }
        }
      } else if (this.reportColumns.count === 3) {
        for (let i = 0; i < filteredArray.length; i = i + 3) {
          const left = filteredArray[i]
          const leftObj = {
            gradeId: left.id,
            courseId: left.gradeMeta.course.courseType.id,
            name: this.cutName(left.gradeMeta.course.courseType),
            value: left.value,
            currentgradevalue: left.value,
            note: left.note,
            teacher: left.gradeMeta.teacher
          }

          if (i + 1 === filteredArray.length) {
            retArray.push([leftObj])
          } else {
            const center = filteredArray[i + 1]
            const centerObj = {
              gradeId: center.id,
              courseId: center.gradeMeta.course.courseType.id,
              name: this.cutName(center.gradeMeta.course.courseType),
              value: center.value,
              currentgradevalue: center.value,
              note: center.note,
              teacher: center.gradeMeta.teacher
            }
            if (i + 2 === filteredArray.length) {
              retArray.push([leftObj, centerObj])
            } else {
              const right = filteredArray[i + 2]
              const rightObj = {
                gradeId: right.id,
                courseId: right.gradeMeta.course.courseType.id,
                name: this.cutName(right.gradeMeta.course.courseType),
                value: right.value,
                currentgradevalue: right.value,
                note: right.note,
                teacher: right.gradeMeta.teacher
              }
              retArray.push([leftObj, centerObj, rightObj])
            }
          }
        }
      }
      return retArray
    },
    createFullName (fn, ln) {
      return `${ln}, ${fn}`
    },
    // isFullySelected () {
    //   return this.selectedGrade.key !== undefined &&
    //     this.selectedGrade.anmeldungKey !== undefined &&
    //     this.selectedGrade.betragen !== undefined &&
    //     this.selectedGrade.mitarbeit !== undefined &&
    //     this.selectedGrade.fleisz !== undefined &&
    //     this.selectedGrade.ordnung !== undefined &&
    //     this.selectedGrade.versetzungsvermerk !== undefined
    // },
    // checkBox (value, type) {
    //   return type === 'corona'
    //     ? this.postGrade(this.selectedGrade.stern.key, 'stern', value)
    //     : this.postGrade(this.selectedGrade.versetzungsvermerk.key, 'versetzungsvermerk', value)
    // },
    async checkBox (value, type) {
      const currentGrade = this.getGrade(type, this.selectedStudent.grades)
      const url = `${config.api_prisma_set_report}/${currentGrade.id}/${value}`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.post(url, {}, axiosConfig)
        if (res.data.success) {
          if (currentGrade.timeoutHandle !== undefined) clearTimeout(currentGrade.timeoutHandle)
          this.$set(currentGrade, 'error', '')
          this.$set(currentGrade, 'success', 'hochgeladen')
          this.$set(currentGrade, 'timeoutHandle', setTimeout(() => { currentGrade.success = '' }, 5000))
          currentGrade.value = value === 'null' ? '' : value
        }
      } catch (err) {
        this.$set(currentGrade, 'error', 'nicht erlaubt!')
        console.error(err)
      }

      // return type === 'corona'
      //   ? this.postGrade(this.selectedGrade.stern.key, 'stern', value)
      //   : this.postGrade(this.selectedGrade.versetzungsvermerk.key, 'versetzungsvermerk', value)
    },
    etwasZuviel (v) {
      return ((((v === undefined) ? 0 : v.length) < 275)) || 'Das könnte nicht mehr auf das Zeugnis passen, ich nehme an du weißt was du da tust?'
    },
    kopfnotenText (type) {
      if (this.computed[0] !== undefined && this.computed[0][type] !== undefined) {
        const { anzahl, liste } = this.anzahlEintragungen(type)
        const kopfnotenObj = { betragen: 'Betragen', fleisz: 'Fleiß', mitarbeit: 'Mitarbeit', ordnung: 'Ordnung' }
        return `${kopfnotenObj[type]} (${anzahl}/${liste.length})`
      }
      // return 'Betragen'
    },
    // checkMaxChars () {
    //   if (this.selectedGrade.einschaetzung.langtext !== undefined) {
    //     if (this.selectedGrade.einschaetzung.langtext.length > 5) {
    //       this.$set(this.selectedGrade.einschaetzung, 'langtext', this.selectedGrade.einschaetzung.langtext.slice(0, 5))
    //     }
    //   }
    // },
    computedHeadGradeCount (type) {
      const termString = this.selectedTerm.term
      const counter = { notNullCount: 0, allCount: 0 }
      for (const grade of this.selectedStudent.grades) {
        if (
          grade.gradeMeta.gradeType === termString &&
          grade.gradeMeta.topic === type &&
          grade.gradeMeta.course.courseType.name !== 'Zeugnis'
        ) {
          if (grade.value !== null) counter.notNullCount++
          counter.allCount++
        }
      }
      return `${counter.notNullCount}/${counter.allCount}`
    },
    computedHeadGrade (type) {
      const termString = this.selectedTerm.term
      const justTypedHeadGrades =
        this.selectedStudent.grades.filter(e => {
          return e.value !== null &&
          e.gradeMeta.gradeType === termString &&
          e.gradeMeta.topic === type &&
          e.gradeMeta.course.courseType.name !== 'Zeugnis'
        })
      const value = (justTypedHeadGrades.reduce((a, b) => a + Number(b.value), 0) / justTypedHeadGrades.length).toFixed(2)
      if (!isNaN(value)) {
        return value
      } else {
        return '-'
      }
    },

    anzahlEintragungen (type) {
      const liste = this.computed[this.currentKey][type]
      return {
        anzahl: liste.filter((el) => el.value !== null).length,
        summeGewichte: liste.filter((el) => el.value !== null).reduce((a, b) => a + b.weight, 0),
        liste
      }
    },

    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    async getGtas () {
      const url = config.api_prisma_get_report_gtas
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      return (await this.$axios.get(url, axiosConfig)).data
    },
    setGtas (myGtas) {
      const gtas = []
      for (const gta of myGtas) {
        gtas.push({ id: gta.id })
      }
      const url = `${config.api_prisma_set_report_gtas}/${this.selectedStudent.id}`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      this.$axios.post(url, { gtas }, axiosConfig)
        .then((res) => {
          if (res.data.success) {
            if (this.selectedStudent.gta_timeoutHandle !== undefined) clearTimeout(this.selectedStudent.gta_timeoutHandle)
            this.$set(this.selectedStudent, 'gta_error', '')
            this.$set(this.selectedStudent, 'gta_success', 'hochgeladen')
            this.$set(this.selectedStudent, 'gta_timeoutHandle', setTimeout(() => { this.selectedStudent.gta_success = '' }, 5000))
          }
        })
    },
    async setNote (type, value) {
      const currentGrade = this.getGrade(type, this.selectedStudent.grades)
      const url = `${config.api_prisma_set_report}/${currentGrade.id}/notiz`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.post(url, { notiz: value }, axiosConfig)
        if (res.data.success) {
          if (currentGrade.timeoutHandle !== undefined) clearTimeout(currentGrade.timeoutHandle)
          this.$set(currentGrade, 'noteError', '')
          this.$set(currentGrade, 'noteSuccess', 'hochgeladen')
          this.$set(currentGrade, 'noteTimeoutHandle', setTimeout(() => { currentGrade.noteSuccess = '' }, 5000))
        }
      } catch (err) {
        this.$set(currentGrade, 'noteError', 'nicht erlaubt!')
        console.error(err)
      }
    },
    async setGrade (type, value) {
      const currentGrade = this.getGrade(type, this.selectedStudent.grades)
      if (Number.isNaN(Number(value))) {
        if (currentGrade.timeoutHandle !== undefined) clearTimeout(currentGrade.timeoutHandle)
        this.$set(currentGrade, 'error', 'keine Zahl!')
        this.$set(currentGrade, 'timeoutHandle', setTimeout(() => { currentGrade.error = '' }, 5000))
        return
      }
      value = value === '' ? 'null' : value
      const url = `${config.api_prisma_set_report}/${currentGrade.id}/${value}`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      if (value === 'null' || Number(currentGrade.value) !== value) {
        try {
          const res = await this.$axios.post(url, {}, axiosConfig)
          if (res.data.success) {
            if (currentGrade.timeoutHandle !== undefined) clearTimeout(currentGrade.timeoutHandle)
            this.$set(currentGrade, 'error', '')
            this.$set(currentGrade, 'success', 'hochgeladen')
            this.$set(currentGrade, 'timeoutHandle', setTimeout(() => { currentGrade.success = '' }, 5000))
            currentGrade.value = value === 'null' ? '' : value
          }
        } catch (err) {
          this.$set(currentGrade, 'error', 'nicht erlaubt!')
          console.error(err)
        }
      }
    },
    // postGrade (key, type, value) {
    //   const i = this.grades.findIndex((elem) => elem[type].key === key)
    //   value = value === '' ? 'null' : value
    //   if (value === 'null' || type === 'stern' || type === 'versetzungsvermerk' || Number(this.grades[i][type].value) !== value) {
    //     this.grades[i][type].error = 'lade hoch'
    //     const url = `${config.api_post_grades}${this.$store.state.login_name}/${key}/${value}`
    //     this.$axios.post(url, { jwt_token: this.$store.state.auth_token })
    //       .then((res) => {
    //         if (res.data.success) {
    //           this.grades[i][type].error = []
    //           this.grades[i][type].success = 'hochgeladen'
    //           setTimeout(() => { this.grades[i][type].success = '' }, 5000)
    //           this.grades[i][type].value = String(value)
    //         }
    //       })
    //       .catch((err) => {
    //         console.error(err)
    //       })
    //   }
    // },
    postText (key, type, langtext) {
      const i = this.grades.findIndex((elem) => elem[type].key === key)
      this.grades[i][type].error = 'lade hoch'
      const url = `${config.api_post_grades}${this.$store.state.login_name}/${key}/langtext`
      this.$axios.post(url, { jwt_token: this.$store.state.auth_token, langtext })
        .then((res) => {
          if (res.data.success) {
            this.grades[i][type].error = []
            this.grades[i][type].success = 'hochgeladen'
            setTimeout(() => { this.grades[i][type].success = '' }, 5000)
            this.grades[i][type].langtext = langtext
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    computeGrades () {
      let url = `${config.api_student_grades}${this.$store.state.errorin_name}/wichtung/0`
      this.$axios.post(url, { jwt_token: this.$store.state.auth_token })
        .then(this.getDataComputed)
        .then((pages) => {
          this.computedError = `Ich lade noch ${pages - 1} von ${pages} weiteren Fachlehrer Kopfnoten zur Berechnung!`
          return pages
        })
        .then(async (pages) => {
        // .then((pages) => {
          // this.isLoading = false
          if (pages > 1) {
            for (let i = 1; i < pages; i++) {
              url = `${config.api_student_grades}${this.$store.state.login_name}/wichtung/${i}`
              await this.$axios.post(url, { jwt_token: this.$store.state.auth_token })
              // this.$axios.post(url, { jwt_token: this.$store.state.auth_token })
                .then(this.getDataComputed)
                .catch((err) => {
                  console.error(err)
                })
                .then(() => {
                  this.computedError = `Ich lade noch ${pages - (i + 1)} von ${pages} weiteren Kopfnoten!`
                })
            }
          }
        })

        .catch((err) => {
          console.error(err)
        })
        .then(() => {
          this.computedError = ''
          this.computedSuccess = 'Alle verfügbaren Fachlehrer Kopfnoten wurden heruntergeladen'
          setTimeout(() => { this.computedSuccess = '' }, 15000)
        })
    },
    async initialize () {
      // this.isPlayground = isAPlayground
      const url = config.api_prisma_get_report
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.get(url, axiosConfig)
        this.getData(res.data)
      } catch (err) {
        console.error(err)
      }
    },

    async getData (schools) {
      this.schools = schools.filter(e => e.classes.length > 0)
      this.selectedSchool = schools[0]
      this.selectedClass = schools[0].classes[0]

      for (const aSchool of this.schools) {
        for (const aClass of aSchool.classes) {
          for (const student of aClass.students) {
            student.fullName = this.createFullName(student.firstName, student.lastName)
          }
        }
      }
      this.selectedStudent = schools[0].classes[0].students[0]
      this.possibleGtaArr = await this.getGtas()
      this.isLoading = false
    }
  }
}

</script>

<style scoped>
  .v-select-grades {
    width: 100px;
    top: 10px;
  }
  .v-select-fehl {
    width: 120px;
  }
  .hasRisk {
    border: 1px solid white;
    border-collapse: collapse;
    padding: 5px;
  }
  .tooltip {
    hyphens: auto;
    text-align: justify;
  }
  strong.greenText {
    color: #72ab12;
  }
  strong.orangeText {
    color: orange;
  }
  strong.redText {
    color: red;
  }
</style>
