import Vue from 'vue'
import Keycloak from 'keycloak-js'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)

const initOptions = {
  url: config.kc.url,
  realm: config.kc.realm,
  clientId: config.kc.clientId,
  onLoad: 'login-required'
}

// if (process.env.VUE_APP_IS_PLAYGROUND === 'true') {
//   initOptions.url = config.kc.url
//   initOptions.realm = config.kc.realm
//   initOptions.clientId = config.kc.clientId
// }

const keycloak = new Keycloak(initOptions)

keycloak
  .init({ onLoad: initOptions.onLoad, enableLogging: true })
  .then((auth) => {
    if (!auth) {
      window.location.reload()
    } else {
      Vue.prototype.$keycloak = keycloak
      console.info('Authenticated')
    }

    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App)
    }).$mount('#app')

    setInterval(() => {
      keycloak.updateToken(70).then(refreshed => {
        if (refreshed) {
          console.debug('Token refreshed' + refreshed)
        } else {
          console.warn('Token not refreshed, valid for ' +
          Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
        }
      }).catch(() => {
        console.error('Failed to refresh token')
      })
    }, 60 * 1000)
  }).catch((err) => {
    console.error('Authenticated Failed')
    if (err.error === 'Timeout when waiting for 3rd party check iframe message.') {
      const elemDiv = document.createElement('div')
      elemDiv.style.cssText = 'position: absolute; left: 25%; width: 50%; display: grid; justify-content: center;'
      elemDiv.innerHTML = `
        <img src="https://res.cloudinary.com/bohmeyer-schuster-gmbh/image/upload/w_300,c_fill,q_auto:low,f_auto/v1574938619/products/images/arbeitsstelle-verkehrsschild-nr-123-2868.jpg" style="justify-self: center;"><br>
        <span style="hyphens: auto; text-align: justify">Leider ist der Anmeldeserver derzeit nicht erreichbar - Wir wissen Bescheid und sind bei der Reparatur. Komm bitte später einfach nochmal wieder oder hör in der Zeit Teile einer guten Metal Oper <a href="https://www.youtube.com/watch?v=-fHN4Qceip4">hier</a>, <a href="https://www.youtube.com/watch?v=vn7P3oVMnys">hier</a> oder <a href="https://www.youtube.com/watch?v=Ff4dFzMXYsM">hier</a>.</span>
      `
      document.body.appendChild(elemDiv)
    }
  })
