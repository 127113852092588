<template>
  <v-container v-if="$keycloak !== undefined && $keycloak.authenticated">
    <v-row>
      <v-col class="mt-4 mb-4">
        <v-card>
          <v-card-title>
            Zensuren
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
            >
            </v-text-field>
          </v-card-title>
          <v-data-table
            name="nw_data_table"
            fixed-header
            :height="windowHeight - heightOffset"
            :headers="headers"
            :items="grades"
            :search="search"
            item-key="name"
            sort-by="name"
            :loading="isLoading"
            :footer-props="{
              itemsPerPageOptions: isPhone ? [1, -1] : [-1],
            }"
          >
            <template v-slot:[`header.e`] = "{ header }">
               {{ header.text }}
               &empty;
            </template>
            <template v-for="type in ['A', 'B', 'HJ', 'EJ']" v-slot:[`item.${type}`] = "{ item }">
              <GradeRow
                :key="`${item.name}_${item[type]}`"
                :grades="item[type]"
                :isSek2="isSek2"
              />
            </template>
            <template v-slot:[`item.e`] = "{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on="on" v-bind="attrs" :color="getColor(level, item.e)" :text-color="getTextColor(level, item.e)" :dark="getDark(level, item.e)">
                    {{ item.e }}
                  </v-chip>
                </template>
                A<sub>{{ item.gradesWeight.A }}</sub> &divide; B<sub>{{ item.gradesWeight.B }}</sub>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GradeRow from '../components/GradeRow'
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)
const { getColor, getTextColor, getDark } = require('../../handler/tableHandler')

export default {
  name: 'ShowGradesPrismaS',
  components: { GradeRow },

  data () {
    return {
      isLoading: true,
      isSek2: undefined,
      level: undefined,
      search: '',
      heightOffset: 240,
      headers: [
        { text: 'Fach', value: 'name' },
        { text: 'A-Noten', value: 'A', filterable: false },
        { text: 'B-Noten', value: 'B', filterable: false },
        { text: '', value: 'e', filterable: false },
        { text: 'HJ', value: 'HJ', filterable: false },
        { text: 'EJ', value: 'EJ', filterable: false }
      ],
      windowHeight: window.innerHeight,
      isPhone: false,
      courses: [],
      grades: [
        // {
        //   name: 'Deutsch',
        //   a: [{ key: 0, value: 1, meta: { text: 'sehr gut', testedAt: '1981-04-29', topic: 'myTopic', backAt: '1981-06-01', overview: { 1: '3', 2: '3', 3: '4', 4: '2', 5: '0', 6: '1' }, median: '2.3' } }, { key: 1, value: 2, meta: { text: 'gut', testedAt: '1981-04-29', topic: 'myTopic2', backAt: '1981-06-01', overview: { 1: '3', 2: '3', 3: '4', 4: '2', 5: '0', 6: '1' }, median: '2.3' } }],
        //   b: [{ key: 2, value: 4, meta: { text: 'ausreichend', testedAt: '1981-04-29', topic: 'myTopic3', backAt: '1981-06-01', overview: { 1: '3', 2: '3', 3: '4', 4: '2', 5: '0', 6: '1' }, median: '2.3' } }, { key: 3, value: 5, meta: { text: 'mangelhaft', testedAt: '1981-04-29', topic: 'myTopic4', backAt: '1981-06-01', overview: { 1: '3', 2: '3', 3: '4', 4: '2', 5: '0', 6: '1' }, median: '2.3' } }, { key: 4, value: 3, meta: { text: 'befriedigend', testedAt: '1981-04-29', topic: 'myTopic', backAt: '1981-06-01', overview: { 1: '3', 2: '3', 3: '4', 4: '2', 5: '0', 6: '1' }, median: '2.3' } }],
        //   e: '3.2'
        // },
        // {
        //   name: 'Englisch',
        //   a: [{ key: 5, value: 1, meta: { text: 'sehr gut', testedAt: '1981-04-29', topic: 'myTopic', backAt: '1981-06-01', overview: { 1: '3', 2: '3', 3: '4', 4: '2', 5: '0', 6: '1' }, median: '2.3' } }, { key: 6, value: 2, meta: { text: 'gut', testedAt: '1981-04-29', topic: 'myTopic', backAt: '1981-06-01', overview: { 1: '3', 2: '3', 3: '4', 4: '2', 5: '0', 6: '1' }, median: '2.3' } }],
        //   b: [{ key: 7, value: 4, meta: { text: 'ausreichend', testedAt: '1981-04-29', topic: 'myTopic', backAt: '1981-06-01', overview: { 1: '3', 2: '3', 3: '4', 4: '2', 5: '0', 6: '1' }, median: '2.3' } }, { key: 8, value: 5, meta: { text: 'mangelhaft', testedAt: '1981-04-29', topic: 'myTopic', backAt: '1981-06-01', overview: { 1: '3', 2: '3', 3: '4', 4: '2', 5: '0', 6: '1' }, median: '2.3' } }, { key: 9, value: 3, meta: { text: 'befriedigend', testedAt: '1981-04-29', topic: 'myTopic', backAt: '1981-06-01', overview: { 1: '3', 2: '3', 3: '4', 4: '2', 5: '0', 6: '1' }, median: '2.3' } }],
        //   e: '3.2'
        // }
      ]
    }
  },

  mounted () {
    this.initialize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  methods: {
    onResize () {
      // this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    getColor (level, grade) {
      return getColor(level, grade)
    },
    getTextColor (level, grade) {
      return getTextColor(level, grade)
    },
    getDark (grade) {
      return getDark(grade)
    },
    async initialize () {
      const url = config.api_prisma_get_student_grades
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.get(url, axiosConfig)
        this.level = res.data.level
        this.isSek2 = res.data.isSek2
        const hasZeugnisNoten = { hj: false, ej: false }
        this.addExternalGradesToDataRow(res.data.externalGradesArray)
        this.addGradesToDataRow(res.data.gradesArray, hasZeugnisNoten)
        this.createCurrentDataRowMedian(this.courses)
        this.removeUnnecessaryHeaders(hasZeugnisNoten)

        this.isLoading = false
      } catch (err) {
        console.error(err)
      }
    },

    addExternalGradesToDataRow (externalGradesArray) {
      for (const exGrade of externalGradesArray) {
        if (exGrade.value !== null) {
          let dataRow = {}
          const name = exGrade.course.courseType.name
          if (!this.courses.includes(name)) {
            this.courses.push(name)
            dataRow = { name, A: [], B: [], HJ: [], EJ: [] }
            dataRow[exGrade.gradeType].push({ key: `Ex_${exGrade.id}`, value: exGrade.value, note: exGrade.note })
            this.grades.push(dataRow)
          } else {
            dataRow = this.grades.find(row => row.name === name)
            dataRow[exGrade.gradeType].push({ key: `Ex_${exGrade.id}`, value: exGrade.value, note: exGrade.note })
          }
        }
      }
    },
    addGradesToDataRow (gradesArray, hasZeugnisNoten) {
      for (const grade of gradesArray) {
        let dataRow = {}
        const gradeType = grade.gradeMeta.gradeType
        const name = grade.gradeMeta.course.courseType.name
        const primaryGradesWeight = grade.gradeMeta.course.primaryGradesWeight
        const gradesWeight = { A: primaryGradesWeight / 100, B: (100 - primaryGradesWeight) / 100 }
        if (grade.value !== null) {
          if (!this.courses.includes(name)) {
            this.courses.push(name)
            dataRow = { name, gradesWeight, A: [], B: [], HJ: [], EJ: [] }
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
            this.grades.push(dataRow)
          } else {
            dataRow = this.grades.find(row => row.name === name)
            if (dataRow.gradesWeight === undefined) dataRow.gradesWeight = gradesWeight
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
          }
        }
        if ((gradeType === 'EJ' || gradeType === 'HJ') && grade.value === null && grade.note === 'teilgenommen') {
          if (!this.courses.includes(name)) {
            dataRow = { name, gradesWeight, A: [], B: [], HJ: [], EJ: [] }
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
            this.grades.push(dataRow)
          } else {
            dataRow = this.grades.find(row => row.name === name)
            if (dataRow.gradesWeight === undefined) dataRow.gradesWeight = gradesWeight
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
          }
        }
        if (gradeType === 'HJ') hasZeugnisNoten.hj |= true
        if (gradeType === 'EJ') hasZeugnisNoten.ej |= true
      }
    },
    createCurrentDataRowMedian (courses) {
      for (const course of courses) {
        const median = { sumA: 0, counterA: 0, sumB: 0, counterB: 0 }
        const dataRow = this.grades.find(row => row.name === course)
        for (const aAGrade of dataRow.A) {
          median.sumA += aAGrade.value
          median.counterA++
        }
        for (const aBGrade of dataRow.B) {
          median.sumB += aBGrade.value
          median.counterB++
        }
        const medianA = isNaN(median.sumA / median.counterA) ? 0 : median.sumA / median.counterA
        const medianB = isNaN(median.sumB / median.counterB) ? 0 : median.sumB / median.counterB
        if (medianA === 0) dataRow.e = medianB.toFixed(2)
        if (medianB === 0) dataRow.e = medianA.toFixed(2)
        if (medianA !== 0 && medianB !== 0) dataRow.e = (dataRow.gradesWeight.A * medianA + dataRow.gradesWeight.B * medianB).toFixed(2)
      }
    },
    removeUnnecessaryHeaders (hasZeugnisNoten) {
      for (const term of ['hj', 'ej']) {
        if (!hasZeugnisNoten[term]) {
          const headerIndex = this.headers.findIndex(e => e.text === term.toUpperCase())
          this.headers.splice(headerIndex, 1)
        }
      }
    }
  }
}

</script>

<style scoped>
  span.pointer { cursor: default; }
</style>
