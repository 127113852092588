import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store/index'
// import User from '../views/User'
// import Grades from '../views/Grades'
// import Timetable from '../views/Timetable'

// import AddHeadGradesFL from '../views/AddHeadGradesFL'
// import AddHeadGradesKL from '../views/AddHeadGradesKL'
// import AddGradesFL from '../views/AddGradesFL'
// import AddGradesPrismaFL from '../views/AddGradesPrismaFL'
import AddGrades from '../views/AddGrades'
import ShowGradesKL from '../views/ShowGradesKL'
// import AddHeadGradesPrismaKL from '../views/AddHeadGradesPrismaKL'
import Reports from '../views/Reports'
import ShowGradesPrismaSLExtension from '../views/ShowGradesPrismaSLExtension'
import ShowGradesPrismaS from '../views/ShowGradesPrismaS'
import ShowTimetablePrismaS from '../views/ShowTimetablePrismaS'
import ShowGradesPrismaE from '../views/ShowGradesPrismaE'
import ShowCalendar from '../views/ShowCalendar'
import ShowCalculator from '../views/ShowCalculator'
import ChangeSeatingPlanKL from '../views/ChangeSeatingPlanKL'
import ChangeAttandanceFL from '../views/ChangeAttandanceFL'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root'
    // redirect: '/user'
  },
  {
    path: '*',
    name: 'Missing',
    redirect: '/'
    // redirect: '/user'
  },
  // {
  //   path: '/user',
  //   name: 'User',
  //   component: User,
  //   beforeEnter: isAuthenticated
  // },
  {
    path: '/addgrades',
    name: 'AddGrades',
    component: AddGrades,
    beforeEnter: isTeacher
  },
  // {
  //   path: '/addgradesprismafl',
  //   name: 'AddGradesPrismaFL',
  //   component: AddGradesPrismaFL,
  //   beforeEnter: isTeacher
  // },
  {
    path: '/showgradeskl',
    name: 'ShowGradesKL',
    component: ShowGradesKL,
    beforeEnter: isHeadTeacher
  },
  {
    path: '/showgradesprismaslextension/:email',
    name: 'ShowGradesPrismaSLExtension',
    component: ShowGradesPrismaSLExtension,
    beforeEnter: isHeadMaster
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    beforeEnter: isHeadTeacher
  },
  {
    path: '/showgradesprismas',
    name: 'ShowGradesPrismaS',
    component: ShowGradesPrismaS,
    beforeEnter: isStudent
  },
  {
    path: '/showtimetableprismas',
    name: 'ShowTimetablePrismaS',
    component: ShowTimetablePrismaS,
    beforeEnter: isStudent
  },
  {
    path: '/showgradesprismae',
    name: 'ShowGradesPrismaE',
    component: ShowGradesPrismaE,
    beforeEnter: isParent
  },
  {
    path: '/showcalendar',
    name: 'ShowCalendar',
    component: ShowCalendar,
    beforeEnter: isHeadMaster
  },
  {
    path: '/showcalculator',
    name: 'ShowCalculator',
    component: ShowCalculator,
    beforeEnter: isHeadMaster
  },
  {
    path: '/changeseatingplankl',
    name: 'ChangeSeatingPlanKL',
    component: ChangeSeatingPlanKL,
    beforeEnter: isHeadTeacher

  },
  {
    path: '/changeattandancefl',
    name: 'ChangeAttandanceFL',
    component: ChangeAttandanceFL,
    beforeEnter: isTeacher
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

// function isAuthenticated (to, from, next) {
//   if (Vue.prototype.$keycloak !== undefined) next()
//   else next('/')
// }

function isTeacher (to, from, next) {
  if (Vue.prototype.$keycloak.realmAccess.roles.includes('lehrer')) next()
  else next('/user')
}

function isHeadTeacher (to, from, next) {
  const keycloak = Vue.prototype.$keycloak
  if (keycloak.hasRealmRole('schulleiter') || keycloak.hasRealmRole('klassenlehrer')) next()
  else next('/user')
}

function isHeadMaster (to, from, next) {
  const keycloak = Vue.prototype.$keycloak
  if (keycloak.hasRealmRole('schulleiter')) next()
  else next('/user')
}

function isStudent (to, from, next) {
  if (Vue.prototype.$keycloak.realmAccess.roles.includes('schueler')) next()
  else next('/user')
}

function isParent (to, from, next) {
  if (Vue.prototype.$keycloak.realmAccess.roles.includes('eltern')) next()
  else next('/user')
}
