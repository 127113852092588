<template>
  <v-container v-if="$keycloak !== undefined && $keycloak.authenticated">
    <v-row>
      <v-col class="mt-4 mb-4">
        <v-card>
          <v-card-title>
            <v-tooltip bottom v-if="!isLoading">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  class="mr-3"
                  @click="$router.push({ name: 'ShowGradesPrismaKL' })">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              gehe zurück zur Klassenübersicht
            </v-tooltip>
            {{ fullName }}
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
            >
            </v-text-field>
          </v-card-title>
          <v-card-subtitle>
            <v-tooltip bottom v-if="!isLoading">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  class="mr-3"
                  disabled>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              importiere externe Noten
            </v-tooltip>

            <v-tooltip bottom v-if="!isLoading">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  class="mr-3"
                  @click="showFileInput = true">
                  <v-icon>mdi-paperclip</v-icon>
                </v-btn>
              </template>
              importiere externe Noten
            </v-tooltip>
          </v-card-subtitle>

          <div class="d-flex align-center justify-end ml-1 mr-1">
           <v-dialog
              v-model="showFileInput"
              max-width="400"
            >
              <v-card>
                <v-card-title class="text-h5">
                  externe Noten importieren
                </v-card-title>
                <v-card-text>
                  <v-file-input
                    v-model="file"
                    accept=".xlsx"
                    truncate-length="30"
                    :rules="[() => {
                      if (this.file !== null && !isXLSX()) return 'only .xlsx supported'
                      else return true
                    }]"
                  ></v-file-input>
                  <v-spacer/>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="setExternalGrades"
                    :disabled="this.file === null || !isXLSX()"
                    icon
                  >
                    <v-icon>mdi-cloud-upload</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
           </v-dialog>
          </div>

          <v-data-table
            name="nw_data_table"
            fixed-header
            :height="windowHeight - heightOffset"
            :headers="headers"
            :items="grades"
            :search="search"
            item-key="name"
            sort-by="name"
            :loading="isLoading"
            :footer-props="{
              itemsPerPageOptions: isPhone ? [1, -1] : [-1],
            }"
          >
            <template v-slot:[`header.e`] = "{ header }">
               {{ header.text }}
               &empty;
            </template>
            <template v-for="type in ['A', 'B', 'HJ', 'EJ']" v-slot:[`item.${type}`] = "{ item }">
              <GradeRow
                :key="`${item.name}_${item[type]}`"
                :grades="item[type]"
                :isSek2="isSek2"
              />
            </template>
            <template v-slot:[`item.e`] = "{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on="on" v-bind="attrs" :color="getColor(item.e)" :dark="getDark(item.e)">
                    {{ item.e }}
                  </v-chip>
                </template>
                A<sub>{{ item.gradesWeight.A }}</sub> &divide; B<sub>{{ item.gradesWeight.B }}</sub>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GradeRow from '../components/GradeRow'
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)

const { getColor, getDark } = require('../../handler/tableHandler')
const exceljs = require('exceljs')

export default {
  name: 'ShowGradesPrismaSLExtension',
  components: { GradeRow },

  data () {
    return {
      email: this.$route.params.email,
      fullName: '',
      isLoading: true,
      showFileInput: false,
      file: null,
      isSek2: undefined,
      search: '',
      heightOffset: 280,
      headers: [
        { text: 'Fach', value: 'name' },
        { text: 'A-Noten', value: 'A', filterable: false },
        { text: 'B-Noten', value: 'B', filterable: false },
        { text: '', value: 'e', filterable: false },
        { text: 'HJ', value: 'HJ', filterable: false },
        { text: 'EJ', value: 'EJ', filterable: false }
      ],
      windowHeight: window.innerHeight,
      isPhone: false,
      courses: [],
      grades: []
    }
  },

  mounted () {
    this.initialize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  methods: {
    onResize () {
      // this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    isXLSX () {
      return this.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    async setExternalGrades () {
      const currentWorkbook = new exceljs.Workbook()
      await currentWorkbook.xlsx.load(this.file)
      const grades = {}
      currentWorkbook.getWorksheet('A').eachRow((row, rowNumber) => {
        const course = row.getCell(1).value
        row.eachCell((cell, colNumber) => {
          if (colNumber === 1) {
            grades[course] = { A: [] }
          }
          if (colNumber > 1) {
            grades[course].A.push(cell.value)
          }
        })
      })
      currentWorkbook.getWorksheet('B').eachRow((row) => {
        const course = row.getCell(1).value
        row.eachCell((cell, colNumber) => {
          if (colNumber === 1) {
            if (grades[course] !== undefined) grades[course].B = []
            else grades[course] = { B: [] }
          }
          if (colNumber > 1) {
            grades[course].B.push(cell.value)
          }
        })
      })

      console.log('external grades will be set to:')
      // console.table(grades)
      const url = `${config.api_prisma_create_external_grades}/${this.email}`
      const axiosData = { grades }
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.post(url, axiosData, axiosConfig)
        console.log(res.data)
      } catch (err) {
        console.error(err)
      }
      this.file = null
      this.showFileInput = false
    },
    getColor (grade) {
      return getColor(grade)
    },
    getDark (grade) {
      return getDark(grade)
    },
    async initialize () {
      const url = `${config.api_prisma_get_student_grades}/${this.email}`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.get(url, axiosConfig)
        this.fullName = res.data.fullName
        this.isSek2 = res.data.isSek2
        const hasZeugnisNoten = { hj: false, ej: false }
        this.addExternalGradesToDataRow(res.data.externalGradesArray)
        this.addGradesToDataRow(res.data.gradesArray, hasZeugnisNoten)
        this.createCurrentDataRowMedian(this.courses)
        this.removeUnnecessaryHeaders(hasZeugnisNoten)

        this.isLoading = false
      } catch (err) {
        console.error(err)
      }
    },

    addExternalGradesToDataRow (externalGradesArray) {
      for (const exGrade of externalGradesArray) {
        if (exGrade.value !== null) {
          let dataRow = {}
          const name = exGrade.course.courseType.name
          if (!this.courses.includes(name)) {
            this.courses.push(name)
            dataRow = { name, A: [], B: [], HJ: [], EJ: [] }
            dataRow[exGrade.gradeType].push({ key: `Ex_${exGrade.id}`, value: exGrade.value, note: exGrade.note })
            this.grades.push(dataRow)
          } else {
            dataRow = this.grades.find(row => row.name === name)
            dataRow[exGrade.gradeType].push({ key: `Ex_${exGrade.id}`, value: exGrade.value, note: exGrade.note })
          }
        }
      }
    },
    addGradesToDataRow (gradesArray, hasZeugnisNoten) {
      for (const grade of gradesArray) {
        let dataRow = {}
        const gradeType = grade.gradeMeta.gradeType
        const name = grade.gradeMeta.course.courseType.name
        const primaryGradesWeight = grade.gradeMeta.course.primaryGradesWeight
        const gradesWeight = { A: primaryGradesWeight / 100, B: (100 - primaryGradesWeight) / 100 }
        if (grade.value !== null) {
          if (!this.courses.includes(name)) {
            this.courses.push(name)
            dataRow = { name, gradesWeight, A: [], B: [], HJ: [], EJ: [] }
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
            this.grades.push(dataRow)
          } else {
            dataRow = this.grades.find(row => row.name === name)
            if (dataRow.gradesWeight === undefined) dataRow.gradesWeight = gradesWeight
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
          }
        }
        if ((gradeType === 'EJ' || gradeType === 'HJ') && grade.value === null && grade.note === 'teilgenommen') {
          if (!this.courses.includes(name)) {
            dataRow = { name, gradesWeight, A: [], B: [], HJ: [], EJ: [] }
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
            this.grades.push(dataRow)
          } else {
            dataRow = this.grades.find(row => row.name === name)
            if (dataRow.gradesWeight === undefined) dataRow.gradesWeight = gradesWeight
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
          }
        }
        if (gradeType === 'HJ') hasZeugnisNoten.hj |= true
        if (gradeType === 'EJ') hasZeugnisNoten.ej |= true
      }
    },
    createCurrentDataRowMedian (courses) {
      for (const course of courses) {
        const median = { sumA: 0, counterA: 0, sumB: 0, counterB: 0 }
        const dataRow = this.grades.find(row => row.name === course)
        for (const aAGrade of dataRow.A) {
          median.sumA += aAGrade.value
          median.counterA++
        }
        for (const aBGrade of dataRow.B) {
          median.sumB += aBGrade.value
          median.counterB++
        }
        const medianA = isNaN(median.sumA / median.counterA) ? 0 : median.sumA / median.counterA
        const medianB = isNaN(median.sumB / median.counterB) ? 0 : median.sumB / median.counterB
        if (medianA === 0) dataRow.e = medianB.toFixed(2)
        if (medianB === 0) dataRow.e = medianA.toFixed(2)
        if (medianA !== 0 && medianB !== 0) dataRow.e = (dataRow.gradesWeight.A * medianA + dataRow.gradesWeight.B * medianB).toFixed(2)
      }
    },
    removeUnnecessaryHeaders (hasZeugnisNoten) {
      for (const term of ['hj', 'ej']) {
        if (!hasZeugnisNoten[term]) {
          const headerIndex = this.headers.findIndex(e => e.text === term.toUpperCase())
          this.headers.splice(headerIndex, 1)
        }
      }
    }
  }
}

</script>

<style scoped>
  span.pointer { cursor: default; }
</style>
