<template>
  <div>
    <!-- <h4>{{ firstName }} {{ lastName }}</h4> -->
    <div class="text-center">
      <v-progress-circular
        v-if="!isReady"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <table v-if="isReady">
      <tr v-for="custody in custodies" :key="custody.id">
        <td>
          <v-icon>{{ getIcon(custody.type) }}</v-icon> {{ custody.name }}
        </td>
        <td style="width: 10px;" />

        <td v-if="custody.telefon !== null">
          <v-icon>mdi-phone-outline</v-icon> {{ custody.telefon }}
        </td>
        <td v-else></td>
        <td style="width: 10px;" />

        <td v-if="custody.mobil !== null">
          <v-icon>mdi-cellphone</v-icon> {{ custody.mobil }}
        </td>
        <td v-else></td>
        <td style="width: 10px;" />

        <td v-if="custody.email !== null">
          <v-icon>mdi-email-outline</v-icon> {{ custody.email }}
        </td>
        <td v-else></td>
      </tr>
    </table>
  </div>
</template>

<script>
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)

export default {
  name: 'ContactDetailsTable',

  props: {
    oid: String,
    firstName: String,
    lastName: String
  },

  data () {
    return {
      isReady: false,
      custodies: []
    }
  },

  mounted () {
    this.initialize()
  },

  methods: {
    getIcon (type) {
      if (type === 'mother') return 'mdi-human-female'
      if (type === 'father') return 'mdi-human-male'
      if (type === 'other') return 'mdi-human-non-binary'
    },
    async initialize () {
      const url = `${config.api_prisma_get_contact}/${this.oid}`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.get(url, axiosConfig)
        this.initializeData(res.data)
        this.isReady = true
      } catch (err) {
        console.error(err)
      }
    },
    initializeData (data) {
      const filteredKeys = Object.keys(data).filter(e => e !== '_OID')
      for (const key of filteredKeys) {
        if (data[key] !== null) {
          const person = data[key].assPerson
          this.custodies.push({
            id: person._OID,
            type: this.getType(key),
            name: `${person.Vorname} ${person.Name}`,
            telefon: person.Tel1,
            mobil: person.TelMobil,
            email: person.EMail1
          })
        }
      }
    },
    getType (key) {
      if (key === 'assMutter') return 'mother'
      if (key === 'assVater') return 'father'
      if (key === 'assVormund') return 'other'
    }
  }

}
</script>
