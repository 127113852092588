import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  state: {
    auth_token: '',
    oid: '',
    login_name: '',
    isAuthenticated: false,
    view: [],
    bez: '',
    showAvatars: true,
    status: {}
  },
  mutations: {
    set_auth_token (state, value) {
      state.auth_token = value
    },
    set_oid (state, value) {
      state.oid = value
    },
    set_bez (state, value) {
      state.bez = value
    },
    set_login_name (state, value) {
      state.login_name = value
    },
    set_authenticated (state, value) {
      state.isAuthenticated = value
    },
    push_view (state, value) {
      state.view.push(value)
    },
    reset_view (state) {
      state.view = []
    },
    set_show_avatars (state, value) {
      state.showAvatars = value
    },
    set_status (state, value) {
      state.abesOnline = value
    },
    set_abes_online (state, value) {
      state.abesOnline.odata = value
    }
  },
  getters: {
    get_oid: state => {
      return state.oid
    },
    get_bez: state => {
      return state.bez
    },
    get_login_name: state => {
      return state.login_name
    },
    get_auth_token: state => {
      return state.auth_token
    },
    get_show_avatars: state => {
      return state.showAvatars
    },
    get_abes_online: state => {
      return state.abesOnline.odata
    }
  },
  actions: {},
  modules: {}
})
