<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mt-4 mb-4">
        <h1>
          Wichtungen
        </h1>
      </v-col>
    </v-row>

    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card class="pb-6" :elevation="hover ? 12 : 3">
          <v-row class="text-center">
            <v-col>
              <h3>
                Kopfnoten TODO - API (getAmountPerWeek, setAmountPerWeek), FE (initializeData, AddHeadGradesPrismaKL switchCalcModel), DB (initializeAmountPerWeekFromIndiware)
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-checkbox v-model="weighHeadGrades" label="Kopfnoten gewichten"></v-checkbox>
            </v-col>
          </v-row>
          <v-expand-transition>
            <div flat v-if="weighHeadGrades">
              <v-row v-for="i in 6" :key="`row${i}`">
                <v-col md="2" cols="1"></v-col>
                <v-col md="2" cols="3" class="d-flex align-start justify-end pt-7">Deutsch</v-col>
                <v-col md="2" cols="2" class="d-flex justify-start">
                  <v-select
                    outlined
                    rounded
                    style="max-width: 90px;"
                    :items="['1', '2', '3', '4', '5', '6', '7', '8', '9']"
                  ></v-select>
                </v-col>
                <v-col md="2" cols="3" class="d-flex align-start justify-end pt-7">Mathematik</v-col>
                <v-col md="2" cols="2" class="d-flex justify-start">
                  <v-select
                    outlined
                    rounded
                    style="max-width: 90px;"
                    :items="['1', '2', '3', '4', '5', '6', '7', '8', '9']"
                  ></v-select>
                </v-col>
                <v-col md="2" cols="1"></v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-card>
      </template>
    </v-hover>

    <v-hover>
      <template v-slot:default="{ hover }">
        <v-card class="mt-10" :elevation="hover ? 12 : 3">
          <v-row>
            <v-col class="d-flex justify-center">
              <h3>
                Fachnoten [ A-Noten ({{ Number(primaryGradesWeight).toFixed(2) }} : {{ Number(100 - primaryGradesWeight).toFixed(2) }}) B-Noten ] TODO API (getPrimaryGradesWeigh, setPrimaryGradesWeigh), FE (initializeData, App switchCalcModel)
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3" cols="1" class="mt-4"></v-col>
            <v-col md="4" cols="6" class="mt-4">
              <v-slider
                v-model="primaryGradesWeight"
                min="0.01"
                max="99.99"
                step="0.01"
                thumb-label="always"
              >

              </v-slider>
            </v-col>
            <v-col md="2" cols="4" class="mt-4">
              <v-spacer/>

              <v-text-field
                label="Gewicht A-Noten"
                v-model="primaryGradesWeight"
                style="width: 150px;"
                :rules="[numberRules]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="3" cols="1" class="mt-4"></v-col>
          </v-row>
          <!-- <v-row class="d-flex">
            <v-col md="3" cols="1"></v-col>
            <v-col md="4" cols="6" class="d-flex justify-center">A-Noten ({{ Number(primaryGradesWeight).toFixed(2) }} : {{ Number(100 - primaryGradesWeight).toFixed(2) }}) B-Noten</v-col>
            <v-col md="5" cols="5"></v-col>
          </v-row> -->
        </v-card>
      </template>
    </v-hover>
  </v-container>
</template>
<script>
export default {
  name: 'ShowCalculator',
  data: function () {
    return {
      primaryGradesWeight: 50.0,
      weighHeadGrades: false
    }
  },
  methods: {
    numberRules (input) {
      if (!Number.isNaN(Number(input))) {
        const number = Number(input).toFixed(2)
        console.log(`in ${input}`)
        if (number < 0.01) return 'zu klein!'
        else if (number > 99.99) return 'zu groß!'
        else return true
      } else {
        console.error(input)
        return 'keine Zahl!'
      }
    }
  }
}
</script>
