<template>
  <v-tooltip right v-if="!random && showContacts">
    <template v-slot:activator="{ on, attrs }">
      <v-avatar :size="size" v-on="on" v-bind="attrs">
        <v-img v-if="show && src !== undefined" :src="`data:image/jpeg;base64,${src}`" :key="oid"/> <!--  :key="oiditem.key" -->
        <v-icon v-if="(!show || src === undefined) && sex === 'm'">mdi-gender-male</v-icon>
        <v-icon v-if="(!show || src === undefined) && sex === 'f'">mdi-gender-female</v-icon>
      </v-avatar>
    </template>
    <ContactDetailsTable
      v-if="$store.getters.get_abes_online"
      :oid="oid"
      :firstName="firstName"
      :lastName="lastName"
    />
    <template v-else-if="!$store.getters.get_abes_online">
      <v-icon class="ml-1" color="red">mdi-database-off-outline</v-icon>
      <span style="color: orange;">
      ABES DB<br>
      nicht verfügbar</span>
    </template>
  </v-tooltip>
  <v-avatar :size="size" v-else-if="!showContacts">
    <v-img v-if="show && src !== undefined" :src="`data:image/jpeg;base64,${src}`" :key="oid"/> <!--  :key="oiditem.key" -->
    <v-icon v-if="(!show || src === undefined) && sex === 'm'">mdi-gender-male</v-icon>
    <v-icon v-if="(!show || src === undefined) && sex === 'f'">mdi-gender-female</v-icon>
  </v-avatar>
  <v-avatar :size="size" v-else-if="random">
    <v-img v-if="show && src !== undefined" :src="src" :key="oid"/>
    <v-icon v-if="(!show || src === undefined) && sex === 'm'">mdi-gender-male</v-icon>
    <v-icon v-if="(!show || src === undefined) && sex === 'f'">mdi-gender-female</v-icon>
  </v-avatar>
</template>

<script>
import ContactDetailsTable from './ContactDetailsTable'
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)

export default {
  name: 'ProtectedAvatar',
  components: { ContactDetailsTable },
  props: {
    firstName: String,
    lastName: String,
    sex: String,
    vintage: String,
    oid: String,
    size: {
      default: '80',
      type: String
    },
    random: {
      default: false,
      type: Boolean
    },
    showContacts: Boolean
  },
  data () {
    return {
      src: undefined,
      baseURL: undefined,
      show: this.$store.getters.get_show_avatars
    }
  },

  mounted () {
    this.baseURL = config.api_get_avatar
    this.initialize()
  },

  methods: {
    async initialize () {
      if (this.random) {
        const url = `https://randomuser.me/api/?gender=${this.sex === 'm' ? 'male' : 'female'}`
        try {
          const res = await this.$axios.get(url)
          this.src = res.data.results[0].picture.large
        } catch (err) {
          console.error(err)
        }
      } else {
        const url = `${this.baseURL}/${this.vintage}/${this.oid}`
        const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
        try {
          const res = await this.$axios.get(url, axiosConfig)
          this.src = res.data.avatar
        } catch (err) {
          console.error(err)
        }
        // this.src = await this.$axios.get(url, axiosConfig)
        //   .then(async (res) => {
        //     return await res.data.avatar
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //   })
      }
    }
  }
}
</script>
