const { getTagNotenschluss } = require('./dateHandler')

function createHeaders (courseId, item, namenKopfnoten, is10OS, hasExternalGrades) {
  const forbiddenTopics = ['Kopfnoten', 'Jahresnote', 'Prüfung', 'mündliche Prüfung', 'zusätzliche mündliche Prüfung', 'Zeugnisnote']
  const headers = {}
  const baseHeader = [
    { text: '', value: 'bild', filterable: false, sortable: false },
    { text: 'FachThema', value: 'fachThema', filterable: true, sortable: false, align: ' d-none d-xs-table-cell' },
    { text: 'Name', value: 'name', filterable: false },
    { text: 'Vorname', value: 'vorname', filterable: false }
  ]

  for (const head of [`${courseId}_HJ`, `${courseId}_EJ`, `${courseId}_HJ, Kopfnoten`, `${courseId}_EJ, Kopfnoten`]) {
    headers[head] = [...baseHeader]
  }

  if (is10OS) {
    headers[`${courseId}_EJ, Prüfungen`] = [...baseHeader]
    headers[`${courseId}_EJ, Zeugnisnote`] = [...baseHeader]
  }

  for (const anItem of item.noten) {
    if ((!(namenKopfnoten.includes(anItem.topic) || anItem.topic === 'Kopfnoten' || anItem.topic === 'Zeugnisnote') && new Date(anItem.testedAt) < getTagNotenschluss('HJ'))) {
      headers[`${courseId}_HJ`].push({
        text: anItem.thema,
        value: anItem.fachThema,
        filterable: false,
        testedAt: anItem.testedAt,
        backAt: anItem.backAt
      })
    }
    if (
      !namenKopfnoten.includes(anItem.topic) &&
      !forbiddenTopics.includes(anItem.topic)
    ) {
      headers[`${courseId}_EJ`].push({
        text: anItem.thema,
        value: anItem.fachThema,
        filterable: false,
        testedAt: anItem.testedAt,
        backAt: anItem.backAt
      })
    }
    // if (['Jahresnote', 'Prüfung', 'mündliche Prüfung', 'zusätzliche mündliche Prüfung', 'Zeugnisnote'].includes(anItem.topic)) {
    //   headers[`${courseId}_EJ_Jahresnoten`].push({
    //     text: anItem.thema,
    //     value: anItem.fachThema,
    //     filterable: false,
    //     testedAt: anItem.testedAt,
    //     backAt: anItem.backAt
    //   })
    // }

    const t = anItem.thema.split(',')[0]
    if (anItem.thema === 'HJ, Zeugnisnote') {
      headers[`${courseId}_HJ`].push({
        text: t,
        value: 'median_hj',
        filterable: false
      })
      // headers[`${courseId}_HJ`].push({
      //   text: anItem.thema,
      //   value: 'note',
      //   filterable: false
      // })
    } else if ((!is10OS && anItem.thema === 'EJ, Zeugnisnote') || (is10OS && anItem.thema === 'EJ, Jahresnote')) {
      if (hasExternalGrades.A) {
        hasExternalGrades.A = false
        headers[`${courseId}_EJ`].push({
          text: 'A, extern',
          value: 'externA',
          filterable: false
        })
      }
      if (hasExternalGrades.B) {
        hasExternalGrades.B = false
        headers[`${courseId}_EJ`].push({
          text: 'B, extern',
          value: 'externB',
          filterable: false
        })
      }
      headers[`${courseId}_EJ`].push({
        text: t,
        value: 'median_ej',
        filterable: false
      })
      // headers[`${courseId}_EJ`].push({
      //   text: anItem.thema,
      //   value: 'note',
      //   filterable: false
      // })
    }

    if (namenKopfnoten.includes(anItem.topic) && ['HJ', 'EJ'].includes(anItem.gradeType)) {
      const head = `${courseId}_${anItem.gradeType}, Kopfnoten`
      // if (anItem.topic === 'Mitarbeit') {
      //   const fleiszHeader = headers[head].pop()
      //   headers[head].push({
      //     text: anItem.thema,
      //     value: anItem.fachThema,
      //     filterable: false
      //   })
      //   headers[head].push(fleiszHeader)
      // } else {
      headers[head].push({
        text: anItem.thema,
        value: anItem.fachThema,
        filterable: false
      })
      // }
    }

    if (anItem.fachThema === `${courseId}_HJ, Ordnung`) {
      const head = `${courseId}_HJ, Kopfnoten`
      headers[head] = headers[head].concat([
        { text: 'Eingabe Betragen', value: 'betragen', filterable: false, sortable: false },
        { text: 'Eingabe Mitarbeit', value: 'mitarbeit', filterable: false, sortable: false },
        { text: 'Eingabe Fleiß', value: 'fleisz', filterable: false, sortable: false },
        { text: 'Eingabe Ordnung', value: 'ordnung', filterable: false, sortable: false }
      ])
    }

    if (anItem.fachThema === `${courseId}_EJ, Ordnung`) {
      const head = `${courseId}_EJ, Kopfnoten`
      headers[head] = headers[head].concat([
        { text: 'Eingabe Betragen', value: 'betragen', filterable: false, sortable: false },
        { text: 'Eingabe Mitarbeit', value: 'mitarbeit', filterable: false, sortable: false },
        { text: 'Eingabe Fleiß', value: 'fleisz', filterable: false, sortable: false },
        { text: 'Eingabe Ordnung', value: 'ordnung', filterable: false, sortable: false }
      ])
    }

    const exams = ['Prüfung', 'mündliche Prüfung', 'zusätzliche mündliche Prüfung']
    const key = anItem.topic
    if (exams.includes(key)) {
      headers[`${courseId}_EJ, Prüfungen`].push({
        text: anItem.thema,
        value: anItem.fachThema,
        filterable: false
      })
      headers[`${courseId}_EJ, Zeugnisnote`].push({
        text: anItem.thema,
        value: `${anItem.fachThema}_ro`,
        filterable: false
      })
    }
  }

  if (is10OS) {
    const foundExamAt = headers[`${courseId}_EJ, Prüfungen`].findIndex(e => e.text === 'EJ, Prüfung')
    const foundOralAt = headers[`${courseId}_EJ, Prüfungen`].findIndex(e => e.text === 'EJ, mündliche Prüfung')
    const foundExtraAt = headers[`${courseId}_EJ, Prüfungen`].findIndex(e => e.text === 'EJ, zusätzliche mündliche Prüfung')
    const head = `${courseId}_EJ, Zeugnisnote`
    const headerObj = { text: 'EJ, Jahresnote', value: `${courseId}_EJ, Jahresnote_ro`, filterable: false }
    if (foundExamAt > -1) headers[head].splice(foundExamAt, 0, headerObj)
    else if (foundOralAt > -1) headers[head].splice(foundOralAt, 0, headerObj)
    else if (foundExtraAt > -1) headers[head].splice(foundExtraAt, 0, headerObj)
    headers[head] = headers[head].concat([
      { text: 'Z', value: 'median_z', filterable: false, sortable: false },
      { text: 'Noteneingabe', value: 'noteneingabe', filterable: false, sortable: false }
    ])
  }
  for (const head of [`${courseId}_HJ`, `${courseId}_EJ`]) {
    headers[head].push({ text: 'Noteneingabe', value: 'noteneingabe', filterable: false, sortable: false })
  }

  return headers
}

function getColor (level, grade, noGradeText) {
  if (level >= 11) {
    if (grade === noGradeText) return 'orange'
    if (grade < 3.5) return 'red'
    else if (grade < 6.5) return 'orange'
    else if (grade < 9.5) return 'yellow'
    else return '#72ab12'
  } else {
    if (grade === noGradeText) return 'orange'
    if (grade > 4.5) return 'red'
    else if (grade > 3.5) return 'orange'
    else if (grade > 2.5) return 'yellow'
    else return '#72ab12'
  }
}

function getDark (level, grade) {
  if (level >= 11) {
    if (grade < 3.5) return true
    else if (grade < 6.5) return true
    else if (grade < 9.5) return false
    else return false
  } else {
    if (grade > 4.5) return true
    else if (grade > 3.5) return true
    else if (grade > 2.5) return false
    else return false
  }
}

function getTextColor (level, grade) {
  if (level >= 11) {
    if (grade < 3.5) return 'white'
    else if (grade < 6.5) return 'purple'
    else if (grade < 9.5) return 'black'
    else return 'black'
  } else {
    if (grade > 4.5) return 'white'
    else if (grade > 3.5) return 'purple'
    else if (grade > 2.5) return 'black'
    else return 'black'
  }
}

function getTooLong (testedAt, backAt) {
  const dayDifference = (new Date(backAt) - new Date(testedAt)) / (24 * 3600000)
  if (dayDifference > 28) return 'red'
  else if (dayDifference > 14) return 'orange'
  else return ''
}

function shortenTopic (topic) {
  return topic.length > 10 ? `${topic.substr(0, 10)} ...` : topic
}

// function getToday () {
//   return new Date()
// }

// function getHJStart () {
//   return new Date('2022-01-24')
// }

// function getTagErster (which) {
//   if (which === 'HJ') return new Date('2022-09-06')
//   else if (which === 'EJ') return new Date('2022-01-31')
// }

// function getTagZeugniseingabe (which) {
//   if (which === 'HJ') return new Date('2022-01-24')
//   else if (which === 'EJ') return new Date('2022-06-13')
// }

// function getTagNotenschluss (which) {
//   if (which === 'HJ') return new Date('2022-01-31')
//   else if (which === 'EJ') return new Date('2022-05-27T16:00')
// }

// function getTagZeugnisdruck (which) {
//   if (which === 'HJ') return new Date('2022-02-10')
//   else if (which === 'EJ') return new Date('2022-07-14')
// }

module.exports = {
  createHeaders,
  getColor,
  getDark,
  // getHJStart,
  // getTagErster,
  // getTagNotenschluss,
  // getTagZeugniseingabe,
  // getTagZeugnisdruck,
  // getToday,
  getTextColor,
  getTooLong,
  shortenTopic
}
