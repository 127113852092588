<template>
  <div v-if="whichHead === 'betragen'">
    „sehr gut“ (1), wenn vorbildlich ausgeprägt ist<br>
    „gut“ (2), wenn stark ausgeprägt ist<br>
    „befriedigend“ (3), wenn durchschnittlich ausgeprägt ist<br>
    „ausreichend“ (4), wenn schwach ausgeprägt ist<br>
    „mangelhaft“ (5), wenn unzureichend ausgeprägt ist.<br><br>
    Betragen umfasst Aufmerksamkeit,<br>
    Hilfsbereitschaft, Zivilcourage und<br>
    angemessenen Umgang mit Konflikten,<br>
    Rücksichtnahme, Toleranz und Gemeinsinn<br>
    sowie Selbsteinschätzung.<br>
    Gesundheitliche Beeinträchtigungen des<br>
    Schülers sind zu berücksichtigen!<br>
    <a href="https://www.revosax.sachsen.de/vorschrift/12053-Schulordnung-Ober-und-Abendoberschulen#p23">Internetquelle I</a><br>
    <a href="https://www.revosax.sachsen.de/vorschrift/12517-Schulordnung-Gymnasien-Abiturpruefung#p23">Internetquelle II</a>
  </div>
  <div v-else-if="whichHead === 'fleisz'">
    „sehr gut“ (1), wenn vorbildlich ausgeprägt ist<br>
    „gut“ (2), wenn stark ausgeprägt ist<br>
    „befriedigend“ (3), wenn durchschnittlich ausgeprägt ist<br>
    „ausreichend“ (4), wenn schwach ausgeprägt ist<br>
    „mangelhaft“ (5), wenn unzureichend ausgeprägt ist.<br><br>
    Fleiß umfasst Lernbereitschaft, Zielstrebigkeit,<br>
    Ausdauer und Regelmäßigkeit beim Erfüllen von Aufgaben.<br>
    Gesundheitliche Beeinträchtigungen des Schülers sind<br>
    zu berücksichtigen!<br>
    <a href="https://www.revosax.sachsen.de/vorschrift/12053-Schulordnung-Ober-und-Abendoberschulen#p23">Internetquelle I</a><br>
    <a href="https://www.revosax.sachsen.de/vorschrift/12517-Schulordnung-Gymnasien-Abiturpruefung#p23">Internetquelle II</a>
  </div>
  <div v-else-if="whichHead === 'mitarbeit'">
    „sehr gut“ (1), wenn vorbildlich ausgeprägt ist<br>
    „gut“ (2), wenn stark ausgeprägt ist<br>
    „befriedigend“ (3), wenn durchschnittlich ausgeprägt ist<br>
    „ausreichend“ (4), wenn schwach ausgeprägt ist<br>
    „mangelhaft“ (5), wenn unzureichend ausgeprägt ist.<br><br>
    Mitarbeit umfasst Initiative, Kooperationsbereitschaft<br>
    und Teamfähigkeit, Beteiligung am Unterricht, Selbstständigkeit,<br>
    Kreativität sowie Verantwortungsbereitschaft.<br>
    Gesundheitliche Beeinträchtigungen des Schülers sind<br>
    zu berücksichtigen!<br>
    <a href="https://www.revosax.sachsen.de/vorschrift/12053-Schulordnung-Ober-und-Abendoberschulen#p23">Internetquelle I</a><br>
    <a href="https://www.revosax.sachsen.de/vorschrift/12517-Schulordnung-Gymnasien-Abiturpruefung#p23">Internetquelle II</a>
  </div>
  <div v-else-if="whichHead === 'ordnung'">
    „sehr gut“ (1), wenn vorbildlich ausgeprägt ist<br>
    „gut“ (2), wenn stark ausgeprägt ist<br>
    „befriedigend“ (3), wenn durchschnittlich ausgeprägt ist<br>
    „ausreichend“ (4), wenn schwach ausgeprägt ist<br>
    „mangelhaft“ (5), wenn unzureichend ausgeprägt ist.<br><br>
    Ordnung umfasst Sorgfalt, Pünktlichkeit, Zuverlässigkeit,<br>
    Einhalten von Regeln und Absprachen sowie Bereithalten<br>
    notwendiger Unterrichtsmaterialien.<br>
    Gesundheitliche Beeinträchtigungen des Schülers sind<br>
    zu berücksichtigen! <br>
    <a href="https://www.revosax.sachsen.de/vorschrift/12053-Schulordnung-Ober-und-Abendoberschulen#p23">Internetquelle I</a><br>
    <a href="https://www.revosax.sachsen.de/vorschrift/12517-Schulordnung-Gymnasien-Abiturpruefung#p23">Internetquelle II</a>
  </div>
</template>

<script>
export default {
  name: 'HeadGradeInfo',

  props: {
    whichHead: String
  }
}
</script>
