<template>
  <v-container v-if="$keycloak !== undefined && $keycloak.authenticated">
    <v-row>
      <v-col class="mt-4 mb-4">
        <v-card >
          <v-card-title class="d-flex justify-end">
            Fachnoten eintragen
            <v-spacer />
            <v-select
              v-if="!isLoading && schools.length > 1"
              v-model="select0"
              hide-details
              :items="schools"
              class="v-select mr-3"
              single-line
              ref="select"
              tabindex="1"
              @change="setSelected"
            />
            <v-select
              v-if="!isLoading && schools.length === 1"
              v-model="select0"
              hide-details
              :items="schools"
              class="v-select mr-3"
              single-line
              ref="select"
              append-icon=""
              tabindex="-1"
            />

            <v-select
              v-if="!isLoading"
              v-model="select"
              hide-details
              :items="filteredItems"
              item-text="fachKlasse"
              item-value="id"
              class="v-select mr-3"
              return-object
              single-line
              ref="select2"
              tabindex="2"
              @change="setSelected2"
            />

            <v-tooltip bottom v-if="!isLoading">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  class="mt-3"
                  color="primary"
                  @click="newNote.show = true"
                  :disabled="isCreateMetaDisabled('EJ') || isCreating">
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              definiere eine neue Note
            </v-tooltip>
          </v-card-title>
          <v-card-subtitle class="d-flex justify-end" v-if="select2.topic !== undefined">
            <GradeWeightSlider
              v-if="isAB() || isFOS()"
              v-model="select"
            />
            <v-spacer />
            <v-select
              v-if ="select.noten !== undefined"
              v-model="select2"
              hide-details
              :items="select.noten.filter(e => !forbiddenTopics.includes(e.topic))"
              :item-text="isBB()? 'topic' : 'thema'"
              item-value="fachThema"
              label="Note auswählen"
              class="v-select mr-3"
              no-data-text="keine Noten definiert"
              return-object
              single-line
              ref="select3"
              tabindex="3"
              @change="setCurrentHeader"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  class="mt-3"
                  color="primary"
                  :disabled="isCreateMetaDisabled('EJ') || isCreating || checkSelect2()"
                  @click="setChangeGrade">
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>
              aktuell ausgewählte Notendefinition ändern
            </v-tooltip>
          </v-card-subtitle>
          <div
            class="d-flex justify-center ml-1 mr-1 mt-1 mb-5"
            v-if="isLoading && items.length > 0"
          >
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="100* (itemsLoaded / items.length)"
              color="primary"
            >
              {{ (100 * (itemsLoaded / items.length)).toFixed() }}%
            </v-progress-circular>
          </div>
          <v-skeleton-loader
            v-if="isLoading && items.length > 0 || isCreating"
            type="table"
          ></v-skeleton-loader>

          <div class="d-flex align-center justify-end ml-1 mr-1">

            <GradeMetaDialog
              v-model="newNote"
              type="new"
              :select0="select0"
              :select="select"
              :select2="select2"
              @created="createNewIsReady"
            />

            <GradeMetaDialog
              v-model="changeNote"
              type="change"
              :select0="select0"
              :select="select"
              :select2="select2"
              @changed="changeMetaIsReady"
            />

          </div>
          <v-data-table
            v-if="!isLoading && !isCreating && select.noten !== undefined"
            name="nw_data_table"
            :height="windowHeight-310"
            fixed-header

            :items-per-page="isPhone ? 1 : -1"
            :footer-props="{
              itemsPerPageOptions: isPhone ? [1, -1] : [3, 5, -1],
              itemsPerPageText: 'Schüler pro Seite:',
              itemsperpagealltext: 'Jeder'
            }"
            :headers="currentHeader"
            :items="grades"
            item-key="key"
            :sort-by="['name', 'vorname']"
            multi-sort
            :search="select2.fachThema"
            :custom-filter="customDataTableFilter"
            :loading="isLoading"
            loading-text="Ich lade die Noten."
            no-data-text="Es wurden noch keine Noten eingetragen!"
            no-results="Es wurden keine Noten gefunden!"
          >
            <!-- <template v-if="select2.topic !== 'Zeugnisnote' || select2.topic !== 'Kopfnoten'" v-slot:[`header.noteneingabe`]> -->
            <template v-if="select2.topic !== 'Zeugnisnote' && select2.topic !== 'Kopfnoten'" v-slot:[`header.noteneingabe`]>
              <MetaGradeHeader
                :thema="select2.thema"
                :type="select2.gradeType"
                :topic="select2.topic"
                :teacher="select2.teacher"
                :testedAt="select2.testedAt"
                :backAt="select2.backAt"
                :classOverview="getTableRef({})"
                :classMedian="getClassMedian({ thema: select2.fachThema }).toFixed(2)"
                :classMedianColor="getColor(select.level, getClassMedian({ thema: select2.fachThema }))"
                :isSek2="isSek2()"
                :isBB="isBB()"
              />
            </template>

            <template v-slot:[`header.notiz`] = "{ header }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <span>
                      {{ header.text }}
                    </span>
                  </div>
                </template>
                <p style="width: 350px; hyphens: auto; text-align: justify; text-align-last: center;">
                  Das Kontrollkästchen aktiviert die Eingabe für das Notizfeld.
                  Verfasse deine Notiz und bestätige deine Eingabe mit <kbd>&#9166;</kbd>
                  bzw. <kbd>Enter</kbd> oder indem du das Feld mit <kbd>&#8677;</kbd> bzw.
                  <kbd>Tab &#8633;</kbd> verlässt oder du kannst auch mit der Maus auf ein
                  anderes Element dieser Seite klicken. Deine Notiz wurde
                  erfolgreich hochgeladen, wenn darunter <span style="color: #72ab12;">geladen</span> steht.
                </p>

              </v-tooltip>
            </template>

            <template v-for="head in allHeads" v-slot:[`header.${head}`] = "{ header }">
              <HeadGradeHeader
                :key="head"
                :header="header"
                componetType="grade"
                :classOverview="getTableRef({ sub: header.value })"
                :classMedian="getClassMedian({ sub: header.value}).toFixed(2)"
                :classMedianColor="getColor(0, getClassMedian({ sub: header.value}))"
              />
            </template>

            <template v-for="head2 in ['betragen', 'mitarbeit', 'fleisz', 'ordnung']" v-slot:[`header.${head2}`] = "{ header }">
              <HeadGradeHeader
                :key="head2"
                :header="header"
                componetType="info"
              />
            </template>

            <template v-for="meta in allMetas" v-slot:[`header.${meta}`] = "{ header }">
              <MetaGradeHeader
                :key="meta"
                :header="header"
                :firstName="select.noten.find(e => e.fachThema === meta).teacher.split(' ')[0]"
                :lastName="select.noten.find(e => e.fachThema === meta).teacher.split(' ')[1]"
                :testedAt="select.noten.find(e => e.fachThema === meta).testedAt"
                :backAt="select.noten.find(e => e.fachThema === meta).backAt"
                :classOverview="getTableRef({ thema: meta })"
                :classMedian="getClassMedian({ thema: meta }).toFixed(2)"
                :classMedianColor="getColor(select.level, getClassMedian({ thema: meta }))"
                :isSek2="isSek2()"
                :isBB="isBB()"
              />
            </template>

            <!-- <template v-for="exam in allExams" v-slot:[`header.${exam}`] = "{ header }">
              {{ header.text }} - {{ header.value }} &empty;
            </template> -->

            <template v-slot:[`header.median_hj`] = "{ header }">
              {{ header.text }}
              &empty;
            </template>

            <template v-slot:[`header.median_ej`] = "{ header }">
              {{ header.text }}
              &empty;
            </template>

            <!-- <template v-slot:[`header.median_z`] = "{ header }">
              {{ header.text }} -->
            <template v-slot:[`header.median_z`]>
              Zeugnis &empty;
            </template>

            <!-- <template v-slot:[`header.noteneingabe`] = "{ header }">
              <v-tooltip bottom v-if="select2.gradeType === 'HJ'">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    {{ header.text }}
                  </div>
                </template>
                Eingabe Halbjahresnoten nur vom<br><strong>{{ getTagZeugniseingabe('HJ').toLocaleString().split(',')[0] }}</strong> bis <strong>{{ getTagZeugnisdruck('HJ').toLocaleString().split(',')[0] }}</strong> möglich!
              </v-tooltip>
              <v-tooltip bottom v-else-if="select2.gradeType === 'EJ'">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    {{ header.text }}
                  </div>
                </template>
                Eingabe Endjahresnoten nur vom<br><strong>{{ getTagZeugniseingabe('EJ').toLocaleString().split(',')[0] }}</strong> bis <strong>{{ getTagZeugnisdruck('EJ').toLocaleString().split(',')[0] }}</strong> möglich!
              </v-tooltip>
              <div v-else>{{ header.text }}</div>
            </template> -->

            <template v-slot:[`item.bild`] = "{ item }">
              <ProtectedAvatar
                :key="item.id"
                :firstName="item.vorname"
                :lastName="item.name"
                :sex="item.sex"
                :vintage="item.vintage"
                :oid="item.oid"
                class="mt-1 mb-1"
                :random="isPlayground"
                showContacts
              />
            </template>

            <template v-for="head in allHeads" v-slot:[`item.${head}`] = "{ item }">
              {{ getHeadItem(item, head) }}
            </template>

            <template v-for="meta in allMetas" v-slot:[`item.${meta}`] = "{ item }">
              {{ getItem(item, meta) }}
            </template>

            <!-- <template v-for="gradeType in ['A', 'B']" v-slot:[`item.extern${gradeType}`] = "{ item }">
              <span v-if="item.myGrades.extern !== undefined && item.myGrades.extern[gradeType] !== undefined" :key="extern[gradeType]">
                {{ getExternalGradesString(item.myGrades.extern[gradeType]) }}
              </span>
            </template> -->

            <template v-slot:[`item.externA`] = "{ item }">
              <span v-if="item.myGrades.extern !== undefined && item.myGrades.extern[`${select.id}_A`] !== undefined" >
                {{ getExternalGradesString(item.myGrades.extern[`${select.id}_A`]) }}
              </span>
            </template>

            <template v-slot:[`item.externB`] = "{ item }">
              <span v-if="item.myGrades.extern !== undefined && item.myGrades.extern[`${select.id}_B`] !== undefined" >
                {{ getExternalGradesString(item.myGrades.extern[`${select.id}_B`]) }}
              </span>
            </template>

            <template v-slot:[`item.median_hj`] = "{ item }">
              <v-chip
                v-if="calculateMedian(item.myGrades, 'HJ')"
                :color="getColor(select.level, calculateMedian(item.myGrades, 'HJ'))"
                :text-color="getTextColor(select.level, calculateMedian(item.myGrades, 'HJ'))"
              >
                {{ calculateMedian(item.myGrades, 'HJ') }}
              </v-chip>
            </template>

            <template v-slot:[`item.median_ej`] = "{ item }">
              <v-chip
                v-if="calculateMedian(item.myGrades, 'EJ')"
                :color="getColor(select.level, calculateMedian(item.myGrades, 'EJ'))"
                :text-color="getTextColor(select.level, calculateMedian(item.myGrades, 'EJ'))"
              >
                {{ calculateMedian(item.myGrades, 'EJ') }}
              </v-chip>
            </template>

            <!-- TODO  -->
            <template v-slot:[`item.median_z`] = "{ item }">
              <v-chip
                v-if="calculateMedian(item.myGrades, 'Z')"
                :color="getColor(select.level, calculateMedian(item.myGrades, 'Z'))"
                :text-color="getTextColor(select.level, calculateMedian(item.myGrades, 'Z'))"
              >
                {{ calculateMedian(item.myGrades, 'Z') }}
              </v-chip>
            </template>

            <!-- <template v-slot:[`item.note`] = "{ item }">
              {{ item.myGrades[select2.fachThema].note }}
            </template> -->

            <template v-slot:[`item.noteneingabe`] = "{ item }">
              <v-select
                v-if="select2.gradeType === 'HJ'"
                v-model="item.myGrades[select2.fachThema].noteneingabe"
                :key="item.myGrades[select2.fachThema].key"
                :error-messages="item.myGrades[select2.fachThema].error"
                :success-messages="item.myGrades[select2.fachThema].success"
                :value="item.myGrades[select2.fachThema].note"
                :placeholder="String(item.myGrades[select2.fachThema].note)"
                :items="isSek2() ? possibleSek2Grades : possibleReportGrades"
                :disabled="select2.isWatcher || isChangeGradeDisabled({ term: 'HJ' }) || noABES(item) || noABES()"
                rounded
                outlined
                ref="select4"
                @change="(value) => { setReportGrade({
                  key: item.myGrades[select2.fachThema].key,
                  odataNoteOid: item.myGrades[select2.fachThema].odataNoteOid,
                  value,
                  median: calculateMedian(item.myGrades, select2.gradeType)
                }) }"
                class="v-select-grades mb-1"
              >
              <!-- || uploadingABES(item) || noABES(item) -->
                <template v-slot:append v-if="!doneNothing(item) || noABES()">
                  <v-icon
                    v-if="!noABES(item) && uploadingABES(item)"
                    style="color: orange;"
                  >mdi-database-arrow-up</v-icon>
                  <v-icon
                    v-if="noABES(item) || noABES()"
                    style="color: red;"
                  >mdi-database-off</v-icon>
                  <v-icon
                    v-if="successABES(item)"
                    style="color: #72ab12;"
                  >mdi-database-check</v-icon>

                  <!-- <span :style="item.myGrades[select2.fachThema].abesUploading ? 'color: error;' : 'color: #72ab12;'" v-if="select2.gradeType === 'HJ'">ABES</span> -->
                </template>
              </v-select>
              <!-- delete after test start-->
              <!-- <span v-if="select2.gradeType === 'HJ'">
                {{ item.myGrades[select2.fachThema] }}
              </span> -->
              <!-- delete after test end-->
              <v-select
                v-else-if="select2.gradeType === 'EJ'"
                v-model="item.myGrades[select2.fachThema].noteneingabe"
                :key="item.myGrades[select2.fachThema].key"
                :error-messages="item.myGrades[select2.fachThema].error"
                :success-messages="item.myGrades[select2.fachThema].success"
                :value="item.myGrades[select2.fachThema].note"
                :placeholder="String(item.myGrades[select2.fachThema].note)"
                :items="isSek2() ? possibleSek2Grades : possibleReportGrades"
                :disabled="select2.isWatcher || isChangeGradeDisabled({ term: 'EJ' })"
                rounded
                outlined
                @change="(value) => { setReportGrade({
                  key: item.myGrades[select2.fachThema].key,
                  odataNoteOid: item.myGrades[select2.fachThema].odataNoteOid,
                  value,
                  median: calculateMedian(item.myGrades, select2.gradeType)
                }) }"
                class="v-select-grades mb-1"
              />
              <v-select
                v-else-if="select2.gradeType !== 'HJ' || select2.gradeType !== 'EJ'"
                v-model="item.myGrades[select2.fachThema].noteneingabe"
                :key="item.myGrades[select2.fachThema].key"
                :error-messages="item.myGrades[select2.fachThema].error"
                :success-messages="item.myGrades[select2.fachThema].success"
                :value="item.myGrades[select2.fachThema].note"
                :placeholder="String(item.myGrades[select2.fachThema].note)"
                :items="isSek2()  ? possibleSek2Grades : possibleGrades"
                :disabled="select2.isWatcher || isChangeGradeDisabled({ dateString: item.myGrades[select2.fachThema].erzeugtAm, term: 'HJ' }) || isChangeGradeDisabled({ dateString: item.myGrades[select2.fachThema].erzeugtAm, term: 'EJ' })"
                rounded
                outlined
                @change="(value) => setGrade(item.myGrades[select2.fachThema].key, value, undefined, undefined)"
                class="v-select-grades mb-1"
              />
            </template>

            <template v-slot:[`item.notiz`] = "{ item }">
              <v-text-field
                :value="getNote(item, select2.fachThema )"
                filled
                rounded
                :key="item.myGrades[select2.fachThema].key"
                :error-messages="item.myGrades[select2.fachThema].notizError"
                :success-messages="item.myGrades[select2.fachThema].notizSuccess"
                :disabled="select2.isWatcher || isChangeGradeDisabled({ dateString: item.myGrades[select2.fachThema].erzeugtAm, term: 'HJ' }) || isChangeGradeDisabled({ dateString: item.myGrades[select2.fachThema].erzeugtAm, term: 'EJ' }) || !item.myGrades[select2.fachThema].isNoteEnabled"
                @change="(value) => {
                  // item.myGrades[select2.fachThema].isNoteEnabled = false;
                  setNote(item.myGrades[select2.fachThema].key, value, false)
                }"
                class="v-text-field-note"
              >
                <template v-slot:prepend>
                  <v-checkbox
                    class="mt-0 pt-0"
                    tabindex="-1"
                    v-model="item.myGrades[select2.fachThema].isNoteEnabled"
                  />
                </template>
              </v-text-field>
            </template>

            <template v-for="head in objKopfnoten" v-slot:[`item.${head.slot}`] = "{ item }">
              <v-select
                v-if="select2.gradeType === 'HJ'"
                v-model="item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`][head.slot]"
                :key="item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].key"
                :error-messages="item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].error"
                :success-messages="item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].success"
                :value="item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].note"
                :placeholder="String(item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].note)"
                :items="possibleHeadGrades"
                :disabled="isChangeGradeDisabled({ term: 'HJ' })"
                rounded
                outlined
                @change="(value) => setGrade(item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].key, value, head.name, undefined)"
                class="v-select-grades mb-1"
              />
              <v-select
                v-if="select2.gradeType === 'EJ'"
                v-model="item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`][head.slot]"
                :key="item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].key"
                :error-messages="item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].error"
                :success-messages="item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].success"
                :value="item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].note"
                :placeholder="String(item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].note)"
                :items="possibleHeadGrades"
                :disabled="isChangeGradeDisabled({ term: 'EJ' })"
                rounded
                outlined
                @change="(value) => setGrade(item.myGrades[select2.fachThema][`${select2.fachThema.split(',')[0]}, ${head.name}`].key, value, head.name, undefined)"
                class="v-select-grades mb-1"
              />
            </template>

            <template v-for="exam in allExams" v-slot:[`item.${exam}`] = "{ item }">
              <!-- TODO :disabled="getToday() < getTagZeugniseingabe('EJ') || getToday() >= getTagZeugnisdruck('EJ')" -->
              <v-select
                v-if="item.myGrades[exam].note !== null"
                :key="item.myGrades[exam].key"
                :error-messages="item.myGrades[exam].error"
                :success-messages="item.myGrades[exam].success"
                :value="item.myGrades[exam].note"
                :items="isSek2()  ? possibleSek2Grades : possibleGrades"

                rounded
                outlined
                @change="(value) => setGrade(item.myGrades[exam].key, value, undefined, undefined)"
                class="v-select-grades mb-1"
              />
            </template>

            <template v-slot:[`item.${getFachThemaRO()}`] = "{ item }">
              {{ item.myGrades[getFachThemaRO().substr(0, getFachThemaRO().length - 3)].note }}
            </template>

            <template v-for="exam2 in allExams.map(e => e.concat('_ro'))" v-slot:[`item.${exam2}`] = "{ item }">
              {{ item.myGrades[exam2.substr(0, exam2.length - 3)].note }}
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProtectedAvatar from '../components/ProtectedAvatar'
import HeadGradeHeader from '../components/HeadGradeHeader'
import MetaGradeHeader from '../components/MetaGradeHeader'
import GradeMetaDialog from '../components/GradeMetaDialog'
import GradeWeightSlider from '../components/GradeWeightSlider'

const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)
const { createHeaders, getColor, getTextColor, getTooLong, shortenTopic } = require('../../handler/tableHandler')
const { getToday, getHJStart, getTagErster, getTagNotenschluss, getTagZeugniseingabe, getTagZeugnisdruck, isCreateMetaDisabled, isChangeGradeDisabled, isTimeForReportGrades } = require('../../handler/dateHandler')

export default {
  components: {
    ProtectedAvatar,
    HeadGradeHeader,
    MetaGradeHeader,
    GradeMetaDialog,
    GradeWeightSlider
  },
  data () {
    return {
      isPlayground: process.env.VUE_APP_IS_PLAYGROUND === 'true',
      isCreating: false,

      newNote: { isCreating: false, error: {} },
      changeNote: { isChanging: false, error: {} },
      showChart: false,

      isLoading: true,
      isPhone: window.innerWidth <= 480 || window.innerHeight <= 480,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      select0: '',
      select: { isLocked: true },
      select2: {},
      schools: [],
      itemsLoaded: 0,
      items: [],
      filteredItems: [],
      refactoredItems: [],
      savedSelect: undefined,
      currentHeader: [],
      headers: {
        default: [
          { text: '', value: 'bild', filterable: false, sortable: false },
          { text: 'FachThema', value: 'fachThema', filterable: true, sortable: false, align: ' d-none d-xs-table-cell' },
          { text: 'Name', value: 'name', filterable: false },
          { text: 'Vorname', value: 'vorname', filterable: false },
          // { text: 'Note', value: 'note', filterable: false },
          { text: 'Noteneingabe', value: 'noteneingabe', filterable: false, sortable: false },
          { text: 'Notiz', value: 'notiz', filterable: false, sortable: false }
        ]
      },
      possibleGrades: [1, 2, 3, 4, 5, 6, ''],
      // possibleReportGrades: [1, 2, 3, 4, 5, 6, 'nicht erteilt', 'teilgenommen', 'befreit', 'keine Benotung', ''],
      possibleReportGrades: [1, 2, 3, 4, 5, 6, 'nicht erteilt', 'teilgenommen', 'befreit', 'keine Benotung'],
      notizMap: { 'nicht erteilt': 7, 'keine Benotung': 8, befreit: 9, teilgenommen: 10, ntg: 11 },
      possibleSek2Grades: [15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, ''],
      possibleHeadGrades: [1, 2, 3, 4, 5, ''],
      grades: [],
      allMetas: [],
      allHeads: [],
      allExams: [],
      namenKopfnoten: ['Betragen', 'Mitarbeit', 'Fleiß', 'Ordnung'],
      namenExams: ['Prüfung', 'mündliche Prüfung', 'zusätzliche mündliche Prüfung'],
      // forbiddenTopics: ['Betragen', 'Mitarbeit', 'Fleiß', 'Ordnung', 'mündliche Prüfung', 'zusätzliche mündliche Prüfung'],
      forbiddenTopics: ['Betragen', 'Mitarbeit', 'Fleiß', 'Ordnung'],
      objKopfnoten: [
        { slot: 'betragen', name: 'Betragen' },
        { slot: 'mitarbeit', name: 'Mitarbeit' },
        { slot: 'fleisz', name: 'Fleiß' },
        { slot: 'ordnung', name: 'Ordnung' }
      ],
      objExams: [
        { slot: 'pruefung', name: 'Prüfung' },
        { slot: 'muendlich', name: 'mündliche Prüfung' },
        { slot: 'zusatz', name: 'zusätzliche mündliche Prüfung' }
      ],
      noGradeText: 'bisher keine Note eingetragen!',
      whichHelper: ''
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.initialize()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    isAB (school) {
      const schoolType = school !== undefined
        ? school.split(' ')[0]
        : this.select0.split(' ')[0]
      return schoolType === 'GS' || schoolType === 'OS' || schoolType === 'GY' || schoolType === 'BGY'
    },
    isFOS () {
      return this.select0.split(' ')[0] === 'FOS'
    },
    isBB () {
      const schoolType = this.select0.split(' ')[0]
      return schoolType === 'BFS' || schoolType === 'FS' || schoolType === 'LOG'
    },
    getExternalGradesString (array) {
      let retString = ''
      for (const e of array) {
        retString = `${retString}${e.value}; `
      }
      return retString.substr(0, retString.length - 2)
    },
    getFachThemaRO () {
      return this.select2.fachThema.split('_')[0].concat('_EJ, Jahresnote_ro')
    },
    is10OS () {
      return this.select.level === '10' && this.select.school.substring(0, 2) === 'OS'
    },
    applyCustomStyles () {
      this.$nextTick(() => {
        const customStyles = { '.v-select__selection.v-select__selection--comma': { overflow: 'unset' } }
        for (const vSelect of [this.$refs.select, this.$refs.select2, this.$refs.select3]) {
          Object.entries(customStyles).forEach(([selector, styles]) => {
            Object.entries(styles).forEach(([style, value]) => {
              if (vSelect !== undefined && vSelect.$el.querySelector(selector) !== null) vSelect.$el.querySelector(selector).style[style] = value
            })
          })
        }
      })
    },
    onResize () {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    checkSelect2 () {
      const t = this.select2.gradeType
      return this.select2.isWatcher || (t !== 'A' && t !== 'B')
    },
    getItem (i, m) {
      if (i.myGrades[m] !== undefined) return i.myGrades[m].note
      else return '-'
    },
    getNote (i, m) {
      return i.myGrades[m].notiz
    },
    getHeadItem (i, h) {
      return i.myGrades[this.select2.fachThema][h].note
    },
    calculateMedianZ (anArray) {
      const retVal = { sum: 0, counter: 0 }
      for (const grade of anArray) {
        retVal.sum += Number(grade.note)
        retVal.counter += grade.note !== '' ? 1 : 0
      }
      return retVal.counter === 0 ? '' : (retVal.sum / retVal.counter).toFixed(2)
    },
    calculateMedian (anObj, which) {
      const courseId = this.select.id
      if (which === 'Z') {
        return this.calculateMedianZ(
          Object.values(anObj).filter(e => {
            if (e.fachThema !== undefined) {
              const thema = e.fachThema.split(', ')[1]
              return (this.namenExams.includes(thema)) || thema === 'Jahresnote'
            }
            return false
          })
        )
      }
      const extern = anObj.extern
      anObj = Object.values(anObj).filter(e => {
        return e.fachThema !== undefined && Number(e.fachThema.split('_')[0]) === courseId
      })
      const a = { sum: 0, count: 0 }
      const b = { sum: 0, count: 0 }
      this.whichHelper = which
      const notenschluss = this.getTagNotenschluss(which)
      const myObjFilter = function (e) {
        return e.fachThema !== undefined && (
          e.fachThema.split('_')[1].split(',')[0] === 'A' ||
          e.fachThema.split('_')[1].split(',')[0] === 'B'
        ) && new Date(e.erzeugtAm) <= notenschluss
      }
      for (const e of Object.values(anObj).filter(myObjFilter)) {
        const t = e.fachThema.split(',')[0].split('_')[1]
        if (t === 'A') {
          a.sum += Number(e.note)
          const compareTo = this.isSek2() ? null : ''
          a.count = e.note !== compareTo ? a.count + 1 : a.count
        }
        if (t === 'B') {
          b.sum += Number(e.note)
          const compareTo = this.isSek2() ? null : ''
          b.count = e.note !== compareTo ? b.count + 1 : b.count
        }
      }
      for (const gradeType of ['A', 'B']) {
        if (extern !== undefined && extern[`${courseId}_${gradeType}`] !== undefined) {
          for (const e of extern[`${courseId}_${gradeType}`]) {
            if (gradeType === 'A') {
              a.sum += e.value
              a.count++
            }
            if (gradeType === 'B') {
              b.sum += e.value
              b.count++
            }
          }
        }
      }
      if (a.sum === 0 && b.sum === 0) {
        if (this.isSek2() && (a.count > 0 || b.count > 0)) return Number(0).toFixed(2)
        else return ''
      }
      if (a.count > 0 && b.count > 0) {
        const factor = { A: this.select.primaryGradesWeight / 100, B: (100 - this.select.primaryGradesWeight) / 100 }
        return (factor.A * (a.sum / a.count) + factor.B * (b.sum / b.count)).toFixed(2)
      } else if (b.count > 0) {
        return (b.sum / b.count).toFixed(2)
      } else if (a.count > 0) {
        return (a.sum / a.count).toFixed(2)
      }
    },

    getTableRef ({ thema, sub }) {
      const hasThema = thema !== undefined
      const hasSub = sub !== undefined
      let currentFachThema
      let currentSubThema

      if (hasThema) currentFachThema = thema
      else currentFachThema = this.select2.fachThema
      if (hasSub) currentSubThema = sub
      const myGradesFilter = e => Object.keys(e.myGrades).includes(currentFachThema)
      let gradesTableObj = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
      if (this.isSek2()) gradesTableObj = { 0: 0, ...gradesTableObj, ...{ 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0 } }
      // const gradesTableObj = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
      for (const grade of this.grades.filter(myGradesFilter)) {
        if (hasSub) gradesTableObj[grade.myGrades[currentFachThema][currentSubThema].note] += 1
        else gradesTableObj[grade.myGrades[currentFachThema].note] += 1
      }
      return gradesTableObj
    },

    getClassMedian (obj) {
      return this.getMedianRef(obj).note / this.getMedianRef(obj).anzahl
    },
    getMedianRef ({ thema, sub }) {
      const hasThema = thema !== undefined
      const hasSub = sub !== undefined
      let currentFachThema
      let currentSubThema

      if (hasThema) currentFachThema = thema
      else currentFachThema = this.select2.fachThema
      if (hasSub) currentSubThema = sub

      const myGradesReducer = (a, b) => {
        const nextNote = hasSub ? b.myGrades[currentFachThema][currentSubThema].note : b.myGrades[currentFachThema].note
        const isEmpty = nextNote === '' || (this.isSek2() && nextNote === null)
        const helperB = { note: isEmpty ? 0 : nextNote, anzahl: isEmpty ? 0 : 1 }

        return { note: a.note + helperB.note, anzahl: a.anzahl + helperB.anzahl }
      }
      const myGradesFilter = e => Object.keys(e.myGrades).includes(currentFachThema)
      return this.grades.filter(myGradesFilter).reduce(myGradesReducer, { note: 0, anzahl: 0 })
    },

    getMedian (meta) {
      let currentFachThema
      if (meta === undefined) {
        currentFachThema = this.select2.fachThema
      } else {
        currentFachThema = meta
      }
      const myGradesReducer = (a, b) => {
        const nextNote = b.myGrades[currentFachThema].note
        const isEmpty = nextNote === ''
        const helperB = { note: isEmpty ? 0 : nextNote, anzahl: isEmpty ? 0 : 1 }

        return { note: a.note + helperB.note, anzahl: a.anzahl + helperB.anzahl }
      }
      const myGradesFilter = e => Object.keys(e.myGrades).includes(currentFachThema)
      return this.grades.filter(myGradesFilter).reduce(myGradesReducer, { note: 0, anzahl: 0 })
    },

    getMedianHead (which) {
      const currentFachThema = this.select2.fachThema
      const currentSubThema = which
      const myGradesReducer = (a, b) => {
        const nextNote = b.myGrades[currentFachThema][currentSubThema].note
        const isEmpty = nextNote === ''
        const helperB = { note: isEmpty ? 0 : nextNote, anzahl: isEmpty ? 0 : 1 }

        return { note: a.note + helperB.note, anzahl: a.anzahl + helperB.anzahl }
      }
      const myGradesFilter = e => Object.keys(e.myGrades).includes(currentFachThema)
      return this.grades.filter(myGradesFilter).reduce(myGradesReducer, { note: 0, anzahl: 0 })
    },
    getHJStart () {
      return getHJStart()
    },
    getTagErster (which) {
      return getTagErster(which)
    },
    getTagNotenschluss (which) {
      return getTagNotenschluss(which)
    },
    getTagZeugniseingabe (which) {
      return getTagZeugniseingabe(which)
    },
    getTagZeugnisdruck (which) {
      return getTagZeugnisdruck(which)
    },
    getToday () {
      return getToday()
    },
    isCreateMetaDisabled (term) {
      return isCreateMetaDisabled(term)
    },
    isChangeGradeDisabled ({ dateString, term }) {
      return isChangeGradeDisabled({ dateString, term })
    },
    isTimeForReportGrades (termObj) {
      return isTimeForReportGrades(termObj)
    },
    getTooLong (testedAt, backAt) {
      return getTooLong(testedAt, backAt)
    },
    getColor (level, grade) {
      return getColor(level, grade, this.noGradeText)
    },
    getTextColor (level, grade) {
      return getTextColor(level, grade, this.noGradeText)
    },
    shortenTopic (topic) {
      return shortenTopic(topic)
    },
    getCurrentGradeNames () {
      const { currentGradeNames } = this.getCurrent()
      return currentGradeNames
    },
    getCurrentGradeValues () {
      const { currentGradeValues } = this.getCurrent()
      return currentGradeValues
    },
    getCurrent () {
      const currentGradeValues = []
      const currentGradeNames = []
      for (const grade of this.grades) {
        if (this.select2.fachThema === grade.fachThema) {
          currentGradeValues.push(Number(grade.note))
          currentGradeNames.push(grade.vorname)
        }
      }
      return { currentGradeValues, currentGradeNames }
    },
    setChangeGrade () {
      this.changeNote = {
        id: this.select2.id,
        aOrB: this.select2.gradeType,
        topic: this.select2.topic,
        show: true,
        geschrieben: this.select2.testedAt,
        zurueckgegeben: this.select2.backAt,
        showGeschriebenCal: false,
        showZurueckgegebenCal: false,
        error: {
          topicExists: '',
          timelineError: ''
        }
      }
    },
    // do not delete because it is needed in this.initialize !
    resetNewGrade () {
      this.newNote = {
        isCreating: false,
        aOrB: '',
        topic: '',
        show: false,
        geschrieben: this.getCurrentDateString(),
        zurueckgegeben: this.getCurrentDateString(),
        showGeschriebenCal: false,
        showZurueckgegebenCal: false,
        error: {
          topicExists: '',
          timelineError: ''
        }
      }
    },
    getCurrentDateString () {
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    },
    customDataTableFilter (value, search, item) {
      if (item.myGrades[search] === undefined) {
        return false
      } else {
        return true
      }
    },
    setCurrentHeader () {
      this.applyCustomStyles()
      const t = this.select2.gradeType
      const top = this.select2.topic

      if (t === 'A' || t === 'B') {
        this.currentHeader = this.headers.default
      } else if (top === 'Prüfung' || top === 'mündliche Prüfung' || top === 'zusätzliche mündliche Prüfung') {
        this.currentHeader = this.headers[`${this.select.id}_EJ, Prüfungen`]
      } else if (this.is10OS() && t === 'EJ' && top === 'Zeugnisnote') {
        this.currentHeader = this.headers[`${this.select.id}_EJ, Zeugnisnote`]
      } else if ((t === 'HJ' || t === 'EJ') && (top === 'Zeugnisnote' || top === 'Jahresnote')) {
        this.currentHeader = this.headers[`${this.select.id}_${t}`]
      } else if ((t === 'HJ' || t === 'EJ') && top === 'Kopfnoten') {
        this.currentHeader = this.headers[this.select2.fachThema]
      }
    },
    setSelected (value) {
      let currentSchool = this.select0
      if (value?.select0 !== undefined) currentSchool = value.select0
      this.filteredItems = this.items.filter(e => e.school === currentSchool)
      this.select = this.items.find(e => e.school === currentSchool)
      this.setSelected2(value)
    },
    setSelected2 (value) {
      if (this.select.noten !== undefined) {
        let notenIndex = 0
        if (value?.select2 !== undefined) notenIndex = this.select.noten.findIndex(e => e.id === value.select2.id)
        this.select2 = this.select.noten[notenIndex]
        this.setCurrentHeader()
      } else {
        this.select2 = {}
      }
      this.applyCustomStyles()
    },
    async initialize () {
      this.resetNewGrade()
      const url = config.api_prisma_get_courses
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.get(url, axiosConfig)
        this.initializeData(res.data)
        this.initialize2()
      } catch (err) {
        console.error(err)
      }
    },
    initializeData (data) {
      for (const item of data) {
        const lastLetterOfVintage = item.class.vintage[item.class.vintage.length - 1]
        const jahrgang = item.class.vintage
        let anotherVintage
        if (lastLetterOfVintage === 'a') {
          if (data.findIndex(e => {
            return e.class.vintage[e.class.vintage.length - 1] !== 'a' &&
            e.class.vintage.substring(0, e.class.vintage.length - 1) === jahrgang.substring(0, jahrgang.length - 1)
          }) !== -1) {
            anotherVintage = lastLetterOfVintage
          } else anotherVintage = ''
        } else anotherVintage = lastLetterOfVintage
        const klasse = `${item.class.level}${anotherVintage}`
        const school = item.class.school.name
        if (!this.schools.includes(school)) this.schools.push(school)
        this.items.push({
          id: item.id,
          // delete after test before commit start
          // fachKlasse: `${item.courseType.name} ${klasse}`,
          // delete after test before commit end
          fachKlasse: `${item.courseType.name} ${this.isAB(school) ? klasse : jahrgang}`,
          level: item.class.level,
          jahrgang,
          fach: item.courseType.name,
          school,
          sliderEnabled: false,
          usesWeight: item.class.school.usesWeight,
          primaryGradesWeight: item.primaryGradesWeight,
          success: '',
          error: '',
          timeoutHandle: {},
          notizTimeoutHandle: {},
          uploading: false
          // isLocked: false
        })
        this.refactoredItems.push({
          id: item.id,
          jahrgang: item.class.vintage,
          klasse,
          fach: item.courseType.name
        })
      }
      if (this.savedSelect !== undefined) {
        this.select0 = this.savedSelect.select0
        this.setSelected(this.savedSelect)
      } else {
        // this.select = this.items[0]
        this.select0 = this.schools[0]
        this.setSelected()
      }
    },
    async initialize2 () {
      for (const item of this.items) {
        const hasExternalGrades = { A: false, B: false }
        const courseId = item.id
        const url = `${config.api_prisma_get_grades}/${courseId}`
        const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
        const hasGradesMeta = await this.$axios.get(url, axiosConfig)
          .then(res => {
            if (res.data.gradeMetas.length === 0) return false
            for (const e of res.data.gradeMetas) {
              const id = e.id
              const thema = `${e.gradeType}, ${e.topic}`
              const fachThema = `${courseId}_${thema}`
              if (item.noten === undefined) item.noten = []
              if (item.noten !== undefined && !item.noten.includes(thema)) {
                item.noten.push({
                  id,
                  fachThema,
                  thema,
                  gradeType: e.gradeType,
                  topic: e.topic,
                  testedAt: e.testedAt.substr(0, 10),
                  backAt: e.backAt.substr(0, 10),
                  isWatcher: e.isWatcher,
                  teacher: `${e.teacher.firstName} ${e.teacher.lastName}`
                })
                const t = e.gradeType
                if (t === 'A' || t === 'B') {
                  this.allMetas.push(fachThema)
                } else if ((t === 'HJ' || t === 'EJ') && this.namenKopfnoten.includes(e.topic)) {
                  this.allHeads.push(fachThema)
                } else if (t === 'EJ' && this.namenExams.includes(e.topic)) {
                  this.allExams.push(fachThema)
                }
              }
              for (const grade of e.grades) {
                const studentHasGradeAlready = this.grades.find(e => e.id === grade.student.id)
                const note = this.getGradeValue({ note: grade.value, notiz: grade.note })
                const currentGrade = {
                  key: grade.id,
                  odataNoteOid: grade.odataNoteOid,
                  erzeugtAm: grade.createdAt.split('T')[0],
                  fachThema,
                  note,
                  noteneingabe: note,
                  notiz: grade.note,
                  isNoteEnabled: false,
                  rating: 6 - grade.value,
                  error: '',
                  success: '',
                  notizError: '',
                  notizSuccess: ''
                }
                if (studentHasGradeAlready !== undefined) {
                  // const courseId = fachThema.split('_')[0]
                  if ((e.gradeType === 'HJ' || e.gradeType === 'EJ') && this.namenKopfnoten.includes(e.topic)) {
                    if (studentHasGradeAlready.myGrades[`${courseId}_${e.gradeType}, Kopfnoten`] === undefined) {
                      // https://vuejs.org/v2/guide/reactivity.html
                      // studentHasGradeAlready.myGrades[`${courseId}_${e.gradeType}, Kopfnoten`] = {}
                      this.$set(studentHasGradeAlready.myGrades, `${courseId}_${e.gradeType}, Kopfnoten`, {})
                    }
                    // // studentHasGradeAlready.myGrades[`${courseId}_${e.gradeType}, Kopfnoten`][fachThema] = currentGrade
                    this.$set(studentHasGradeAlready.myGrades[`${courseId}_${e.gradeType}, Kopfnoten`], fachThema, currentGrade)
                  } else {
                    // studentHasGradeAlready.myGrades[fachThema] = currentGrade
                    this.$set(studentHasGradeAlready.myGrades, fachThema, currentGrade)
                  }
                } else {
                  // const courseId = fachThema.split('_')[0]
                  const myGrades = {}
                  if (this.namenKopfnoten.includes(e.topic)) {
                    const headGradesString = `${courseId}_${e.gradeType}, Kopfnoten`
                    if (myGrades[headGradesString] === undefined) myGrades[headGradesString] = {}
                    myGrades[headGradesString][fachThema] = currentGrade
                  } else {
                    myGrades[fachThema] = currentGrade
                  }
                  this.grades.push({
                    id: grade.student.id,
                    sex: grade.student.sex,
                    vorname: grade.student.firstName.split(' ')[0].split('-')[0],
                    name: grade.student.lastName.split(' ')[0],
                    vintage: grade.student.class.vintage,
                    oid: grade.student.odataPersonOid,
                    myGrades
                  })
                }
              }
            }
            // I assume student exists && has at least one grade in the course
            for (const externalGrade of res.data.externalGrades) {
              hasExternalGrades[externalGrade.gradeType] |= true
              const student = this.grades.find(e => e.oid === externalGrade.student.odataPersonOid)
              delete externalGrade.student
              if (student.myGrades.extern === undefined) student.myGrades.extern = {}
              if (student.myGrades.extern[`${courseId}_${externalGrade.gradeType}`] === undefined) student.myGrades.extern[`${courseId}_${externalGrade.gradeType}`] = []
              student.myGrades.extern[`${courseId}_${externalGrade.gradeType}`].push(externalGrade)
            }
            return true
          })
          .catch((err) => {
            console.error(err)
          })
        if (hasGradesMeta) {
          // inject pseudo note Kopfnoten if at least one Kopfnote exists
          const termStringArray = [] // ['HJ', 'EJ']
          for (const type of ['HJ', 'EJ']) {
            if (item.noten.findIndex(e => this.namenKopfnoten.includes(e.topic) && e.gradeType === type) !== -1) {
              termStringArray.push(type)
            }
          }

          // if (item.noten !== undefined && item.noten.findIndex(e => this.namenKopfnoten.includes(e.topic)) !== -1) {
          if (item.noten !== undefined) {
            for (const type of termStringArray) {
              const gradeType = type
              const topic = 'Kopfnoten'
              const thema = `${gradeType}, ${topic}`
              const fachThema = `${item.id}_${thema}`
              const indexOfBetragen = item.noten.findIndex(e => e.gradeType === type && e.topic === 'Betragen')
              item.noten.splice(indexOfBetragen, 0, {
                fachThema,
                thema,
                gradeType,
                topic
              })
            }
          }

          const namenKopfnoten = this.objKopfnoten.map(e => e.name)
          const is10OS = this.is10OS()
          const newHeaders = createHeaders(courseId, item, namenKopfnoten, is10OS, hasExternalGrades)
          for (const head of [`${courseId}_HJ`, `${courseId}_EJ`, `${courseId}_HJ, Kopfnoten`, `${courseId}_EJ, Kopfnoten`]) {
            this.headers[head] = newHeaders[head]
          }
          if (is10OS) {
            this.headers[`${courseId}_EJ, Prüfungen`] = newHeaders[`${courseId}_EJ, Prüfungen`]
            this.headers[`${courseId}_EJ, Zeugnisnote`] = newHeaders[`${courseId}_EJ, Zeugnisnote`]
          }
        }
        this.itemsLoaded += 1
      }
      if (this.savedSelect !== undefined) {
        this.select0 = this.savedSelect.select0
        this.select = this.items.find(e => e.id === this.savedSelect.select)
        this.select2 = this.savedSelect.select2

        this.savedSelect = undefined
      } else if (this.select.noten !== undefined) {
        this.select2 = this.select.noten[0]
      }
      // this.currentHeader = this.headers.default
      this.setCurrentHeader()
      // refactor after HJ
      // this.createNewFinalGrades('EJ')
      // refactor after HJ end
      // this.applyCustomStyles()
      this.isCreating = false
      this.isLoading = false
    },

    async createNewFinalGrades (which) {
      let didWork = false
      const themen = [
        { thema: `${which}, Betragen`, name: `${which},Betragen`, gradeType: which, topic: 'Betragen' },
        { thema: `${which}, Fleiß`, name: `${which},Fleiß`, gradeType: which, topic: 'Fleiß' },
        { thema: `${which}, Mitarbeit`, name: `${which},Mitarbeit`, gradeType: which, topic: 'Mitarbeit' },
        { thema: `${which}, Ordnung`, name: `${which},Ordnung`, gradeType: which, topic: 'Ordnung' },
        { thema: `${which}, Zeugnisnote`, name: `${which},Zeugnisnote`, gradeType: which, topic: 'Zeugnisnote' }
      ]
      for (const i of this.items) {
        for (const einThema of themen) {
          if (i.noten !== undefined && i.noten.findIndex(e => e.thema === einThema.thema) === -1) {
            await this.createNewGrade({
              grade: this.createGradeObj(einThema),
              kurs: i.jahrgang,
              fach: i.fach
            })
            didWork |= true
          }
        }
      }
      if (didWork) {
        this.items = []
        this.refactoredItems = []
        this.grades = []
        this.itemsLoaded = 0
        this.newNote.isCreating = false
        this.initialize()
      }
    },

    createGradeObj ({ gradeType, topic }) {
      return {
        gradeType,
        topic,
        testedAt: getTagNotenschluss(gradeType),
        backAt: getTagNotenschluss(gradeType)
      }
    },

    createNewGrade ({ grade, kurs, fach }) {
      if (grade !== undefined || !this.isCreating) {
        this.isCreating = true
        const url = config.api_prisma_create_grade
        const axiosData = grade !== undefined
          ? { grade, kurs, fach }
          : {
              grade: {
                gradeType: this.newNote.aOrB,
                topic: this.newNote.topic,
                testedAt: this.newNote.geschrieben,
                backAt: this.newNote.zurueckgegeben
              },
              kurs: this.select.jahrgang,
              fach: this.select.fach
            }
        const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
        this.$axios.post(url, axiosData, axiosConfig)
          .then((res) => {
            if (grade === undefined) {
              const id = res.data.gradeMeta.id
              const gradeType = res.data.gradeMeta.gradeType
              const topic = res.data.gradeMeta.topic
              const thema = `${gradeType}, ${topic}`
              const fachThema = `${this.select.id}_${thema}`
              const testedAt = res.data.gradeMeta.testedAt.substr(0, 10)
              const backAt = res.data.gradeMeta.backAt.substr(0, 10)
              const teacher = res.data.gradeMeta.teacher
              this.savedSelect = { selectId: this.select.id, select2: { id, gradeType, topic, testedAt, backAt, fachThema, thema, teacher } }
              this.select2 = { id, gradeType, topic, testedAt, backAt, fachThema, thema, teacher }
              this.items = []
              this.refactoredItems = []
              this.grades = []
              this.itemsLoaded = 0
              this.initialize()
            }
          })
      }
    },

    createNewIsReady (e) {
      this.savedSelect = e
      this.items = []
      this.refactoredItems = []
      this.grades = []
      this.itemsLoaded = 0
      this.isCreating = this.newNote.isCreating
      this.initialize()
    },
    changeMetaIsReady ({ success, savedFachThema }) {
      if (success.gradeType !== undefined || success.topic !== undefined) {
        this.changeGrades(savedFachThema, success)
        const currentSelectedOfSelectNoten = this.select.noten.findIndex(el => el.fachThema === savedFachThema)
        if (success.gradeType !== undefined && success.topic !== undefined) {
          this.select.noten[currentSelectedOfSelectNoten].gradeType = success.gradeType
          this.select2.gradeType = success.gradeType
          this.select.noten[currentSelectedOfSelectNoten].topic = success.topic
          this.select2.topic = success.topic
        } else if (success.gradeType !== undefined) {
          this.select.noten[currentSelectedOfSelectNoten].gradeType = success.gradeType
          this.select2.gradeType = success.gradeType
        } else if (success.topic !== undefined) {
          this.select.noten[currentSelectedOfSelectNoten].topic = success.topic
          this.select2.topic = success.topic
        }
        this.select2.thema = `${this.select2.gradeType}, ${this.select2.topic}`
        this.select.noten[currentSelectedOfSelectNoten].thema = this.select2.thema
        this.select2.fachThema = `${this.select2.fachThema.split('_')[0]}_${this.select2.thema}`
        this.select.noten[currentSelectedOfSelectNoten].fachThema = this.select2.fachThema
      }
      if (success.backAt !== undefined) this.select2.backAt = success.backAt.split('T')[0]
      if (success.testedAt !== undefined) this.select2.testedAt = success.testedAt.split('T')[0]
    },
    changeGrades (savedFachThema, success) {
      const myGradesFilter = e => Object.keys(e.myGrades).includes(savedFachThema)
      let newFachThema
      let id
      for (const grade of this.grades.filter(myGradesFilter)) {
        const fachThemaSplited = grade.myGrades[savedFachThema].fachThema.split('_')
        id = fachThemaSplited[0]
        const topic = fachThemaSplited[1].split(',')[1]
        const type = fachThemaSplited[1].split(',')[0]
        // if (grade.myGrades[savedFachThema].fachThema === savedFachThema) {
        if (success.gradeType !== undefined && success.topic !== undefined) {
          grade.myGrades[savedFachThema].fachThema = `${id}_${success.gradeType}, ${success.topic}`
        } else if (success.gradeType !== undefined) {
          grade.myGrades[savedFachThema].fachThema = `${id}_${success.gradeType},${topic}`
        } else if (success.topic !== undefined) {
          grade.myGrades[savedFachThema].fachThema = `${id}_${type}, ${success.topic}`
        }
        newFachThema = grade.myGrades[savedFachThema].fachThema
        grade.myGrades[newFachThema] = grade.myGrades[savedFachThema]
        delete grade.myGrades[savedFachThema]
      }
      const currentMetaIndex = this.allMetas.findIndex(e => e === savedFachThema)
      this.allMetas.splice(currentMetaIndex, 1, newFachThema)
      for (const type of ['HJ', 'EJ']) {
        const assocHeader = this.headers[`${id}_${type}`].find(e => e.value === savedFachThema)
        assocHeader.text = newFachThema.split('_')[1]
        assocHeader.value = newFachThema
      }
      this.applyCustomStyles()
    },

    async setNote (key, value, report) {
      const currentGrade = this.grades.find(e => {
        return Object.values(e.myGrades).find(f => {
          if (f.key === undefined) {
            return Object.values(f).find(g => {
              return g.key === key
            })
          } else return f.key === key
        })
      })
      const currentFachThema = this.select2.fachThema
      const currentMyGrade = currentGrade.myGrades[currentFachThema]
      if (report) currentMyGrade.error = 'lade hoch'
      else currentMyGrade.notizError = 'lade hoch'
      const url = `${config.api_prisma_set_grade}/${key}/notiz`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.post(url, { notiz: value }, axiosConfig)
        if (res.data.retValuePrisma.success) {
          if (report) {
            const checkLater = () => {
              if (!currentMyGrade.uploading) {
                clearInterval(currentMyGrade.notizTimeoutHandle)
                clearTimeout(currentMyGrade.timeoutHandle)
                currentMyGrade.error = ''
                currentMyGrade.success = 'geladen'
                currentMyGrade.timeoutHandle = setTimeout(() => { currentMyGrade.success = '' }, 5000)
                currentMyGrade.notiz = value
              }
            }
            if (currentMyGrade.uploading) {
              currentMyGrade.notizTimeoutHandle = setInterval(checkLater, 500)
            } else checkLater()
          } else {
            clearTimeout(currentMyGrade.notizTimeoutHandle)
            currentMyGrade.notizError = ''
            currentMyGrade.notizSuccess = 'geladen'
            currentMyGrade.notizTimeoutHandle = setTimeout(() => { currentMyGrade.notizSuccess = '' }, 5000)
            currentMyGrade.notiz = value
          }
        }
      } catch (err) {
        currentMyGrade.notizError = 'nicht erlaubt!'
        console.error(err)
      }
    },
    getGradeValue ({ note, notiz }) {
      const notizen = notiz?.split('_')
      if (this.isSek2() || (note >= 1 && note <= 6 && note !== null)) return note
      if ((!this.isSek2() && note >= 6) || note === null) return notizen?.length > 1 ? notizen[1] : ''
    },
    doneNothing (item) {
      const grade = item.myGrades[this.select2.fachThema]
      const retBool = grade.success === '' && grade.error === ''
      return retBool
    },
    successABES (item) {
      const retBool = item.myGrades[this.select2.fachThema].success === 'geladen'
      return retBool
    },
    noABES (item) {
      if (item === undefined) {
        return !this.$store.getters.get_abes_online
      } else {
        return item.myGrades[this.select2.fachThema].notizError === 'ABES'
      }
    },
    uploadingABES (item) {
      const retBool = item.myGrades[this.select2.fachThema].uploading
      return retBool
    },
    setReportGrade ({ key, odataNoteOid, value, median }) {
      let note = median
      if (value !== '' && isNaN(Number(value))) {
        note = `${note}_${value}`
      }
      this.setGrade(key, value, undefined, odataNoteOid)
      this.setNote(key, note, true)
    },
    isSek2 () {
      return (this.select.level >= 11 && this.select.school.substring(0, 2) === 'GY') ||
        (this.select.level >= 12 && this.select.school.substring(0, 3) === 'BGY')
    },
    async setGrade (key, value, name, odataNoteOid) {
      const isReport = odataNoteOid !== undefined
      const currentGrade = this.grades.find(e => {
        return Object.values(e.myGrades).find(f => {
          if (f.key === undefined) {
            return Object.values(f).find(g => {
              return g.key === key
            })
          } else return f.key === key
        })
      })
      const currentFachThema = this.select2.fachThema
      const currentFachThemaSplitted = currentFachThema.split(',')
      let currentMyGrade

      if (currentFachThemaSplitted[1] !== ' Kopfnoten') {
        currentMyGrade = currentGrade.myGrades[currentFachThema]
      } else {
        currentMyGrade = currentGrade.myGrades[currentFachThema][`${currentFachThemaSplitted[0]}, ${name}`]
      }
      if (this.possibleGrades.includes(value) ||
        (this.isSek2() && this.possibleSek2Grades) ||
        (isReport && this.possibleReportGrades.includes(value))
      ) {
        const notizArray = Object.keys(this.notizMap)
        value = !this.isSek2() && notizArray.includes(value) ? this.notizMap[value] : value
        value = value === '' ? 'null' : value
        if (isReport) currentMyGrade.uploading = true
        else currentMyGrade.error = 'lade hoch'
        const url = `${config.api_prisma_set_grade}/${key}/${value}`
        const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
        try {
          const res = await this.$axios.post(url, { odataNoteOid }, axiosConfig)
          if (res.data.retValuePrisma.success) {
            if (isReport) {
              if (res.data.retValueODATA?.success) {
                currentMyGrade.uploading = false
              } else {
                clearInterval(currentMyGrade.notizTimeoutHandle)
                currentMyGrade.notizError = 'ABES'
                currentMyGrade.error = 'offline'
                currentMyGrade.timeoutHandle = setTimeout(() => {
                  currentMyGrade.notizError = ''
                  currentMyGrade.error = ''
                  currentMyGrade.uploading = false
                }, 5000)
                // this.$store.getters.get_show_avatars,
                // const status = this.$store.getters.abes_online
                this.$store.commit('set_abes_online', false)
                // currentMyGrade.timeoutHandle = setTimeout(() => { currentMyGrade.error = '' }, 5000)

                currentMyGrade.noteneingabe = currentMyGrade.note
                // !!! reset prisma also !!!

                // sdfsdf
              }
            } else {
              clearTimeout(currentMyGrade.timeoutHandle)
              currentMyGrade.error = ''
              currentMyGrade.success = 'geladen'
              currentMyGrade.timeoutHandle = setTimeout(() => { currentMyGrade.success = '' }, 5000)
              currentMyGrade.note = value === 'null' ? '' : value
              currentMyGrade.rating = 6 - value
            }
          }
        } catch (err) {
          currentMyGrade.error = 'nicht erlaubt!'
          console.error(err)
        }
      }
    }

  }
}

</script>

<style scoped>
  /* https://github.com/vuetifyjs/vuetify/issues/9576 */
  .reqError {
    color: red;
  }
  .reqSucces {
    color: transparent;
    user-select: none;
  }
  .v-select {
    max-width: min-content;
  }
  .v-select-grades {
    top: 10px;
  }
  .v-text-field-note {
    top: 10px;
  }
  .sparklineWorkaround {
    font-size: 3px;
    pointer-events: none;
    cursor: default;
    /* transform: translate(100,100) rotate(90); */
  }
</style>
