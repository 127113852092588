const baseUrl = 'https://test.noten.dpfa.de'

module.exports = {
  kc: {
    url: 'https://testbed.meeting-dpfa.de/auth',
    realm: 'DPFA',
    clientId: 'dpfa-np-fe-test'
  },

  staticSaveAllGrades: `${baseUrl}/studentOverview.docx`,

  api_status: `${baseUrl}/status`,

  api_prisma_get_courses: `${baseUrl}/api/grades/prisma/courses`,
  api_prisma_set_weight: `${baseUrl}/api/grade/prisma/setWeight`,
  api_prisma_get_grades: `${baseUrl}/api/grades/prisma/grades`,
  api_prisma_create_grade: `${baseUrl}/api/grade/prisma/create`,
  api_prisma_change_grade: `${baseUrl}/api/grade/prisma/change`,
  api_prisma_set_grade: `${baseUrl}/api/grade/prisma/students/set`,
  api_prisma_create_external_grades: `${baseUrl}/api/grade/prisma/createExternal`,

  api_prisma_get_class: `${baseUrl}/api/grades/prisma/class`,

  api_prisma_get_student_grades: `${baseUrl}/api/grades/prisma/student`,
  api_prisma_get_parent: `${baseUrl}/api/grades/prisma/parent`,
  api_get_avatar: `${baseUrl}/api/grade/student`,

  api_prisma_get_report: `${baseUrl}/api/grades/prisma/report`,
  api_prisma_set_report: `${baseUrl}/api/grades/prisma/set`,
  api_prisma_get_report_gtas: `${baseUrl}/api/grades/prisma/gtas`,
  api_prisma_set_report_gtas: `${baseUrl}/api/grades/prisma/gtas`,

  api_calendar_get_entry: `${baseUrl}/api/calendar/entry/get`,
  api_calendar_set_entry: `${baseUrl}/api/calendar/entry/set`,

  api_get_timetable: `${baseUrl}/api/timetable`,
  api_get_odata_timetable: `${baseUrl}/api/odata/timetable`,
  api_set_timetable: `${baseUrl}/api/set/timetable`,

  api_prisma_get_contact: `${baseUrl}/api/contact/get`
}
