var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$keycloak !== undefined && _vm.$keycloak.authenticated)?_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-4 mb-4"},[_c('v-card',[_c('v-card-title',[_vm._v(" Zensuren "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"name":"nw_data_table","fixed-header":"","height":_vm.windowHeight - _vm.heightOffset,"headers":_vm.headers,"items":_vm.grades,"search":_vm.search,"item-key":"name","sort-by":"name","loading":_vm.isLoading,"footer-props":{
            itemsPerPageOptions: _vm.isPhone ? [1, -1] : [-1],
          }},scopedSlots:_vm._u([{key:"header.e",fn:function(ref){
          var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ∅ ")]}},_vm._l((['A', 'B', 'HJ', 'EJ']),function(type){return {key:("item." + type),fn:function(ref){
          var item = ref.item;
return [_c('GradeRow',{key:((item.name) + "_" + (item[type])),attrs:{"grades":item[type],"isSek2":_vm.isSek2}})]}}}),{key:"item.e",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.getColor(_vm.level, item.e),"text-color":_vm.getTextColor(_vm.level, item.e),"dark":_vm.getDark(_vm.level, item.e)}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.e)+" ")])]}}],null,true)},[_vm._v(" A"),_c('sub',[_vm._v(_vm._s(item.gradesWeight.A))]),_vm._v(" ÷ B"),_c('sub',[_vm._v(_vm._s(item.gradesWeight.B))])])]}}],null,true)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }