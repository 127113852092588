<template>
  <v-dialog
    v-model="note.show"
    persistent
    max-width="300"
  >
    <v-card>
      <v-card-title class="text-h5" v-if="type==='new'">
        neue Note definieren
      </v-card-title>
      <v-card-title class="text-h5" v-if="type==='change'">
        Notendefinition ändern
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4" v-if="isAB()">
              <v-select
                v-model="note.aOrB"
                :items="['A', 'B']"
                @input="() => checkTopic(type)"
                class="maxWidth"
              >
                <template #label>
                  <label>A/B<sup>*</sup></label>
                </template>
              </v-select>
            </v-col>
            <v-col :cols="isAB() ? 8 : (isBB() ? 12 : 8)">
              <v-text-field
                v-model="note.topic"
                :error-messages="note.error.topic"
                @input="() => checkTopic(type)"
              >
                <template #label>
                  <label>Thema<sup>*</sup></label>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-dialog
                ref="dialogGeschriebenNewCal"
                v-model="note.showGeschriebenCal"
                :return-value.sync="note.geschrieben"
                width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="note.geschrieben"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details=true
                  >
                    <template #label>
                      <label>geschrieben am</label>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="note.geschrieben"
                  first-day-of-week="1"
                  :allowed-dates="(date) => allowedDates('tested', date)"
                  scrollable
                  @input="() => {
                    if (checkTimelineError(type) === '') {
                      $refs.dialogGeschriebenNewCal.save(note.geschrieben)
                      note.showGeschriebenCal = false
                    }
                  }"
                >
                  <v-alert dense outlined elevation="5" type="warning" v-if="checkTimelineError(type) !== ''">
                    {{ checkTimelineError(type) }}
                  </v-alert>
                  <!-- <v-spacer></v-spacer>
                  <v-btn color="error" icon @click="note.showGeschriebenCal = false">
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                  <v-btn color="success" icon @click="$refs.dialogGeschriebenNewCal.save(note.geschrieben)">
                    <v-icon>mdi-check</v-icon>
                  </v-btn> -->
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <v-dialog
                ref="dialogZurueckgegebenNewCal"
                v-if="note.geschrieben !== undefined && note.zurueckgegeben !== undefined"
                v-model="note.showZurueckgegebenCal"
                :return-value.sync="note.zurueckgegeben"
                width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on"
                    v-bind="attrs"
                    v-model="note.zurueckgegeben"
                    label="zurueckgegeben am"
                    append-icon="mdi-calendar"
                    readonly
                    :error-messages="note.error.timelineError"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="note.zurueckgegeben"
                  first-day-of-week="1"
                  :allowed-dates="(date) => allowedDates('back', date)"
                  scrollable
                  @input="() => {
                    if (checkTimelineError(type) === '') {
                      $refs.dialogZurueckgegebenNewCal.save(note.zurueckgegeben)
                      note.showZurueckgegebenCal = false
                    }
                  }"
                >
                  <v-alert dense outlined elevation="5" type="warning" v-if="checkTimelineError(type) !== ''">
                    {{ checkTimelineError(type) }}
                  </v-alert>

                  <!-- <v-spacer></v-spacer>
                  <v-btn color="error" icon @click="note.showZurueckgegebenCal = false">
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                  <v-btn color="success" icon @click="$refs.dialogZurueckgegebenNewCal.save(note.zurueckgegeben)">
                    <v-icon>mdi-check</v-icon>
                  </v-btn> -->
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions >
        <small :class="checkNull(type)"><sup>*</sup>Feld wird benötigt</small>
        <v-spacer></v-spacer>
        <v-btn v-if="type === 'new'" color="error" icon @click="resetNewGrade">
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
        <v-btn v-else-if="type === 'change'" color="error" icon @click="note.show = false">
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
        <v-btn
          color="success"
          :disabled="hasErrors(type) || (type === 'new' && note.isCreating)"
          @click="changeOrCreateGradeMeta"
          icon>
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)
const { getTagErster, getTagZeugniseingabe } = require('../../handler/dateHandler')

export default {
  props: {
    value: Object,
    type: String,
    select0: String,
    select: Object,
    select2: Object
  },

  data () {
    return {
      namenKopfnoten: ['Betragen', 'Mitarbeit', 'Fleiß', 'Ordnung']
    }
  },

  computed: {
    getAllowedDatesArrayTested: function () {
      return this.getAllowedDatesArray('tested')
    },
    getAllowedDatesArrayBack: function () {
      return this.getAllowedDatesArray('back')
    },
    note: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    isAB () {
      return this.select0.split(' ')[0] === 'GS' ||
        this.select0.split(' ')[0] === 'OS' ||
        this.select0.split(' ')[0] === 'GY' ||
        this.select0.split(' ')[0] === 'BGY' ||
        this.select0.split(' ')[0] === 'FOS'
    },
    isBB () {
      return this.select0.split(' ')[0] === 'BFS' ||
        this.select0.split(' ')[0] === 'FS' ||
        this.select0.split(' ')[0] === 'LOG'
    },
    checkTopic (which) {
      const checkTopicExistsVar = this.checkTopicExists(which)
      const checkTopicEndsWithSpaceVar = this.checkTopicEndsWithSpace(which)
      const checkTopicKopfnoteVar = this.checkTopicKopfnote(which)
      const checkTopicHasCommaVar = this.checkTopicHasComma(which)
      this.note.error.topic = []
      if (checkTopicExistsVar !== '') this.note.error.topic.push(checkTopicExistsVar)
      if (checkTopicEndsWithSpaceVar !== '') this.note.error.topic.push(checkTopicEndsWithSpaceVar)
      if (checkTopicKopfnoteVar !== '') this.note.error.topic.push(checkTopicKopfnoteVar)
      if (checkTopicHasCommaVar !== '') this.note.error.topic.push(checkTopicHasCommaVar)
    },
    checkTopicExists (which) {
      if (this.select.noten !== undefined) {
        if (which === 'change' &&
          this.select2.gradeType === this.note.aOrB &&
          this.select2.topic === this.note.topic
        ) return ''

        if (this.isAB()) {
          return (this.select.noten.findIndex(e => e.thema === `${this.note.aOrB}, ${this.note.topic}`) === -1) ? '' : 'Thema existiert bereits!'
        } else if (this.isBB()) {
          return (this.select.noten.findIndex(e => e.topic === this.note.topic) === -1) ? '' : 'Thema existiert bereits!'
        }
      } else return ''
    },
    checkTopicEndsWithSpace (which) {
      if (this.select.noten !== undefined) {
        if (which === 'change' &&
          this.select2.gradeType === this.note.aOrB &&
          this.select2.topic === this.note.topic
        ) return ''
        else return (this.note.topic[this.note.topic.length - 1] === ' ') ? 'Endet auf Leerzeichen!' : ''
      } else return ''
    },
    checkTopicKopfnote (which) {
      if (this.select.noten !== undefined) {
        if (which === 'change' &&
          this.note.aOrB !== undefined &&
          this.note.topic !== undefined &&
          this.select2.gradeType === this.note.aOrB &&
          this.select2.topic === this.note.topic
        ) return ''
        else return (this.namenKopfnoten.includes(this.note.topic)) ? 'keine Kopfnoten!' : ''
      } else return ''
    },
    checkTopicHasComma (which) {
      if (this.select.noten !== undefined) {
        if (which === 'change' &&
          this.note.aOrB !== undefined &&
          this.note.topic !== undefined &&
          this.select2.gradeType === this.note.aOrB &&
          this.select2.topic === this.note.topic
        ) return ''
        else return this.note.topic.includes(',') ? 'Enthält Komma!' : ''
      } else return ''
    },
    allowedDates (type, date) {
      if (type === 'tested') {
        return this.getAllowedDatesArrayTested.includes(date)
      } else if (type === 'back') {
        return this.getAllowedDatesArrayBack.includes(date)
      }
    },
    getAllowedDatesArray (type) {
      // const firstDayString = '2021-09-06' // ABES - DatumVon
      const today = new Date()
      // const tagZeugnisdruck = new Date('2021-02-10')

      const zweitesHJ = today >= this.getTagZeugniseingabe('HJ')
      let firstDayString = this.getTagErster('HJ').toISOString().substr(0, 10) // ABES - DatumVon
      let lastDayString = ''
      if (type === 'tested') {
        lastDayString = '2023-01-27' // ABES - DatumBis
      } else if (type === 'back') {
        lastDayString = '2023-02-11' // ABES - DatumBis
      }
      if (zweitesHJ) {
        firstDayString = '2023-01-31' // ABES - DatumVon
        if (type === 'tested') {
          lastDayString = '2023-06-27' // ABES - DatumBis
        } else if (type === 'back') {
          lastDayString = '2023-07-04' // ABES - DatumBis
        }
      }
      const firstDay = new Date(firstDayString).valueOf()
      const oneDayLater = 24 * 60 * 60 * 1000
      const allowedDatesArray = [firstDayString]
      let nextDayString = new Date(firstDay + oneDayLater).toISOString().substr(0, 10)
      let nextDay = new Date(nextDayString).valueOf()
      let counter = 1
      while (nextDayString < lastDayString) {
        allowedDatesArray.push(nextDayString)
        if (counter === 4) {
          nextDay += 3 * oneDayLater
          nextDayString = new Date(nextDay).toISOString().substr(0, 10)
          counter = 0
        } else {
          nextDay += oneDayLater
          nextDayString = new Date(nextDay).toISOString().substr(0, 10)
          counter++
        }
      }
      return allowedDatesArray
    },

    checkTimelineError (which) {
      if (which === 'new') {
        return (this.note.geschrieben <= this.note.zurueckgegeben) ? '' : 'Zuerst schreiben, dann zurückgeben!'
      } else if (which === 'change' && this.note.aOrB !== undefined && this.note.topic !== undefined) {
        if (this.select2.gradeType === this.note.aOrB && this.select2.topic === this.note.topic) {
          if (this.note.geschrieben !== this.select2.testedAt || this.note.zurueckgegeben !== this.select2.backAt) {
            return (this.note.geschrieben <= this.note.zurueckgegeben) ? '' : 'Zuerst schreiben, dann zurückgeben!'
          }
        } else {
          return (this.note.geschrieben <= this.note.zurueckgegeben) ? '' : 'Zuerst schreiben, dann zurückgeben!'
        }
      }
    },
    checkNull () {
      if (this.isAB()) {
        return (this.note.topic !== '' && this.note.aOrB !== '') ? 'reqSucces' : 'reqError'
      } else if (this.isBB()) {
        return this.note.topic !== '' ? 'reqSucces' : 'reqError'
      }
    },
    hasErrors () {
      const hasNullError = this.checkNull() === 'reqError'
      const hasTopicError = this.note.error.topic?.length > 0
      const hasTimelineError = this.note.error.timelineError?.length > 0
      return hasNullError || hasTopicError || hasTimelineError
    },
    resetNewGrade () {
      const value = {
        isCreating: false,
        aOrB: '',
        topic: '',
        show: false,
        geschrieben: this.getCurrentDateString(),
        zurueckgegeben: this.getCurrentDateString(),
        showGeschriebenCal: false,
        showZurueckgegebenCal: false,
        error: {
          topicExists: '',
          timelineError: ''
        }
      }
      this.$emit('input', value)
    },
    getCurrentDateString () {
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    },

    changeOrCreateGradeMeta () {
      if (this.type === 'new') this.createNewGrade({})
      if (this.type === 'change') this.changeGradeMeta({})
    },
    async createNewGrade ({ grade, kurs, fach }) {
      if (grade !== undefined || !this.note.isCreating) {
        this.note.isCreating = true
        if (this.note.aOrB === '') this.note.aOrB = 'B'
        const url = config.api_prisma_create_grade
        const axiosData = grade !== undefined
          ? { grade, kurs, fach }
          : {
              grade: {
                gradeType: this.note.aOrB,
                topic: this.note.topic,
                testedAt: this.note.geschrieben,
                backAt: this.note.zurueckgegeben
              },
              kurs: this.select.jahrgang,
              fach: { id: this.select.id, type: { name: this.select.fach } }
            }
        const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
        try {
          const res = await this.$axios.post(url, axiosData, axiosConfig)
          if (grade === undefined) {
            const id = res.data.gradeMeta.id
            const gradeType = res.data.gradeMeta.gradeType
            const topic = res.data.gradeMeta.topic
            const thema = `${gradeType}, ${topic}`
            const fachThema = `${this.select.id}_${thema}`
            const testedAt = res.data.gradeMeta.testedAt.substr(0, 10)
            const backAt = res.data.gradeMeta.backAt.substr(0, 10)
            const teacher = res.data.gradeMeta.teacher
            const savedSelect = { select0: this.select0, select: this.select.id, select2: { id, gradeType, topic, testedAt, backAt, fachThema, thema, teacher } }
            this.$emit('created', savedSelect)
          }
        } catch (err) {
          console.error(err)
        }
      }
    },

    changeGradeMeta () {
      const savedFachThema = this.select2.fachThema
      const data = {}
      const gradeTypeHasChanged = this.note.aOrB !== this.select2.gradeType
      if (gradeTypeHasChanged) data.gradeType = this.note.aOrB
      const topicHasChanged = this.note.topic !== this.select2.topic
      if (topicHasChanged) data.topic = this.note.topic
      const testedAtHasChanged = this.note.geschrieben !== this.select2.testedAt
      if (testedAtHasChanged) data.testedAt = new Date(this.note.geschrieben)
      const backAtHasChanged = this.note.zurueckgegeben !== this.select2.backAt
      if (backAtHasChanged) data.backAt = new Date(this.note.zurueckgegeben)

      const url = config.api_prisma_change_grade
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      this.$axios.post(url, {
        id: this.note.id,
        data
      }, axiosConfig)
        .then(res => {
          if (res.data.success !== undefined) {
            const success = res.data.success
            this.$emit('changed', { success, savedFachThema })
            this.note.show = false
          }
        })
    },

    changeGrades (savedFachThema, success) {
      const myGradesFilter = e => Object.keys(e.myGrades).includes(savedFachThema)
      let newFachThema
      let id
      for (const grade of this.grades.filter(myGradesFilter)) {
        const fachThemaSplited = grade.myGrades[savedFachThema].fachThema.split('_')
        id = fachThemaSplited[0]
        const topic = fachThemaSplited[1].split(',')[1]
        const type = fachThemaSplited[1].split(',')[0]
        // if (grade.myGrades[savedFachThema].fachThema === savedFachThema) {
        if (success.gradeType !== undefined && success.topic !== undefined) {
          grade.myGrades[savedFachThema].fachThema = `${id}_${success.gradeType}, ${success.topic}`
        } else if (success.gradeType !== undefined) {
          grade.myGrades[savedFachThema].fachThema = `${id}_${success.gradeType},${topic}`
        } else if (success.topic !== undefined) {
          grade.myGrades[savedFachThema].fachThema = `${id}_${type}, ${success.topic}`
        }
        newFachThema = grade.myGrades[savedFachThema].fachThema
        grade.myGrades[newFachThema] = grade.myGrades[savedFachThema]
        delete grade.myGrades[savedFachThema]
      }
      const currentMetaIndex = this.allMetas.findIndex(e => e === savedFachThema)
      this.allMetas.splice(currentMetaIndex, 1, newFachThema)
      for (const type of ['HJ', 'EJ']) {
        const assocHeader = this.headers[`${id}_${type}`].find(e => e.value === savedFachThema)
        assocHeader.text = newFachThema.split('_')[1]
        assocHeader.value = newFachThema
      }
      this.applyCustomStyles()
    },

    getTagErster (which) {
      return getTagErster(which)
    },
    getTagZeugniseingabe (which) {
      return getTagZeugniseingabe(which)
    }
  }
}
</script>

<style scoped>
  .maxWidth {
    max-width: max-content;
  }
</style>
