'use strict'

import Vue from 'vue'
import axios from 'axios'

const _axios = axios.create()

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  }
)

// Plugin.install = function (Vue, _options) {
//   console.log(_options)
Plugin.install = function (Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        // console.log('in')
        return _axios
      }
    },
    $axios: {
      get () {
        // console.log('in$')
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
