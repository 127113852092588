<template>
  <v-tooltip bottom v-if="select.usesWeight">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-on="on"
        v-bind="attrs"
      >
        <v-slider
          min="10"
          v-model="select.primaryGradesWeight"
          :error-messages="select.error"
          :success-messages="select.success"
          :thumb-size="18"
          thumb-label
          max="90"
          style="max-width: 300px; width: 300px;"
          :disabled="!select.sliderEnabled"
          @change="e => setPrimaryGradesWeight(select, e)"
        >
          <template v-slot:prepend>
            <v-checkbox
              class="mt-0 pt-0"
              tabindex="-1"
              v-model="select.sliderEnabled"
            />
          </template>
          <template v-slot:append>
            <span style="width: 95px;" class="mt-1">
              {{ select.primaryGradesWeight }} <sub>A</sub> &divide; {{ 100 - select.primaryGradesWeight }} <sub>B</sub>
            </span>
          </template>
        </v-slider>
      </div>
    </template>
    Klicke auf das Kontrollkästchen und verschiebe den Schieberegler,<br>
    um das Gewicht der A-Noten dieses Fachs zu verändern.<br>
    {{ select.primaryGradesWeight }} <sub>A</sub> &divide; {{ 100 - select.primaryGradesWeight }} <sub>B</sub> &rArr; {{ select.primaryGradesWeight / 100 }} &times; &empty; <sub>A</sub> + {{ (100 - select.primaryGradesWeight) / 100 }} &times; &empty; <sub>B</sub>
  </v-tooltip>
  <v-tooltip bottom v-else-if="!select.usesWeight">
    <template v-slot:activator="{ on, attrs }">
      <div
        v-on="on"
        v-bind="attrs"
      >
        <span>
          {{ select.primaryGradesWeight }} <sub>A</sub> &divide; {{ 100 - select.primaryGradesWeight }} <sub>B</sub>
        </span>
      </div>
    </template>
    Das Gewicht der A-Noten kann nicht eingestellt werden,<br>
    die Schulleitung kann diese Funktion freigegeben.<br>
    {{ select.primaryGradesWeight }} <sub>A</sub> &divide; {{ 100 - select.primaryGradesWeight }} <sub>B</sub> &rArr; {{ select.primaryGradesWeight / 100 }} &times; &empty; <sub>A</sub> + {{ (100 - select.primaryGradesWeight) / 100 }} &times; &empty; <sub>B</sub>
  </v-tooltip>
</template>

<script>
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)

export default {
  props: {
    value: Object
  },

  // data () {
  //   return {
  //     // newNote: this.new,
  //     namenKopfnoten: ['Betragen', 'Mitarbeit', 'Fleiß', 'Ordnung']
  //   }
  // },

  computed: {
    select: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    async setPrimaryGradesWeight (select, e) {
      const url = `${config.api_prisma_set_weight}/${select.id}/${e}`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      select.error = 'lade hoch'
      try {
        const res = await this.$axios.get(url, axiosConfig)
        if (res.data.success) {
          // do stuff if not successfull uploaded
          clearTimeout(select.timeoutHandle)
          select.error = ''
          select.success = 'hochgeladen'
          select.timeoutHandle = setTimeout(() => { select.success = '' }, 5000)
        } else if (res.data.error) {
          select.error = 'nicht erlaubt!'
        }
      } catch (err) {
        select.error = 'nicht erlaubt!'
        console.error(err)
      }
    }
  }
}
</script>
