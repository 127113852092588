<template>
  <v-app v-if="$keycloak !== undefined && $keycloak.authenticated">
    <v-img
      alt="DPFA Regenbogenschulen Logo"
      src="./assets/dpfa-rebo-logo.svg"
      transition="scale-transition"
      class="shrink mr-2"
      contain
      :width="isPhone ? 100 : 150"
      :height="isPhone ? 50 : 75"
      style="position: absolute; left: 15px; top: 15px; z-index: 10"
    />
    <!-- style="position: absolute; z-index: 0" -->
    <!-- collapse-on-scroll -->
    <v-app-bar hide-on-scroll app color="primary" dark >
      <v-spacer/>
      <div v-if="!isPhone">

        <!-- <v-btn v-if="checkRole(['schueler'])" class="mr-1" name ="nw_grades_button">
          <router-link to="/showgradesprismas">{{ this.textValues.showgradesprismas }}</router-link>
        </v-btn> -->

        <v-tooltip bottom v-if="false && checkRole(['schueler'])">
          <template v-slot:activator="{ attrs, on }">
            <form
              method="post"
              entype="application/x-www-form-urlencoded"
              :action="portal.url"
              style="display: inline;"
              target="_blank"
              v-bind="attrs"
              v-on="on"
              >
              <input type="hidden" name="user" :value="portal.username" />
              <input type="hidden" name="pass" :value="portal.password" />
              <input type="hidden" name="submit" value="Anmelden" />
              <input type="hidden" name="logintype" value="login" />
              <input type="hidden" name="pid" value="343,340,335,336,342,337,341,339,338,343,340,335,336,342,337,341,339,338,333" />
              <input type="hidden" name="redirect_url" value="" />
              <input type="hidden" name="tx_felogin_pi1[noredirect]" value="0" />
              <v-btn type="submit" class="mr-3" small rounded color="#eee">
                <!-- <v-icon color=blue>mdi-bank-outline</v-icon> -->
                <!-- <v-img src="https://intranet.dpfa.de/typo3temp/pics/0f2035b383.png" width="16" height="16" /> -->
                <v-img src="https://intranet.dpfa.de/typo3conf/ext/intranet/Resources/Public/Images/logo.svg" width="16" height="16" />
              </v-btn>
            </form>
          </template>
          Öffne DPFA-Intranet in einem neuem Browserfenster.<br>
          Die automatische Anmeldung ist derzeit nur möglich,<br>
          wenn ich das für dich eingerichtet habe.<br>
        </v-tooltip>

        <v-tooltip bottom v-if="false && checkRole(['schulleiter', 'klassenlehrer', 'lehrer'])">
          <template v-slot:activator="{ attrs, on }">
            <form
              method="post"
              entype="application/x-www-form-urlencoded"
              :action="intranet.url"
              style="display: inline;"
              target="_blank"
              v-bind="attrs"
              v-on="on"
              >
              <input type="hidden" name="user" :value="intranet.username" />
              <input type="hidden" name="pass" :value="intranet.password" />
              <input type="hidden" name="permalogin" value="0" />
              <input type="hidden" name="submit" value="Anmelden" />
              <input type="hidden" name="logintype" value="login" />
              <input type="hidden" name="pid" value="16" />
              <input type="hidden" name="redirect_url" value="index.php?id=434" />
              <input type="hidden" name="tx_felogin_pi1[noredirect]" value="0" />
              <v-btn type="submit" class="mr-3" small rounded color="#eee">
                <!-- <v-img src="https://intranet.dpfa.de/typo3temp/pics/0f2035b383.png" width="16" height="16" /> -->
                <v-img src="https://intranet.dpfa.de/typo3conf/ext/intranet/Resources/Public/Images/logo.svg" width="16" height="16" />
              </v-btn>
            </form>
          </template>
          Öffne DPFA-Intranet in einem neuem Browserfenster.<br>
          Die automatische Anmeldung ist derzeit nur möglich,<br>
          wenn ich dein Kennwort eingerichtet habe.<br>
        </v-tooltip>

        <v-tooltip bottom v-if="false">
          <template v-slot:activator="{ attrs, on }">
            <form
              method="post"
              enctype="multipart/form-data"
              :action="lernsax.url"
              style="display: inline;"
              target="_blank"
              v-bind="attrs"
              v-on="on"
              >
              <input type="hidden" name="login_nojs" value=""/>
              <input type="hidden" name="login_login" :value="lernsax.username"/>
              <input type="hidden" name="login_password" :value="lernsax.password"/>
              <input type="hidden" name="language" value="2"/>
              <v-btn type="submit" class="mr-3" small rounded color="#eee">
                <!-- <v-icon>mdi-monitor</v-icon> -->
                <v-img src="https://www.lernsax.de/favicon.ico" width="16" height="16" />
              </v-btn>
            </form>
          </template>
          Öffne Lernsax in einem neuem Browserfenster.<br>
          Die automatische Anmeldung ist derzeit nur<br>
          möglich, wenn du dein Kennwort in der<br>
          Benutzerverwaltung abgespeichert hast.<br>
        </v-tooltip>

        <router-link v-if="inDevelopment() && checkRole(['schueler'])" to="/showgradesprismas">
          <v-icon color="white" class="mr-3">mdi-numeric-2-box-multiple-outline</v-icon>
        </router-link>

        <router-link v-if="inDevelopment() && checkRole(['schueler'])" to="/showtimetableprismas">
          <v-icon color="white" class="mr-3">mdi-calendar-outline</v-icon>
        </router-link>

        <v-menu v-if="checkRole(['admin'])" bottom left>
          <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTTip, attrs: attrsTTip }">
                <v-icon v-bind="{ ... attrsMenu, ...attrsTTip }" v-on="onTTip" @click="onMenu.click" class="mr-3">mdi-book-open-page-variant</v-icon>
              </template>
              hier kannst du:
              <ul>
                <li>leider noch nichts machen ;(</li>
                <li>work in progress !!!</li>
              </ul>
            </v-tooltip>
          </template>

          <v-list>
            <v-list-item v-if="checkRole(['klassenlehrer'])" >
              <router-link to="/changeseatingplankl">
                <v-icon color="primary" class="mr-3">mdi-seat-outline</v-icon>
                Sitzplan
              </router-link>
            </v-list-item>
            <v-list-item v-if="checkRole(['schulleiter', 'klassenlehrer', 'lehrer'])" >
              <router-link to="/changeattandancefl">
                <v-icon color="primary" class="mr-3">mdi-account-check-outline</v-icon>
                Anwesenheit
              </router-link>
            </v-list-item>
            <v-list-item v-if="checkRole(['schulleiter', 'klassenlehrer', 'lehrer'])">
              <!-- <router-link to="/addheadgradeskl"> -->
                <v-icon class="mr-3">mdi-message-bulleted</v-icon>
                Bemerkungen
              <!-- </router-link> -->
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <router-link to="" class="mr-1">
          <v-icon>mdi-account-check-outline</v-icon>
        </router-link> -->

        <!-- <router-link to="" class="mr-1">
          <v-icon>mdi-message-bulleted</v-icon>
        </router-link> -->

        <v-menu v-if="checkRole(['schulleiter', 'klassenlehrer', 'lehrer'])" bottom left>
          <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
            <!-- <v-icon v-bind="attrs" @mouseenter="showSchool = true" @click="on.click" class="mr-3">mdi-school</v-icon> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTTip, attrs: attrsTTip }">
                <v-icon tabindex=-1 v-bind="{ ... attrsMenu, ...attrsTTip }" v-on="onTTip" @click="onMenu.click" class="mr-3">mdi-school</v-icon>
              </template>
              hier kannst du:
              <ul v-if="checkRole(['lehrer'])"> Noten (Fachlehrer)
                <li>neue Fachnoten definieren</li>
                <li>Fachnoten eintragen</li>
                <li>Fachnoten ändern</li>
                <li>Fachnotenübersicht aller deiner Fächer ansehen</li>
                <li>HJ Noten eintragen</li>
              </ul>
              <ul v-if="checkRole(['klassenlehrer'])"> Übersicht (Klassenlehrer)
                <li>Übersicht der Einzelnoten deiner Klasse in jedem Fach ansehen</li>
                <li>Gesamtübersicht der Notendurchschnitte deiner Klasse ansehen</li>
              </ul>
              <ul v-if="checkRole(['klassenlehrer'])"> Zeugnisdruck (Klassenlehrer)
                <li>Zeugnis deiner Klasse ansehen</li>
                <li>Kopfnoten und Fehltage deiner Klasse eintragen</li>
              </ul>
              <ul v-if="checkRole(['schulleiter'])"> Übersicht (Schulleiter)
                <li>Übersicht der Einzelnoten aller Klassen in jedem Fach ansehen</li>
                <li>Gesamtübersicht der Notendurchschnitte aller Klassen ansehen</li>
              </ul>
              <ul v-if="checkRole(['schulleiter'])"> Übersicht (Schulleiter)
                <li>Zeugnisse aller Schüler ansehen</li>
                <li>Kopfnoten und Fehltage aller Schüler eintragen</li>
              </ul>

            </v-tooltip>

          </template>
          <!-- <v-list @mouseleave="showSchool = false"> -->
          <v-list>
            <v-list-item v-if="checkRole(['lehrer'])" >
              <router-link to="/addgrades">
                <v-icon color="primary" class="mr-3">mdi-numeric-2-box-multiple-outline</v-icon>
                Noten
              </router-link>
            </v-list-item>
            <v-list-item v-if="checkRole(['schulleiter', 'klassenlehrer'])">
              <router-link to="/showgradeskl">
                <v-icon color="primary" class="mr-3">mdi-card-account-details-outline</v-icon>
                Klassenübersicht
              </router-link>
            </v-list-item>
            <v-list-item v-if="checkRole(['schulleiter', 'klassenlehrer'])">
              <router-link to="/reports">
                <v-icon color="primary" class="mr-3">mdi-newspaper-variant-outline</v-icon>
                Zeugnisdruck
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu v-if="checkRole(['admin'])" bottom left>
          <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
            <!-- <v-icon v-bind="attrs" @mouseenter="showSchool = true" @click="on.click" class="mr-3">mdi-school</v-icon> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTTip, attrs: attrsTTip }">
                <v-icon v-bind="{ ... attrsMenu, ...attrsTTip }" v-on="onTTip" @click="onMenu.click" class="mr-3">mdi-shield-crown</v-icon>
              </template>
              hier kannst du:
              <ul>unter Termine
                <li>Notenrelevante Termine eintragen</li>
              </ul>
              <ul>unter Wichtung
                <li>die Wichtung bei der Berechnung der Kopfnoten ändern</li>
                <li>die aktuelle Wochenstundenanzahl einsehen</li>
                <li>die Wichtung der Noten ändern</li>
              </ul>
            </v-tooltip>

          </template>
          <!-- <v-list @mouseleave="showSchool = false"> -->
          <v-list>
            <v-list-item>
              <router-link to="/showcalendar">
                <v-icon color="primary" class="mr-3">mdi-calendar</v-icon>
                Termine
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link to="/showcalculator">
                <v-icon color="primary" class="mr-3">mdi-calculator</v-icon>
                Wichtung
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <v-btn v-if="check('klassenlehrer')" class="mr-1" name ="nw_grades_button_4">
          <router-link to="/addheadgradeskl">{{ this.textValues.addHeadGradesKL }}</router-link>
        </v-btn> -->

        <!-- <v-btn v-if="check('dozenten')" class="mr-1" name ="nw_grades_button_3">
          <router-link to="/addheadgradesfl">{{ this.textValues.addHeadGradesFL }}</router-link>
        </v-btn> -->

        <!-- <v-btn v-if="check('dozenten')" class="mr-1" name ="nw_grades_button_2">
          <router-link to="/addgrades">{{ this.textValues.addGrades }}</router-link>
        </v-btn> -->

        <!-- <v-btn v-if="this.$store.state.isAuthenticated" class="mr-1">
          <router-link to="/user">{{ this.textValues.user }}: {{ this.$store.state.bez.split('@')[0] }}</router-link>
        </v-btn> -->

        <v-menu bottom left>
          <template v-slot:activator="{ attrs, on }">
            <v-avatar v-bind="attrs" v-on="on" size="32" color="white">
              <v-img v-if="avatar.ready && avatar.src !== ''" :src="`data:image/jpeg;base64,${avatar.src}`"/>
              <span v-if="avatar.ready && avatar.else !== ''" class="white primary--text" v-text="avatar.else"/>
            </v-avatar>
          </template>
          <v-list>
            <!-- <v-list-item @click="user"> -->
            <v-list-item>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a v-if="!isPlayground" href="https://testbed.meeting-dpfa.de/auth/realms/DPFA/account" target="_blank" v-bind="attrs" v-on="on" class="d-flex align-center justify-end">
                  <!-- <a href="http://10.100.191.14:8080/realms/Test_DPFA/account" target="_blank" v-bind="attrs" v-on="on" class="d-flex align-center justify-end"> -->
                  <!-- <a href="https://testbed.meeting-dpfa.de/auth/realms/Test_DPFA/account" target="_blank" v-bind="attrs" v-on="on" class="d-flex align-center justify-end"> -->
                    <v-avatar size="88" class="ml-4 mr-5 mt-5 mb-5" color="primary">
                      <v-img v-if="avatar.ready && avatar.src !== ''" :src="`data:image/jpeg;base64,${avatar.src}`"/>
                      <span v-if="avatar.ready && avatar.else !== ''" class="white primary--text" v-text="avatar.else"/>
                    </v-avatar>
                    {{ $keycloak.tokenParsed.name }}<br>
                    {{ $keycloak.tokenParsed.email }}<br>
                    Ich bin {{ getRolesAsString() }}
                  </a>
                  <a v-else-if="isPlayground" href="https://testbed.meeting-dpfa.de/test_auth/realms/Test_DPFA/account" target="_blank" v-bind="attrs" v-on="on" class="d-flex align-center justify-end">
                    <v-avatar size="88" class="ml-4 mr-5 mt-5 mb-5" color="primary">
                      <v-img v-if="avatar.ready && avatar.src !== ''" :src="`data:image/jpeg;base64,${avatar.src}`"/>
                      <span v-if="avatar.ready && avatar.else !== ''" class="white primary--text" v-text="avatar.else"/>
                    </v-avatar>
                    {{ $keycloak.tokenParsed.name }}<br>
                    {{ $keycloak.tokenParsed.email }}<br>
                    Ich bin {{ getRolesAsString() }}
                  </a>

                </template>
                Benutzer anpassen
              </v-tooltip>
            </v-list-item>
            <v-list-item v-if="checkRole(['lehrer', 'schulleiter', 'klassenlehrer'])">
              <v-switch
                v-model="showAvatars"
                :label="`Avatare ${showAvatars ? 'an' : 'aus'}`"
                @change="storeShowAvatars"
              />
            </v-list-item>
            <v-list-item>
              <a @click="toggleFullscreen()">
                <v-icon color="primary" class="mr-3">mdi-fullscreen</v-icon> Vollbildmodus
              </a>
            </v-list-item>
            <v-list-item>
              <a @click="logout">
                <v-icon color="primary" class="mr-3">mdi-logout</v-icon>
                {{ this.textValues.logout }}
              </a>
            </v-list-item>
            <template v-if="!status.odata">
              <v-divider/>
              <v-list-item>
                <v-icon class="ml-1" color="warning">mdi-alert</v-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ attrs, on }">
                    <v-icon
                      class="ml-1"
                      v-on="on"
                      v-bind="attrs"
                      color="error"
                    >mdi-database-off</v-icon>
                  </template>
                  ABES DB <span v-if="!status.odata">nicht</span> verfügbar
                </v-tooltip>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </div>

      <v-menu v-if="isPhone"
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- <v-list-item v-if="check('schueler')">
            <router-link to="/timetable">{{ this.textValues.timetable }}</router-link>
          </v-list-item> -->
          <!-- <v-list-item v-if="check('schueler')">
            <router-link to="/showgradesprismas">{{ this.textValues.showgradesprismas }}</router-link>
          </v-list-item> -->
          <!-- <v-list-item v-if="check('lehrer')">
            <router-link to="/addGrades">{{ this.textValues.addGrades }}</router-link>
          </v-list-item>
          <v-list-item v-if="check('lehrer')">
            <router-link to="/addHeadGradesFL">{{ this.textValues.addHeadGradesFL }}</router-link>
          </v-list-item>
          <v-list-item v-if="check('klassenlehrer')">
            <router-link to="/addHeadGradesKL">{{ this.textValues.addHeadGradesKL }}</router-link>
          </v-list-item> -->
          <v-list-item v-if="this.$store.state.isAuthenticated">
            <router-link to="/user">{{ this.textValues.user }}: {{ this.$store.state.bez.split('@')[0] }}</router-link>
          </v-list-item>
          <v-list-item v-if="this.$store.state.isAuthenticated">
            <a @click="logout" class="mr-1">{{ this.textValues.logout }}</a>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          </v-list-item>

        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)

export default {
  name: 'App',
  components: {},

  data: function () {
    return {
      isPlayground: process.env.VUE_APP_IS_PLAYGROUND === 'true',

      status: {},
      showAvatars: this.$store.getters.get_show_avatars,
      avatar: { src: '', ready: false, else: '', once: false },
      // showBook: false,
      // showSchool: false,
      textValues: {
        logout: 'Abmelden'
      },
      windowWidth: window.innerWidth,
      isPhone: window.innerWidth <= 480,
      lernsax: {
        url: `https://www.lernsax.de/wws/100001.php?sid=${this.getSid()}`,
        username: `${this.$keycloak.idTokenParsed.preferred_username}@gyzw-dpfa.lernsax.de`,
        password: this.$keycloak.idTokenParsed.lernsax_pw
      },
      intranet: {
        url: 'https://intranet.dpfa.de/index.php?id=429&no_cache=1',
        username: this.$keycloak.idTokenParsed.preferred_username,
        password: this.$keycloak.idTokenParsed.intranet_pw
      },
      portal: {
        url: 'https://portal.dpfa.de/',
        username: this.$keycloak.idTokenParsed.portal_un,
        password: this.$keycloak.idTokenParsed.portal_pw
      }
    }
  },

  watch: {
    windowWidth (newWidth) {
      this.isPhone = newWidth <= 480
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.getAvatar()
    const currentRoute = this.$router.history.current.name
    const currentPath = this.$router.history.current.fullPath
    // if (this.$router.history.current.matched.length === 0) {
    this.pushDefaultRoute(currentRoute, currentPath)
    // }
    this.initialize()
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    inDevelopment () {
      return process.env.VUE_APP_IS_TESTSERVER === 'true'
    },
    async initialize () {
      const url = config.api_status
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      const res = await this.$axios.get(url, axiosConfig)
      this.status = res.data
      this.$store.commit('set_status', this.status)
    },
    getRolesAsString () {
      let retString = 'Meine Rollen sind '
      const wantedRoles = ['schulleiter', 'klassenlehrer', 'lehrer', 'eltern', 'schueler']
      const roleArray = this.$keycloak.realmAccess.roles.filter(e => wantedRoles.includes(e))
      let counter = 0
      for (const role of roleArray) {
        let roleString
        if (role === 'schueler') roleString = 'Schüler'
        else roleString = `${role[0].toUpperCase()}${role.substring(1)}`
        if (counter === 0) retString = roleString
        else if (counter === roleArray.length - 1) retString = `${retString} und ${roleString}`
        else retString = `${retString}, ${roleString}`
        counter++
      }
      return retString
    },
    toggleFullscreen () {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
      }
    },

    storeShowAvatars () {
      this.$store.commit('set_show_avatars', this.showAvatars)
      // this.$router.replace('showgradesprismas')
      this.$router.go()
    },
    getSid () {
      const hex = '0123456789abcdef'
      let sid = ''
      for (let i = 0; i < 32; i++) {
        sid = `${sid}${Math.round(Math.random() * 10)}`
      }
      sid = `${sid}S`
      for (let i = 0; i < 8; i++) {
        sid = `${sid}${hex[Math.round(Math.random() * 16)]}`
      }
      return sid
    },
    async getAvatar () {
      // const url = `${config.api_get_avatar}${this.$keycloak.tokenParsed.abes_oid}`
      // return this.$axios.post(url, { jwt_token: this.$keycloak.token })
      const parsedToken = this.$keycloak.tokenParsed
      const oid = this.isPlayground ? parsedToken.preferred_username : parsedToken.abes_oid
      const url = `${config.api_get_avatar}/null/${oid}`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.get(url, axiosConfig)
        this.avatar = { ready: true, once: true, src: res.data.avatar, else: '' }
      } catch (err) {
        console.error(err)
        this.avatar = { ready: true, once: true, src: '', else: `${this.$keycloak.tokenParsed.given_name[0]}${this.$keycloak.tokenParsed.family_name[0]}` }
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    async logout () {
      this.avatar = { src: '', ready: false, else: '', once: false }
      await this.$keycloak.logout()
      this.$router.replace('/')
    },
    user () {
      this.$router.push('/user')
    },
    checkRole (roles) {
      // current dev del after start
      // switch v-if('admin') to v-if('schulleiter')
      // if (process.env.VUE_APP_IS_DEVEL === 'true' && roles.includes('admin')) {
      if (process.env.NODE_ENV === 'development' && roles.includes('admin')) {
        return this.$keycloak.hasRealmRole('schulleiter')
      }
      // current dev del after end
      let result = false
      for (const role of roles) {
        result |= this.$keycloak.hasRealmRole(role)
      }
      return result
    },
    pushDefaultRoute (currentRoute, currentPath) {
      if (this.$keycloak.hasRealmRole('lehrer') &&
        !(this.$keycloak.hasRealmRole('klassenlehrer') || this.$keycloak.hasRealmRole('schulleiter')) &&
        currentRoute !== 'AddGrades'
      ) {
        this.$router.push('/addgrades')
      } else if (this.$keycloak.hasRealmRole('schulleiter') &&
        (
          currentRoute !== 'ShowCalendar' ||
          currentRoute !== 'ShowCalculator'
        )
      ) {
        this.$router.push(currentPath)
      } else if ((this.$keycloak.hasRealmRole('klassenlehrer') || this.$keycloak.hasRealmRole('schulleiter')) &&
        this.$keycloak.hasRealmRole('lehrer') &&
        currentRoute !== 'AddGrades' &&
        currentRoute !== 'ShowGradesKL' &&
        currentRoute !== 'Reports'
      ) {
        this.$router.push('/showgradeskl')
      } else if (this.$keycloak.hasRealmRole('schueler') &&
        currentRoute !== 'ShowGradesPrismaS' &&
        currentRoute !== 'ShowTimetablePrismaS'
      ) {
        this.$router.push('/showgradesprismas')
      } else if (this.$keycloak.hasRealmRole('eltern') &&
        currentRoute !== 'ShowGradesPrismaE'
      ) {
        this.$router.push('/showgradesprismae')
      }
    }
  }
}
</script>

<style>
  html { overflow-y: auto }
</style>

<style scoped>
  a {
    text-decoration: inherit;
  }
</style>
