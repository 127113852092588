<template>
  <v-container v-if="$keycloak !== undefined && $keycloak.authenticated">
    <v-row>
      <v-col class="mt-4 mb-4">
        <v-card>
          <v-card-title class="d-flex justify-end" v-if="possibleSelections !== undefined">
            Notenübersicht
            <v-spacer />
            <SaveAllGradesButton
              :grades="grades"
              :allMetas="allMetas"
              :headers="headers"
              :selectionData="possibleSelections"
              :disabled="isLoading"
            />
            <v-select
              v-if="Object.keys(possibleSelections).length >= 1"
              v-model="select.school"
              hide-details
              :items="Object.keys(possibleSelections)"
              class="v-select-fach mr-3"
              label="Schule:"
              ref="select1"

              @change="setClasses"
            />
            <v-select
              v-if="possibleSelections[select.school] !== undefined &&
                Object.keys(possibleSelections[select.school]).length >= 1"
              v-model="select.class"
              hide-details
              :items="Object.keys(possibleSelections[select.school])"
              class="v-select-fach mr-3"
              :label="isBB() ? 'Jahrgang:' : 'Klasse:'"
              ref="select2"
              @change="setCourses"
            />
            <v-select
              v-if="possibleSelections[select.school] !== undefined &&
                possibleSelections[select.school][select.class] !== undefined &&
                possibleSelections[select.school][select.class].courses.length >= 1"
              v-model="select.course"
              hide-details
              :items="possibleSelections[select.school][select.class].courses"
              item-text="courseName"
              item-value="schoolClassCourseAbbr"
              class="v-select-fach mr-3"
              return-object
              label="Fach:"
              ref="select3"
              @change="getHeaders"
            />
          </v-card-title>
          <!-- <v-card-subtitle class="d-flex justify-end" v-if="select2.topic !== undefined">
            <v-btn
              class="mt-3"
              color="primary"
              @click="showChart = !showChart">
              <v-icon>mdi-chart-timeline-variant-shimmer</v-icon>
            </v-btn>
            <v-spacer />
          </v-card-subtitle> -->
          <div
            class="d-flex justify-center ml-1 mr-1 mt-1 mb-5"
            v-if="isLoading"
          >
          <!-- v-if="isLoading && studentAvatars.length > 0" -->
            <!-- <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="computeVal()"
              color="primary"
            >
              {{ computeVal().toFixed() }}%
            </v-progress-circular> -->
          </div>
          <v-skeleton-loader
            v-if="isLoading"
            type="table"
          ></v-skeleton-loader>
          <!-- v-if="isLoading && studentAvatars.length > 0" -->
          <!-- <div class="d-flex align-center justify-end ml-1 mr-1">

            <v-dialog
              v-model="showStudent"
              :max-width="isPhone ? '95%' : '80%'"
            >
              <v-card>
                <v-system-bar
                  color="primary"
                  window
                >
                  <v-spacer></v-spacer>
                  <v-icon @click="showStudent = false">mdi-close-box-outline</v-icon>
                </v-system-bar>
                <v-card-title class="text-h5">
                  Durchschnitt
                </v-card-title>
                <v-card-text>
                  Hier kommt das Chart rein
                </v-card-text>
                <v-card-actions>
                </v-card-actions>

              </v-card>
            </v-dialog>
          </div> -->
          <v-data-table
            v-if="!isLoading && select.class !== undefined && select.course !== undefined"
            name="nw_data_table"
            :height="windowHeight-330"
            fixed-header

            :items-per-page="isPhone ? 1 : -1"
            :footer-props="{
              itemsPerPageOptions: isPhone ? [1, -1] : [3, 6, -1],
              itemsPerPageText: 'Schüler pro Seite:',
              itemsperpagealltext: 'Jeder'
            }"
            :headers="currentHeader"
            :search="select.course.schoolClassCourseAbbr"
            :custom-filter="filterPerfectMatch"
            :items="grades"
            key="key"
            :sort-by="['lastName', 'firstName']"
            multi-sort
            :loading="isLoading"
            loading-text="Ich lade die Noten."
            no-data-text="Es wurden noch keine Noten eingetragen!"
            no-results="Es wurden keine Noten gefunden!"
          >

            <template v-for="meta in allMetas" v-slot:[getHeaderSlotName(meta)] = "{ header }">
              <MetaGradeHeader
                :key="meta.id"
                :header="header"
                :classOverview="header.overview"
                :classMedian="header.metaMedian"
                :classMedianColor="getColor(select.level, header.metaMedian)"
                :isSek2="isSek2()"
                :isBB="isBB()"
              />
            </template>

            <template v-slot:[`header.median`] = "{}" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <span>
                      Schüler &empty;
                    </span>
                  </div>
                </template>
                {{ select.course.weights.A * 100 }}<sub>A</sub> &divide; {{ select.course.weights.B * 100 }}<sub>B</sub>
              </v-tooltip>
            </template>

            <template v-slot:[`item.bild`] = "{ item }">
                <div
                  @dblclick="if (isHeadMaster()) $router.push({ name: 'ShowGradesPrismaSLExtension', params: { email: item.email } })"
                  >

                  <ProtectedAvatar
                    :key="item.id"
                    :sex="item.sex"
                    :vintage="item.vintage"
                    :oid="item.oid"
                    class="mt-1 mb-1"
                    size="40"
                    :random="isPlayground"
                    :style="isHeadMaster() ? 'cursor: pointer;' : ''"
                    showContacts
                  />
                </div>
            </template>

            <template v-for="course in possibleSelections[select.school][select.class].courses" v-slot:[`item.${course.schoolClassCourseAbbr}`] = "{ item }">
              <v-chip
                v-if="item !== undefined && item[course.schoolClassCourseAbbr] && item[course.courseAbbr] !== 'OView'"
                :key="course.schoolClassCourseAbbr"
                label
                :color="getColor(select.level, item[course.schoolClassCourseAbbr])"
                :text-color="getTextColor(select.level, item[course.schoolClassCourseAbbr])"
              >
                {{ Number(item[course.schoolClassCourseAbbr]).toFixed(1) }}
              </v-chip>
            </template>

            <template v-for="course in possibleSelections[select.school][select.class].courses" v-slot:[`item.${course.schoolClassCourseAbbrEJ}`] = "{ item }">
              <v-chip
                v-if="item !== undefined && item[course.schoolClassCourseAbbrEJ] && item[course.courseAbbrEJ] !== 'OView_EJ'"
                :key="course.schoolClassCourseAbbrEJ"
                label
                :color="getColor(select.level, item[course.schoolClassCourseAbbrEJ])"
                :text-color="getTextColor(select.level, item[course.schoolClassCourseAbbrEJ])"
              >
                {{ item[course.schoolClassCourseAbbrEJ] }}
              </v-chip>
            </template>

            <template v-slot:[`item.median`]="{ item }">
              <v-chip
                :color="getColor(select.level, item.median)"
                :text-color="getTextColor(select.level, item.median)"
              >
                {{ item.median }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProtectedAvatar from '../components/ProtectedAvatar'
import MetaGradeHeader from '../components/MetaGradeHeader'
import SaveAllGradesButton from '../components/SaveAllGradesButton'

const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)

const { getColor, getTextColor, getTooLong, shortenTopic } = require('../../handler/tableHandler')

export default {
  name: 'ShowGradesKL',
  components: { ProtectedAvatar, MetaGradeHeader, SaveAllGradesButton },
  data () {
    return {
      isPlayground: process.env.VUE_APP_IS_PLAYGROUND === 'true',

      showChart: false,
      showStudent: false,

      // classesCount: 1,
      isLoading: true,
      vintage: '',
      isPhone: window.innerWidth <= 480 || window.innerHeight <= 480,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      select: { isLocked: true },
      possibleSelections: {},
      savedSelect: undefined,
      currentHeader: [],
      headers: {},
      grades: [],
      allMetas: [],
      noGradeText: 'bisher keine Note eingetragen!'
    }
  },

  mounted () {
    this.initialize()
  },

  methods: {
    isBB (school) {
      const schoolAbbr = school !== undefined
        ? school.split(' ')[0]
        : this.select.school.split(' ')[0]
      return schoolAbbr === 'BFS' || schoolAbbr === 'FS' || schoolAbbr === 'LOG' || schoolAbbr === 'FOS'
    },
    isSek2 (level) {
      if (level) {
        return (level >= 11 && this.select.school.substring(0, 2) === 'GY') ||
          (level >= 12 && this.select.school.substring(0, 3) === 'BGY')
      }
      return (this.select.level >= 11 && this.select.school.substring(0, 2) === 'GY') ||
        (this.select.level >= 12 && this.select.school.substring(0, 3) === 'BGY')
    },
    isHeadMaster () {
      return this.checkRole('schulleiter')
    },
    checkRole (role) {
      return this.$keycloak.hasRealmRole(role)
    },

    /**
     * a custom-filter to get only exact match (search string GE does not match GE.*)
     */
    filterPerfectMatch (value, search) {
      return value !== null && value === search
    },

    // computeVal () {
    //   return 100 * (this.studentAvatars.length / (24 * this.classesCount))
    // },

    getHeaders () {
      this.currentHeader = []
      for (const header of this.headers[this.select.course.schoolClassCourseAbbr]) {
        const overview = {}
        if (header.overview !== undefined) {
          const forVars = { start: this.isSek2() ? 0 : 1, end: this.isSek2() ? 15 : 6 }
          for (let i = forVars.start; i <= forVars.end; i++) {
            overview[i] = header.overview[i]
          }
          this.currentHeader.push({
            backAt: header.backAt,
            filterable: false,
            metaMedian: header.metaMedian,
            testedAt: header.testedAt,
            teacher: header.teacher,
            text: header.text,
            value: header.value,
            overview
          })
        } else {
          this.currentHeader.push(header)
        }
      }
      this.applyCustomStyles()
      // this.select.key = `${this.select.school}_${this.select.course.schoolClassCourseAbbr}`
    },
    getTooLong (testedAt, backAt) {
      return getTooLong(testedAt, backAt)
    },
    shortenTopic (topic) {
      return shortenTopic(topic)
    },
    getItemSlotName (course) {
      const retValue = `item.${course.courseAbbr}`
      return retValue
    },
    getHeaderSlotName (meta) {
      const retValue = `header.${meta.gradeType},${meta.id}`
      return retValue
    },
    getTextColor (level, grade) {
      return getTextColor(level, grade)
    },
    getColor (level, grade) {
      return getColor(level, grade, this.noGradeText)
    },
    setClasses (e) {
      this.select.school = e
      this.setCourses()
    },
    setCourses (e) {
      if (e) this.select.class = e
      else this.select.class = Object.keys(this.possibleSelections[this.select.school])[0]
      this.select.level = this.possibleSelections[this.select.school][this.select.class].level
      this.select.course = this.possibleSelections[this.select.school][this.select.class].courses[0]
      this.getHeaders()
    },
    initialize () {
      const url = config.api_prisma_get_class
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      this.$axios.get(url, axiosConfig)
        .then(this.getData)
        .catch((err) => {
          console.error(err)
        })
    },
    async getData (res) {
      for (const aSchool of res.data) {
        const currentSchoolName = aSchool.name
        this.select.school = currentSchoolName
        this.possibleSelections[currentSchoolName] = {}
        // this.classesCount = aSchool.classes.length
        for (const aClass of aSchool.classes) {
          const vintage = aClass.vintage
          const level = aClass.level
          this.className = `${level}${vintage[vintage.length - 1]}`
          const courses = []
          let currentKlasse
          let currentLevel
          let currentKlassentyp
          for (const course of aClass.courses) {
            const trueCourseName = course.courseType.name
            const courseAbbr = course.courseType.abbr
            const courseName = trueCourseName.length <= 15 ? trueCourseName : courseAbbr
            const weights = { A: course.primaryGradesWeight / 100, B: (100 - course.primaryGradesWeight) / 100 }
            const students = []
            let first = true
            let schoolClassCourseAbbr
            let classOView
            for (const student of course.students) {
              const anotherVintage = student.class.vintage[student.class.vintage.length - 1]
              currentLevel = student.class.level
              currentKlassentyp = student.class.type.abbr
              const klasse = this.isBB(currentSchoolName) ? aClass.vintage : `${student.class.level}${anotherVintage}`
              currentKlasse = klasse
              schoolClassCourseAbbr = `${currentSchoolName}_${klasse}_${courseAbbr}`
              const schoolClassCourseAbbrEJ = `${currentSchoolName}_${klasse}_${courseAbbr}_EJ`
              classOView = `${currentSchoolName}_${klasse}_OView`
              if (!Object.keys(this.possibleSelections[currentSchoolName]).includes(klasse)) {
                this.possibleSelections[currentSchoolName][klasse] = { level, courses: [{ schoolClassCourseAbbr: classOView, courseAbbr: 'OView', trueCourseName: 'Überblick', courseName: 'Überblick', weights }] }
                if (currentLevel === 10 && currentKlassentyp === 'OS' && this.isReportTime10OS()) this.possibleSelections[currentSchoolName][klasse].courses.push({ schoolClassCourseAbbr: `${classOView}_EJ`, courseAbbr: 'OView_EJ', trueCourseName: 'Überblick_EJ', courseName: 'Überblick_EJ' })
                this.possibleSelections[currentSchoolName][klasse].courses.push({ schoolClassCourseAbbr, schoolClassCourseAbbrEJ, courseAbbr, trueCourseName, courseName, weights })
              } else if (first) {
                this.possibleSelections[currentSchoolName][klasse].courses.push({ schoolClassCourseAbbr, schoolClassCourseAbbrEJ, courseAbbr, trueCourseName, courseName, weights })
                this.possibleSelections[currentSchoolName][klasse].courses.sort((a, b) => {
                  return a.trueCourseName > b.trueCourseName ? 1 : -1
                })
                this.moveAtStartOfArray(this.possibleSelections[currentSchoolName][klasse].courses, 'Überblick')
                this.moveAtStartOfArray(this.possibleSelections[currentSchoolName][klasse].courses, 'Überblick_EJ')
              }

              students.push({
                id: student.id,
                oid: student.odataPersonOid,
                klasse,
                vintage: student.class.vintage,
                sex: student.sex,
                firstName: student.firstName,
                lastName: student.lastName,
                email: student.email
              })
              first = false
            }

            this.headers[schoolClassCourseAbbr] = [
              { text: '', value: 'bild', filterable: false, sortable: false },
              { text: 'KlasseFach', value: 'schoolClassCourseAbbr', filterable: true, sortable: false, align: ' d-none d-xs-table-cell' },
              { text: 'Name', value: 'lastName', filterable: false },
              { text: 'Vorname', value: 'firstName', filterable: false }
            ]

            const gradeMetas = []
            const gradeHeaders = { a: [], b: [] }
            const gradeReportHeaders = {}
            for (const gradeMeta of course.gradeMetas) {
              if (gradeMeta.grades.filter(e => e.value !== null).length > 0) {
                const testedAt = gradeMeta.testedAt
                const backAt = gradeMeta.backAt
                const teacher = gradeMeta.teacher
                const aMeta = {
                  id: gradeMeta.id,
                  gradeType: gradeMeta.gradeType,
                  topic: gradeMeta.topic
                }
                gradeMetas.push(aMeta)
                this.allMetas.push(aMeta)
                const header = {
                  text: `${gradeMeta.gradeType}, ${gradeMeta.topic}`,
                  value: `${gradeMeta.gradeType},${gradeMeta.id}`,
                  filterable: false,
                  testedAt,
                  backAt,
                  teacher
                }
                if (currentLevel === 10 && currentKlassentyp === 'OS' && gradeMeta.gradeType === 'EJ' && gradeMeta.topic === 'Jahresnote') gradeReportHeaders[gradeMeta.gradeType] = header
                else if ((gradeMeta.gradeType === 'HJ' || gradeMeta.gradeType === 'EJ') && gradeMeta.topic === 'Zeugnisnote') gradeReportHeaders[gradeMeta.gradeType] = header
                else if (gradeMeta.gradeType === 'A') gradeHeaders.a.push(header)
                else if (gradeMeta.gradeType === 'B') gradeHeaders.b.push(header)
                let overview = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
                if (this.isSek2(level)) {
                  overview = { 0: 0, ...overview, ...{ 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0 } }
                }
                const metaMedian = { sum: 0, count: 0, overview }
                for (const grade of gradeMeta.grades) {
                  if (grade.value !== null) {
                    const existingRow = courses.find(aCourse => {
                      // return aCourse.key === `${courseAbbr} ${grade.student.id}`
                      return aCourse.key === `${schoolClassCourseAbbr} ${grade.student.id}`
                    })
                    if (existingRow === undefined) {
                      const student = students.find(aStudent => aStudent.id === grade.student.id)

                      const dataRow = {
                        key: `${schoolClassCourseAbbr} ${grade.student.id}`,
                        schoolClassCourseAbbr,
                        id: student.id,
                        sex: student.sex,
                        vintage: student.vintage,
                        oid: student.oid,
                        firstName: student.firstName,
                        lastName: student.lastName,
                        email: student.email,
                        weights
                      }
                      const metaId = `${gradeMeta.gradeType},${gradeMeta.id}`
                      const value = grade.value
                      dataRow[metaId] = value
                      courses.push(dataRow)
                    } else {
                      const metaId = `${gradeMeta.gradeType},${gradeMeta.id}`
                      const value = grade.value
                      existingRow[metaId] = value
                    }
                    metaMedian.sum += grade.value
                    metaMedian.count++
                    metaMedian.overview[grade.value] += 1
                  }
                }
                if (metaMedian.count > 0) {
                  header.metaMedian = (metaMedian.sum / metaMedian.count).toFixed(2)
                  header.overview = metaMedian.overview
                } else header.metaMedian = this.noGradeText
              }
            }
            if (gradeMetas.length > 0) {
              this.headers[schoolClassCourseAbbr] = [...this.headers[schoolClassCourseAbbr], ...gradeHeaders.a, ...gradeHeaders.b]
              this.headers[schoolClassCourseAbbr].push(
                { text: '&empty;', value: 'median', filterable: false }
              )
              if (gradeReportHeaders.HJ !== undefined) this.headers[schoolClassCourseAbbr].push(gradeReportHeaders.HJ)
              if (gradeReportHeaders.EJ !== undefined) this.headers[schoolClassCourseAbbr].push(gradeReportHeaders.EJ)
            }
          }

          for (const course of courses) {
            this.grades.push(course)
          }

          const is10OS = currentLevel === 10 && currentKlassentyp === 'OS'

          this.addOverviewArray(courses)
          if (is10OS && this.isReportTime10OS()) this.addOverviewArrayEJ(courses)

          if (currentKlasse !== undefined) {
            this.addOverviewHeaders(currentSchoolName, currentKlasse, '')
            if (is10OS) this.addOverviewHeaders(currentSchoolName, currentKlasse, '_EJ')
          }
        }
      }
      if (res.data.length > 0) {
        this.select.school = Object.keys(this.possibleSelections)[0]
        this.select.class = Object.keys(this.possibleSelections[this.select.school])[0]
        this.select.level = this.possibleSelections[this.select.school][this.select.class].level
        this.select.course = this.possibleSelections[this.select.school][this.select.class].courses[0]
        // this.select.key = `${this.select.school}_${this.select.course.schoolClassCourseAbbr}`
        this.currentHeader = this.headers[this.select.course.schoolClassCourseAbbr]
      } else console.error('Du hast Schulleiterrechte in LDAP aber in Prisma bist du nicht in der Liste isHeadmaster der Schule.')
      this.isLoading = false
      this.applyCustomStyles()
    },
    isReportTime10OS () {
      return new Date() >= new Date('2023-06-01')
    },

    moveAtStartOfArray (anArray, name) {
      const foundOViewEJAt = anArray.findIndex(e => e.trueCourseName === name)
      if (foundOViewEJAt !== -1) {
        const theOViewEJ = anArray[foundOViewEJAt]
        anArray.splice(foundOViewEJAt, 1)
        anArray.unshift(theOViewEJ)
      }
    },

    addOverviewArray (courses) {
      const overviewArray = []
      for (const dataRow of courses) {
        const sum = { A: 0, B: 0 }
        const count = { A: 0, B: 0 }
        for (const key of Object.keys(dataRow)) {
          const parts = key.split(',')
          if (!isNaN(parts[1])) {
            sum[parts[0]] += dataRow[key]
            count[parts[0]]++
          }
        }
        if (count.A > 0 && count.B > 0) dataRow.median = (dataRow.weights.A * (sum.A / count.A) + dataRow.weights.B * (sum.B / count.B)).toFixed(2)
        else if (count.A > 0 && count.B === 0) dataRow.median = (sum.A / count.A).toFixed(2)
        else if (count.A === 0 && count.B >= 0) dataRow.median = (sum.B / count.B).toFixed(2)
        const schoolClassCourseAbbrSplitted = dataRow.schoolClassCourseAbbr.split('_')
        const schoolClassCourseAbbr = `${schoolClassCourseAbbrSplitted[0]}_${schoolClassCourseAbbrSplitted[1]}`
        const overviewObject = {
          key: `${schoolClassCourseAbbr}_OView ${dataRow.id}`,
          id: dataRow.id,
          sex: dataRow.sex,
          vintage: dataRow.vintage,
          oid: dataRow.oid,

          lastName: dataRow.lastName,
          firstName: dataRow.firstName,
          email: dataRow.email,
          schoolClassCourseAbbr: `${schoolClassCourseAbbr}_OView`
        }
        overviewObject[dataRow.schoolClassCourseAbbr] = dataRow.median
        const index = overviewArray.findIndex(overview => overview.key === overviewObject.key)
        if (index !== -1) {
          overviewArray[index][dataRow.schoolClassCourseAbbr] = overviewObject[dataRow.schoolClassCourseAbbr]
        } else {
          overviewArray.push(overviewObject)
        }
      }
      this.grades = this.grades.concat(overviewArray)
    },

    addOverviewArrayEJ (courses) {
      const overviewArray = []
      for (const dataRow of courses) {
        const schoolClassCourseAbbrSplitted = dataRow.schoolClassCourseAbbr.split('_')
        const schoolClassCourseAbbr = `${schoolClassCourseAbbrSplitted[0]}_${schoolClassCourseAbbrSplitted[1]}`
        const overviewObject = {
          key: `${schoolClassCourseAbbr}_OView_EJ ${dataRow.key.split(' ')[2]}`,
          id: dataRow.id,
          sex: dataRow.sex,
          vintage: dataRow.vintage,
          oid: dataRow.oid,

          lastName: dataRow.lastName,
          firstName: dataRow.firstName,
          email: dataRow.email,
          schoolClassCourseAbbr: `${schoolClassCourseAbbr}_OView_EJ`
        }
        const abbr = `${schoolClassCourseAbbr}_EJ`
        overviewObject[abbr] = this.getJahresnote(dataRow)
        const index = overviewArray.findIndex(overview => overview.key === overviewObject.key)
        if (index !== -1) {
          overviewArray[index][abbr] = overviewObject[abbr]
        } else {
          overviewArray.push(overviewObject)
        }
      }
      this.grades = this.grades.concat(overviewArray)
    },

    getJahresnote (dataRow) {
      const possibleKeys = Object.keys(dataRow).filter(e => e.substr(0, 2) === 'EJ').map(e => Number(e.substr(3, e.length)))
      for (const key of possibleKeys) {
        if (this.allMetas.findIndex(e => e.id === key && e.topic === 'Jahresnote') !== -1) return dataRow[`EJ,${key}`]
      }
    },

    addOverviewHeaders (currentSchoolName, currentKlasse, appendix) {
      this.headers[`${currentSchoolName}_${currentKlasse}_OView${appendix}`] = [
        { text: '', value: 'bild', filterable: false, sortable: false },
        { text: 'KlasseFach', value: 'schoolClassCourseAbbr', filterable: true, sortable: false, align: ' d-none d-xs-table-cell' },
        { text: 'Name', value: 'lastName', filterable: false },
        { text: 'Vorname', value: 'firstName', filterable: false }
      ]

      for (const course of this.possibleSelections[currentSchoolName][currentKlasse].courses) {
        if (course.courseAbbr !== 'OView' && course.courseAbbr !== 'OView_EJ') {
          const header = {
            text: `${course.courseAbbr}`,
            value: `${course.schoolClassCourseAbbr}${appendix}`,
            filterable: false
          }
          this.headers[`${currentSchoolName}_${currentKlasse}_OView${appendix}`].push(header)
        }
      }
    },

    applyCustomStyles () {
      this.$nextTick(() => {
        const customStyles = { '.v-select__selection.v-select__selection--comma': { overflow: 'unset' } }
        for (const vSelect of [this.$refs.select1, this.$refs.select2, this.$refs.select3]) {
          Object.entries(customStyles).forEach(([selector, styles]) => {
            Object.entries(styles).forEach(([style, value]) => {
              if (vSelect !== undefined && vSelect.$el.querySelector(selector) !== null) vSelect.$el.querySelector(selector).style[style] = value
            })
          })
        }
      })
    }

  }
}

</script>

<style scoped>
  .v-select-fach {
    max-width: min-content;
  }
  .v-select__selection.v-select__selection--comma {
    overflow: unset;
  }
</style>
