const isDeveloping = process.env.VUE_APP_IS_DEVEL === 'true'
const isPlayground = process.env.VUE_APP_IS_PLAYGROUND === 'true'

function getToday () {
  return new Date()
}

function getHJStart () {
  return new Date('2023-01-24')
}

function getTagErster (which) {
  if (which === 'HJ') return new Date('2022-08-08')
  else if (which === 'EJ') return new Date('2023-01-31')
}

function getTagZeugniseingabe (which) {
  if (which === 'HJ') return new Date('2023-01-17T00:00')
  else if (which === 'EJ') return new Date('2023-06-13T00:00')
}

function getTagNotenschluss (which) {
  if (which === 'HJ') return new Date('2023-01-31T23:59')
  else if (which === 'EJ') return new Date('2023-06-27T23:59')
}

function getTagZeugnisdruck (which) {
  if (which === 'HJ') return new Date('2023-02-10T12:00')
  else if (which === 'EJ') return new Date('2023-07-14T12:00')
}

function isCreateMetaDisabled (term) {
  return !isPlayground && getTagNotenschluss(term) < getToday()
}

function isChangeGradeDisabled ({ dateString, term }) {
  if (dateString !== undefined) {
    if (term === 'HJ') return new Date(dateString) >= getTagZeugnisdruck(term)
    else if (term === 'EJ') {
      return !isPlayground && new Date() >= getTagZeugnisdruck(term)
    }
  }
  const vintage = '2022/2023'
  return !isTimeForReportGrades({ term, vintage })
}

function isTimeForReportGrades ({ term, vintage }) {
  if (vintage === '2022/2023') {
    return isDeveloping || (term === 'EJ' && isPlayground) || (getTagZeugniseingabe(term) <= getToday() && getToday() < getTagZeugnisdruck(term))
  }
}

module.exports = {
  getToday,
  getHJStart,
  getTagErster,
  getTagNotenschluss,
  getTagZeugniseingabe,
  getTagZeugnisdruck,
  isCreateMetaDisabled,
  isChangeGradeDisabled,
  isTimeForReportGrades
}
