<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        class="mr-3"
        color="primary"
        @click="renderDocs"
        :disabled="disabled">
        <!-- @click="renderDocs" -->
        <v-icon>mdi-printer-outline</v-icon>
      </v-btn>
    </template>
    alle Noten speichern
  </v-tooltip>

        <!--
        <icon>mdi-file-word</icon>
        <icon>mdi-file-word-box</icon>
        <icon>mdi-file-word</icon>
        <icon>mdi-file-word-outline</icon>
        <icon>mdi-printer</icon>
        <icon>mdi-printer-outline</icon>
        <icon>mdi-fingerprint</icon>
        <icon>mdi-cloud-print</icon>
        -->
</template>

<script>
import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'
import { saveAs } from 'file-saver'

const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)

export default {
  props: {
    grades: Array,
    allMetas: Array,
    headers: Object,
    selectionData: Object,
    disabled: Boolean
  },
  methods: {
    getMetaData (gradeObj, additionalMetaData) {
      const filterGrades = function (e) {
        if (e.includes(',')) {
          const firstPart = e.split(',')[0]
          return ['A', 'B', 'HJ', 'EJ'].includes(firstPart)
        } else return false
      }
      const retArray = []
      for (const key of Object.keys(gradeObj).filter(filterGrades)) {
        const type = key.split(',')[0]
        const metaKey = key.split(',')[1]
        const topic = this.allMetas.find(e => e.id === Number(metaKey))?.topic
        const value = gradeObj[key]
        const foundMeta = additionalMetaData.find(e => e.text === `${type}, ${topic}`)
        const teacher = foundMeta.teacher
        const overview = foundMeta.overview
        const ueberblick = {}
        Object.keys(overview).forEach(e => { ueberblick[`a${e}`] = overview[e] })

        retArray.push({
          typ: type,
          thema: topic,
          wert: value,
          lehrer: `${teacher.lastName}, ${teacher.firstName}`,
          lehrerEmail: teacher.email,
          am: foundMeta.testedAt.split('T')[0],
          zurueck: foundMeta.backAt.split('T')[0],
          metaMedian: foundMeta.metaMedian,
          ...ueberblick
        })
      }
      return retArray
    },
    renderDocs () {
      // wholeStudent.docx
      PizZipUtils.getBinaryContent(config.staticSaveAllGrades, this.printCallback)
    },

    printCallback (error, content) {
      if (error) {
        throw error
      }

      const zipAll = new PizZip()
      let place
      for (const schoolName of Object.keys(this.selectionData)) {
        const school = this.selectionData[schoolName] // 'GY Zwickau'
        place = schoolName.split(' ')[1]
        for (const myClassName of Object.keys(school)) { // '10a'
          const myClass = school[myClassName]
          for (const course of myClass.courses.filter(e => e.courseAbbr !== 'OView')) {
            // for (const student of course.students ?? []) {
            const fullAbbr = course.schoolClassCourseAbbr
            const filterGrades = function (e) {
              return e.schoolClassCourseAbbr === fullAbbr
            }
            for (const student of this.grades.filter(filterGrades) ?? []) {
              const zip = new PizZip(content)
              const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true })

              doc.setData({
                schule: schoolName,
                klasse: myClassName,
                fach: course.courseName,
                istSek2: ['GY ', 'BGY'].includes(schoolName.substring(0, 3)) && myClass.level >= 11,
                ...this.getData2(student, this.headers[fullAbbr].filter(e => e.teacher !== undefined))
              })
              try {
                doc.render()
              } catch (err) {
                handleError(err)
                throw error
              }
              const out = doc.getZip().generate({
                type: 'arraybuffer',
                compression: 'DEFLATE',
                mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              })
              // // https://stackoverflow.com/questions/64345139/how-do-i-create-a-zip-file-with-multiple-docx-files-generated-by-the-docxtempla
              // // Output the document using Data-URI
              let filename
              // if (fullAbbr !== undefined && student.lastName !== undefined && student.firstName !== undefined) {
              if (student.lastName !== undefined && student.firstName !== undefined) {
                const firstName = student.firstName.toLowerCase()
                const name = student.lastName.toLowerCase()
                filename = `${name}_${firstName}`
              } else {
                filename = 'output'
              }
              zipAll.folder(schoolName).folder(myClassName).folder(course.courseName).file(`${filename}.docx`, out)
            }
          }
        }
      }

      const outAll = zipAll.generate({ type: 'blob', compression: 'DEFLATE' })
      saveAs(outAll, `backup_${place}_${getWeekNumber(new Date())}.zip`)
    },
    getData2 (student, additionalMetaData) {
      const gradesArray = this.getMetaData(student, additionalMetaData)
      return {
        vorname: student.firstName,
        name: student.lastName,
        grades: gradesArray,
        median: student.median,
        gewichtA: 100 * student.weights.A,
        gewichtB: 100 * student.weights.B
      }
    }
  }
}

function getWeekNumber (date) {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  const dayNum = d.getUTCDay() || 7
  d.setUTCDate(d.getUTCDate() + 4 - dayNum)
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
}

function replaceErrors (key, value) {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(function (
      error,
      key
    ) {
      error[key] = value[key]
      return error
    },
    {})
  }
  return value
}

function handleError (error) {
  // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
  console.log(JSON.stringify({ error: error }, replaceErrors))

  if (error.properties && error.properties.errors instanceof Array) {
    const errorMessages = error.properties.errors
      .map(function (error) {
        return error.properties.explanation
      })
      .join('\n')
    console.log('errorMessages', errorMessages)
    // errorMessages is a humanly readable message looking like this :
    // 'The tag beginning with "foobar" is unopened'
  }
}

</script>
