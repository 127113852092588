<template>
  <v-container v-if="$keycloak !== undefined && $keycloak.authenticated">
    <v-row>
      <v-col class="mt-4 mb-4">
        <v-card>
          <v-card-title>
            <span class="mr-3">
            Zensuren
            </span>
            <v-select
              v-model="currentStudent"
              style="max-width: 100px"
              :items="students"
              item-text="firstName"
              item-value="id"
              :value="currentStudent"
              autofocus
              return-object
            ></v-select>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suche"
              single-line
              hide-details
            >
            </v-text-field>
          </v-card-title>
          <v-data-table
            name="nw_data_table"
            fixed-header
            :height="windowHeight - heightOffset"
            :headers="currentStudent.headers"
            :items="currentStudent.grades"
            :search="search"
            item-key="name"
            sort-by="name"
            :loading="isLoading"
            :footer-props="{
              itemsPerPageOptions: isPhone ? [1, -1] : [-1],
            }"
          >
            <template v-slot:[`header.e`] = "{ header }">
               {{ header.text }}
               &empty;
            </template>
            <template v-for="type in ['A', 'B', 'HJ', 'EJ']" v-slot:[`item.${type}`] = "{ item }">
              <GradeRow
                :key="`${item.name}_${item[type]}`"
                :grades="item[type]"
                :isSek2="currentStudent.isSek2"
              />
            </template>
            <template v-slot:[`item.e`] = "{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-on="on" v-bind="attrs" :color="getColor(item.e)" :dark="getDark(item.e)">
                    {{ item.e }}
                  </v-chip>
                </template>
                A<sub>{{ item.gradesWeight.A }}</sub> &divide; B<sub>{{ item.gradesWeight.B }}</sub>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GradeRow from '../components/GradeRow'
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)
const { getColor, getDark } = require('../../handler/tableHandler')

export default {
  name: 'ShowGradesPrismaE',
  components: { GradeRow },

  data () {
    return {
      isLoading: true,
      search: '',
      heightOffset: 330,
      defaultHeaders: [
        { text: 'Fach', value: 'name' },
        { text: 'A-Noten', value: 'A', filterable: false },
        { text: 'B-Noten', value: 'B', filterable: false },
        { text: '', value: 'e', filterable: false },
        { text: 'HJ', value: 'HJ', filterable: false },
        { text: 'EJ', value: 'EJ', filterable: false }
      ],
      windowHeight: window.innerHeight,
      isPhone: false,
      students: [],
      currentStudent: {}
    }
  },

  mounted () {
    this.initialize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  methods: {
    onResize () {
      // this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    },
    getColor (grade) {
      return getColor(grade)
    },
    getDark (grade) {
      return getDark(grade)
    },
    async initialize () {
      const url = config.api_prisma_get_parent
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.get(url, axiosConfig)
        for (const student of res.data) {
          const isSek2 = student.isSek2
          const myStudent = { id: student.id, isSek2, firstName: student.firstName, lastName: student.lastName, courses: [], grades: [], hasZeugnisNoten: { hj: false, ej: false }, headers: [...this.defaultHeaders] }
          this.addExternalGradesToDataRow(student.grades.externalGradesArray, myStudent)
          this.addGradesToDataRow(student.grades.gradesArray, myStudent)
          this.createCurrentDataRowMedian(myStudent)
          this.removeUnnecessaryHeaders(myStudent)
          this.students.push(myStudent)
        }
        this.currentStudent = this.students[0]
        this.isLoading = false
      } catch (err) {
        console.error(err)
      }
    },

    addExternalGradesToDataRow (externalGradesArray, myStudent) {
      for (const exGrade of externalGradesArray) {
        if (exGrade.value !== null) {
          let dataRow = {}
          const name = exGrade.course.courseType.name
          if (!myStudent.courses.includes(name)) {
            myStudent.courses.push(name)
            dataRow = { name, A: [], B: [], HJ: [], EJ: [] }
            dataRow[exGrade.gradeType].push({ key: `Ex_${exGrade.id}`, value: exGrade.value, note: exGrade.note })
            myStudent.grades.push(dataRow)
          } else {
            dataRow = myStudent.grades.find(row => row.name === name)
            dataRow[exGrade.gradeType].push({ key: `Ex_${exGrade.id}`, value: exGrade.value, note: exGrade.note })
          }
        }
      }
    },
    addGradesToDataRow (gradesArray, myStudent) {
      for (const grade of gradesArray) {
        let dataRow = {}
        const gradeType = grade.gradeMeta.gradeType
        const name = grade.gradeMeta.course.courseType.name
        const primaryGradesWeight = grade.gradeMeta.course.primaryGradesWeight
        const gradesWeight = { A: primaryGradesWeight / 100, B: (100 - primaryGradesWeight) / 100 }
        if (grade.value !== null) {
          if (!myStudent.courses.includes(name)) {
            myStudent.courses.push(name)
            dataRow = { name, gradesWeight, A: [], B: [], HJ: [], EJ: [] }
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
            myStudent.grades.push(dataRow)
          } else {
            dataRow = myStudent.grades.find(row => row.name === name)
            if (dataRow.gradesWeight === undefined) dataRow.gradesWeight = gradesWeight
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
          }
        }
        if ((gradeType === 'EJ' || gradeType === 'HJ') && grade.value === null && grade.note === 'teilgenommen') {
          if (!myStudent.courses.includes(name)) {
            dataRow = { name, gradesWeight, A: [], B: [], HJ: [], EJ: [] }
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
            myStudent.grades.push(dataRow)
          } else {
            dataRow = myStudent.grades.find(row => row.name === name)
            if (dataRow.gradesWeight === undefined) dataRow.gradesWeight = gradesWeight
            dataRow[gradeType].push({ key: grade.id, value: grade.value, note: grade.note, meta: grade.gradeMeta })
          }
        }
        if (gradeType === 'HJ') myStudent.hasZeugnisNoten.hj |= true
        if (gradeType === 'EJ') myStudent.hasZeugnisNoten.ej |= true
      }
    },
    createCurrentDataRowMedian (myStudent) {
      for (const course of myStudent.courses) {
        const median = { sumA: 0, counterA: 0, sumB: 0, counterB: 0 }
        const dataRow = myStudent.grades.find(row => row.name === course)
        for (const aAGrade of dataRow.A) {
          median.sumA += aAGrade.value
          median.counterA++
        }
        for (const aBGrade of dataRow.B) {
          median.sumB += aBGrade.value
          median.counterB++
        }
        const medianA = isNaN(median.sumA / median.counterA) ? 0 : median.sumA / median.counterA
        const medianB = isNaN(median.sumB / median.counterB) ? 0 : median.sumB / median.counterB
        if (medianA === 0) dataRow.e = medianB.toFixed(2)
        if (medianB === 0) dataRow.e = medianA.toFixed(2)
        if (medianA !== 0 && medianB !== 0) dataRow.e = (dataRow.gradesWeight.A * medianA + dataRow.gradesWeight.B * medianB).toFixed(2)
      }
    },
    removeUnnecessaryHeaders (myStudent) {
      for (const term of ['hj', 'ej']) {
        if (!myStudent.hasZeugnisNoten[term]) {
          const headerIndex = myStudent.headers.findIndex(e => e.text === term.toUpperCase())
          myStudent.headers.splice(headerIndex, 1)
        }
      }
    }
  }
}

</script>

<style scoped>
  span.pointer { cursor: default; }
</style>
