import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/lib/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#72ab12',
        success: '#72ab12'
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
})
