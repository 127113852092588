<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <span>
          {{ header.text }}
        </span>
      </div>
    </template>
    <GradeOverviewTable v-if="componetType === 'grade'"
      componentType="head"
      :classOverview="classOverview"
      :classMedian="classMedian"
      :classMedianColor="classMedianColor"
    />
    <HeadGradeInfo v-if="componetType === 'info'"
      :whichHead="header.value"
    />

  </v-tooltip>
</template>

<script>
import GradeOverviewTable from '../components/GradeOverviewTable'
import HeadGradeInfo from '../components/HeadGradeInfo'

export default {
  name: 'HeadGradeHeader',
  components: { HeadGradeInfo, GradeOverviewTable },

  props: {
    header: Object,
    componetType: String,
    classOverview: Object,
    classMedian: String,
    classMedianColor: String
  }
}
</script>
