var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$keycloak !== undefined && _vm.$keycloak.authenticated)?_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-4 mb-4"},[_c('v-card',[_c('v-card-title',[(!_vm.isLoading)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'ShowGradesPrismaKL' })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}],null,false,2066734080)},[_vm._v(" gehe zurück zur Klassenübersicht ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.fullName)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-subtitle',[(!_vm.isLoading)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"primary","disabled":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1527359522)},[_vm._v(" importiere externe Noten ")]):_vm._e(),(!_vm.isLoading)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.showFileInput = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]}}],null,false,3961006958)},[_vm._v(" importiere externe Noten ")]):_vm._e()],1),_c('div',{staticClass:"d-flex align-center justify-end ml-1 mr-1"},[_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showFileInput),callback:function ($$v) {_vm.showFileInput=$$v},expression:"showFileInput"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" externe Noten importieren ")]),_c('v-card-text',[_c('v-file-input',{attrs:{"accept":".xlsx","truncate-length":"30","rules":[function () {
                    if (this$1.file !== null && !_vm.isXLSX()) { return 'only .xlsx supported' }
                    else { return true }
                  }]},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('v-spacer')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":this.file === null || !_vm.isXLSX(),"icon":""},on:{"click":_vm.setExternalGrades}},[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"name":"nw_data_table","fixed-header":"","height":_vm.windowHeight - _vm.heightOffset,"headers":_vm.headers,"items":_vm.grades,"search":_vm.search,"item-key":"name","sort-by":"name","loading":_vm.isLoading,"footer-props":{
            itemsPerPageOptions: _vm.isPhone ? [1, -1] : [-1],
          }},scopedSlots:_vm._u([{key:"header.e",fn:function(ref){
          var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ∅ ")]}},_vm._l((['A', 'B', 'HJ', 'EJ']),function(type){return {key:("item." + type),fn:function(ref){
          var item = ref.item;
return [_c('GradeRow',{key:((item.name) + "_" + (item[type])),attrs:{"grades":item[type],"isSek2":_vm.isSek2}})]}}}),{key:"item.e",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.getColor(item.e),"dark":_vm.getDark(item.e)}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.e)+" ")])]}}],null,true)},[_vm._v(" A"),_c('sub',[_vm._v(_vm._s(item.gradesWeight.A))]),_vm._v(" ÷ B"),_c('sub',[_vm._v(_vm._s(item.gradesWeight.B))])])]}}],null,true)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }