<template>
  <div>
    <SingleGradeSquare
      v-for="grade in grades"
      :key="grade.key"
      :grade="grade"
      :isSek2="isSek2"
    />
  </div>
</template>

<script>
import SingleGradeSquare from '../components/SingleGradeSquare'

export default {
  name: 'GradeRow',
  components: { SingleGradeSquare },

  props: {
    grades: Array,
    isSek2: Boolean
  }
}
</script>
