<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center mt-4 mb-4">
        <h1>
          Notentermine
          <!-- TODO FE(App load Dates via Prisma not tableHandler.getTagZeugniseingabe, ...) -->
        </h1>
      </v-col>
    </v-row>

    <v-row v-for="school in schools" :key="school.name">
      <v-card width="100%" class="mt-20 mb-20">
      <v-row>
        <v-col class="d-flex justify-center mt-4 mb-4">
        <h2 v-if="isReady">
          {{ school.name }}
        </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 12 : 3" class="ml-3 mr-3">
              <v-row>
                <v-col class="d-flex justify-center">
                  <h3>
                    erstes Halbjahr 2022/2023
                  </h3>
                </v-col>
              </v-row>
              <v-row v-if="!isReady" >
                <v-col md="2" cols="12" class="d-flex justify-center" v-for="i in 6" :key="`row1_skel${i}`">
                  <v-skeleton-loader type="table-cell" class="d-flex justify-center">
                  </v-skeleton-loader>
                </v-col>
              </v-row>
              <v-row v-if="isReady">
                <v-col md="2" cols="12" :key="`${school.name}_${which.name}`"
                  class="d-flex justify-center"
                  v-for="which in school.calendarEntrys.filter(e => e.term === 'HJ')"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-on="on"
                        v-bind="attrs">
                        <v-dialog
                          :ref="`${school.name}_${which.name}`"
                          v-model="which.show"
                          :return-value.sync="which.date"
                          persistent
                          width="300px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="which.datum"
                              append-icon="mdi-calendar"
                              readonly
                              :success-messages="which.success"
                              :error-messages="which.error"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <template #label>
                                <label>{{ which.text }}</label>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="which.date"
                            first-day-of-week="1"
                            show-week
                            :allowed-dates="allowedDatesHJ"
                            scrollable
                            :min="dateRange.HJ.firstDay"
                            :max="dateRange.HJ.lastDay"
                          >
                            <v-spacer></v-spacer>
                            <v-btn color="error" icon @click="() => cancel(which)" :disabled="which.isSending">
                              <v-icon>mdi-cancel</v-icon>
                            </v-btn>
                            <v-btn color="success" icon @click="() => clicked({ school: school.name, which })" :disabled="which.isSending">
                              <v-icon>mdi-check</v-icon>
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </template>
                    <span v-html="which.tooltip"></span>
                  </v-tooltip>

                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-hover>

        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card :elevation="hover ? 12 : 3" class="ml-3 mt-10 mr-3 mb-10" >
              <v-row>
                <v-col class="d-flex justify-center">
                  <h3>
                    zweites Halbjahr 2022/2023
                  </h3>
                </v-col>
              </v-row>
              <v-row v-if="!isReady" >
                <v-col md="2" cols="12" class="d-flex justify-center" v-for="i in 6" :key="`row2_skel${i}`">
                  <v-skeleton-loader type="table-cell" class="d-flex justify-center">
                  </v-skeleton-loader>
                </v-col>
              </v-row>
              <v-row v-if="isReady">
                <v-col md="2" cols="12" :key="`${school.name}_${which.name}`"
                  class="d-flex justify-center"
                  v-for="which in school.calendarEntrys.filter(e => e.term === 'EJ')"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-on="on"
                        v-bind="attrs">
                        <v-dialog
                          :ref="`${school.name}_${which.name}`"
                          v-model="which.show"
                          :return-value.sync="which.date"
                          persistent
                          width="300px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="which.datum"
                              append-icon="mdi-calendar"
                              readonly
                              :success-messages="which.success"
                              :error-messages="which.error"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <template #label>
                                <label>{{ which.text }}</label>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="which.date"
                            first-day-of-week="1"
                            show-week
                            :allowed-dates="allowedDatesEJ"
                            scrollable
                            :min="dateRange.EJ.firstDay"
                            :max="dateRange.EJ.lastDay"
                          >
                            <v-spacer></v-spacer>
                            <v-btn color="error" icon @click="() => cancel(which)" :disabled="which.isSending">
                              <v-icon>mdi-cancel</v-icon>
                            </v-btn>
                            <v-btn color="success" icon @click="() => clicked({ school: school.name, which })" :disabled="which.isSending">
                              <v-icon>mdi-check</v-icon>
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </template>
                    <span v-html="which.tooltip"></span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-hover>
        </v-col>
      </v-row>
      </v-card>
    </v-row>

  </v-container>
</template>
<script>

const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)

export default {
  name: 'ShowCalendar',
  data: function () {
    return {
      isReady: false,
      dateRange: {
        HJ: { firstDay: '2022-08-29', lastDay: '2023-02-10' },
        EJ: { firstDay: '2023-02-27', lastDay: '2023-07-07' }
      },
      schools: {}
    }
  },
  mounted: function () {
    this.initialize()
  },
  computed: {
    computedAllowedDatesArrayHJ: function () {
      return this.getAllowedDatesArray(this.dateRange.HJ.firstDay, this.dateRange.HJ.lastDay)
    },
    computedAllowedDatesArrayEJ: function () {
      return this.getAllowedDatesArray(this.dateRange.EJ.firstDay, this.dateRange.EJ.lastDay)
    }
  },
  methods: {
    // timeout (ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms))
    // },
    // async sleep (fn, ...args) {
    //   await this.timeout(5000)
    //   return fn(...args)
    // },
    initialize () {
      const url = `${config.api_calendar_get_entry}/all/all`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      this.$axios.get(url, axiosConfig)
        .then(this.getData)
        .catch((err) => {
          console.error(err)
        })
    },
    getData (res) {
      this.schools = res.data
      for (const school of this.schools) {
        for (const entry of school.calendarEntrys) {
          const aDate = entry.date.split('T')[0]
          this.$set(entry, 'date', aDate)
          this.$set(entry, 'datum', this.translateDate(aDate))

          const lengthName = entry.name.length
          this.$set(entry, 'term', entry.name.substring(lengthName - 2, lengthName))

          const textTooltipObj = this.getTextTooltipObj(entry.name)
          this.$set(entry, 'text', textTooltipObj.text)

          this.$set(entry, 'tooltip', textTooltipObj.tooltip)
          this.$set(entry, 'show', false)
          this.$set(entry, 'success', '')
          this.$set(entry, 'error', '')
          this.$set(entry, 'isSending', false)
        }
      }
      this.isReady = true
    },
    async sendDate (id, date) {
      console.log(`send id: ${id}, date: ${date} via axios`)
      const url = `${config.api_calendar_set_entry}/${id}/${date}`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      return await this.$axios.get(url, axiosConfig)
        .then(res => {
          return res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    cancel (which) {
      which.show = false
    },
    async clicked ({ school, which }) {
      if (!which.isSending) {
        which.isSending = true
        which.error = 'lade hoch'

        try {
          // const success = await this.sleep(this.sendDate, which)
          const result = await this.sendDate(which.id, which.date)
          if (result?.success) {
            clearTimeout(which.timeoutHandle)
            which.error = ''
            which.success = 'hochgeladen'
            which.timeoutHandle = setTimeout(() => { which.success = '' }, 5000)
            const refName = `${school}_${which.name}`
            this.$refs[refName][0].save(which.date)
            which.datum = this.translateDate(which.date)
          } else {
            which.show = false
            which.error = 'nicht erlaubt!'
          }
          which.isSending = false
        } catch (err) {
          which.error = 'Server down!'
          console.error(err)
        }
      }
    },
    translateDate (date) {
      return new Date(date).toLocaleString().split(',')[0]
    },
    allowedDatesHJ (date) {
      return this.computedAllowedDatesArrayHJ.includes(date)
    },
    allowedDatesEJ (date) {
      return this.computedAllowedDatesArrayEJ.includes(date)
    },
    getTextTooltipObj (which) {
      if (which === 'firstDayGradesHJ' || which === 'firstDayGradesEJ') {
        return {
          text: 'Fachnoten ab:',
          tooltip: 'Der erste Tag, ab dem Fachnoten eingetragen werden können.'
        }
      } else if (which === 'lastDayGradesHJ' || which === 'lastDayGradesEJ') {
        return {
          text: 'Fachnoten bis:',
          tooltip: 'Der letzte Tag, bis zu dem Fachnoten eingetragen werden können.'
        }
      } else if (which === 'firstDayReportHJ' || which === 'firstDayReportEJ') {
        return {
          text: 'Zeugnisnoten ab:',
          tooltip: 'Der erste Tag, ab dem Zeugnisnoten für Fächer eingetragen werden können.'
        }
      } else if (which === 'lastDayHeadGradesHJ' || which === 'lastDayHeadGradesEJ') {
        return {
          text: 'Kopfnoten Fach bis:',
          tooltip: 'Der letzte Tag, bis zu dem Kopfnoten für Fächer eingetragen werden können.'
        }
      } else if (which === 'lastDayHeadGradesKLHJ' || which === 'lastDayHeadGradesKLEJ') {
        return {
          text: 'Kopfnoten Zeugnis bis:',
          tooltip: 'Der letzte Tag, bis zu dem die Kopfnoten der Klassenlehrer eingetragen werden können.'
        }
      } else if (which === 'printReportDayHJ' || which === 'printReportDayEJ') {
        return {
          text: 'Zeugnisdruck:',
          tooltip: 'Der Tag, an dem die Zeugnisse gedruckt werden.<br>Bis zu einem Tag vorher können Zeugnisnoten eingetragen werden.'
        }
      }
    },

    /**
     * @param {string} firstDayString an ISO 8601 formatted date string
     * @param {string} lastDayString an ISO 8601 formatted date string
     * @return {string[]} an array of ISO 8601 formatted date strings in the range from firstDayString to lastDayString
     */
    getAllowedDatesArray (firstDayString, lastDayString) {
      const firstDay = new Date(firstDayString).valueOf()
      const oneDayLater = 24 * 60 * 60 * 1000
      const allowedDatesArray = [firstDayString]
      let nextDayString = new Date(firstDay + oneDayLater).toISOString().substr(0, 10)
      let nextDay = new Date(nextDayString).valueOf()
      let counter = 1
      while (nextDayString !== lastDayString) {
        allowedDatesArray.push(nextDayString)
        if (counter === 4) {
          nextDay += 3 * oneDayLater
          nextDayString = new Date(nextDay).toISOString().substr(0, 10)
          counter = 0
        } else {
          nextDay += oneDayLater
          nextDayString = new Date(nextDay).toISOString().substr(0, 10)
          counter++
        }
      }
      allowedDatesArray.push(lastDayString)
      return allowedDatesArray
    }
  }
}
</script>
