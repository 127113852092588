<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" :disabled="disabled" @click="renderDoc" color="primary" class="mr-3">
        <v-icon>
          mdi-file-word-box-outline
        </v-icon>
      </v-btn>
    </template>
    lade eine Datei (.docx) eines<br>
    Schülerzeugnis herunter.
  </v-tooltip>
      <!--
      <icon>mdi-file-word</icon>
      <icon>mdi-file-word-box</icon>
      <icon>mdi-file-word</icon>
      <icon>mdi-file-word-outline</icon>
      <icon>mdi-printer</icon>
      <icon>mdi-printer-outline</icon>
      <icon>mdi-fingerprint</icon>
      <icon>mdi-cloud-print</icon>
      -->
</template>

<script>
import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'
import { saveAs } from 'file-saver'

export default {
  props: [
    'disabled',
    'reportObj',
    'studentObj'
  ],
  methods: {
    transform (string) {
      let newString = ''
      let first = true
      for (const char of string) {
        if (char === '"' && first) {
          newString = `${newString}${String.fromCharCode(0x201e)}`
          first = false
        } else if (char === '"' && !first) {
          newString = `${newString}${String.fromCharCode(0x201c)}`
          first = true
        } else {
          newString = `${newString}${char}`
        }
      }
      return newString
    },
    getZielString (ziel) {
      const zielSubString = ziel > 9 ? 'Realschulabschlusses' : 'Hauptschulabschlusses'
      return this.reportObj.gymOrOs === 'os' && this.reportObj.klasse >= 7
        ? `nahm am Unterricht mit dem Ziel des ${zielSubString} teil.`
        : ''
    },
    getKlasseString () {
      let klasse = this.checkInput(this.reportObj.klasse)
      if (this.reportObj.klasseZusatz !== undefined) {
        klasse = `${klasse}${this.reportObj.klasseZusatz}`
      }
      return klasse
    },
    checkInput (value) {
      if (value === null) return 0
      // return (value === undefined || value === null) ? 'kA' : value
      return (value === undefined) ? 'kA' : value
    },
    checkKey (gradeObj) {
      if (gradeObj !== undefined) {
        if (
          this.reportObj.klasse === 10 &&
          this.reportObj.gymOrOs === 'os' && (
            gradeObj.name === 'Gemeinschaftskunde/Rechtserziehung' ||
            gradeObj.name === 'Geographie' ||
            gradeObj.name === 'Geschichte' ||
            gradeObj.name === 'Kunst' ||
            gradeObj.name === 'Musik'
          )
        ) return gradeObj.value !== null ? gradeObj.value : '-'
        else if (this.reportObj.gymOrOs === 'os' && gradeObj.name === 'Französisch') {
          return gradeObj.value !== null ? gradeObj.value : ''
        } else if (
          this.reportObj.klasse === 10 &&
          this.reportObj.gymOrOs === 'os' &&
          gradeObj.name === 'Wirtschaft-Technik-Haushalt/Soziales_9'
        ) {
          return gradeObj.value !== null ? gradeObj.value : '-'
        } else return gradeObj.value !== null ? gradeObj.value : 'tg'
        // return gradeObj.value !== null ? gradeObj.value : '!?'
      } else return '-'
    },
    checkProfilName (key) {
      return (key !== undefined) ? key.name : '-'
    },
    checkName (key) {
      if (key !== undefined) {
        return key.value !== null ? key.name : ''
      } else return ''
      // return (key !== undefined) ? key.name : '-'
    },
    checkSeit (key) {
      if (key !== undefined) {
        return key.value !== null ? key.note : ''
      } else return '(abschlussorientiert)'
      // return (key !== undefined) ? key.name : '-'
    },
    renderDoc () {
      const printSmall = this.studentObj.printSmall ? '_s' : ''
      const hjString = this.reportObj.hj ? '_hj' : ''
      const ejString = this.reportObj.ej ? '_ej' : ''
      const is10OS = this.reportObj.klasse === 10 && this.reportObj.gymOrOs === 'os'
      const zeString = this.reportObj.hj && is10OS ? '_ze' : ''
      const azString = this.reportObj.ej && is10OS ? '_az' : ''
      PizZipUtils.getBinaryContent(`https://noten.dpfa.de/${this.reportObj.gymOrOs}${hjString}${ejString}${zeString}${azString}${printSmall}.docx`, this.printCallback)
    },
    printCallback (error, content) {
      if (error) {
        throw error
      }
      const zip = new PizZip(content)
      const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true })

      doc.setData({
        hj: this.reportObj.hj,
        ej: this.reportObj.ej,
        // schuleName1: `DPFA-Regenbogen-${this.reportObj.gymOrOs === 'os' ? 'Oberschule' : 'Gymnasium'} Zwickau`,
        // schuleName2: 'Staatlich anerkannte Ersatzschule',
        schuleName1: this.reportObj.schuleName.split(', ')[0],
        schuleName2: this.reportObj.schuleName.split(', ')[1],
        schuleStrasze: 'Salutstraße 4',
        schulePLZ: '08066',
        schuleOrt: 'Zwickau',
        kl: this.getKlasseString(),
        schuljahr: this.checkInput(this.reportObj.schuljahr),
        vorname: this.checkInput(this.studentObj.vorname),
        name: this.checkInput(this.studentObj.name),
        gebDatum: this.checkInput(this.studentObj.gebDatum),
        gebOrt: this.checkInput(this.studentObj.gebOrt),
        strasze: this.checkInput(this.studentObj.strasze),
        plz: this.checkInput(this.studentObj.plz),
        ort: this.checkInput(this.studentObj.ort),
        ziel: this.getZielString(this.studentObj.ziel),
        be: this.checkInput(this.studentObj.betragen),
        mit: this.checkInput(this.studentObj.mitarbeit),
        fl: this.checkInput(this.studentObj.fleisz),
        ord: this.checkInput(this.studentObj.ordnung),
        // kopfStern: this.studentObj.stern === '1' ? '*' : '',
        einschaetzung: this.checkInput(this.studentObj.einschaetzung),
        de: this.checkKey(this.studentObj.fachnoten.de),
        ma: this.checkKey(this.studentObj.fachnoten.ma),
        en: this.checkKey(this.studentObj.fachnoten.en),
        bio: this.checkKey(this.studentObj.fachnoten.bio),
        // frName: this.checkKey(this.studentObj.fachnoten.fr) !== '!?' ? this.checkName(this.studentObj.fachnoten.fr) : '',
        // fr: this.checkKey(this.studentObj.fachnoten.fr) !== '!?' ? this.checkKey(this.studentObj.fachnoten.fr) : '',
        frName: this.checkName(this.studentObj.fachnoten.fr),
        fr: this.checkKey(this.studentObj.fachnoten.fr),
        frSeit: this.checkSeit(this.studentObj.fachnoten.fr),
        ch: this.checkKey(this.studentObj.fachnoten.ch),
        ku: this.checkKey(this.studentObj.fachnoten.ku),
        ph: this.checkKey(this.studentObj.fachnoten.ph),
        mu: this.studentObj.fachnoten.mu !== undefined ? this.checkKey(this.studentObj.fachnoten.mu) : 'tg',
        spo: this.checkKey(this.studentObj.fachnoten.spo),
        ge: this.checkKey(this.studentObj.fachnoten.ge),
        eth: this.checkKey(this.studentObj.fachnoten.eth),
        grw: this.checkKey(this.studentObj.fachnoten['g/r/w']),
        gk: this.checkKey(this.studentObj.fachnoten.gk),
        tc: this.checkKey(this.studentObj.fachnoten.tc),
        geo: this.checkKey(this.studentObj.fachnoten.geo),
        inf: this.checkKey(this.studentObj.fachnoten.inf),
        wth: this.checkKey(this.studentObj.fachnoten.wth),
        wth2: this.checkKey(this.studentObj.fachnoten.wth2),
        mkName: this.checkName(this.studentObj.fachnoten.mk),
        mk: this.checkKey(this.studentObj.fachnoten.mk),
        mk2Name: this.checkName(this.studentObj.fachnoten.mk2),
        mk2: this.checkKey(this.studentObj.fachnoten.mk2),
        gggName: this.checkName(this.studentObj.fachnoten.ggg),
        ggg: this.checkKey(this.studentObj.fachnoten.ggg),
        profilName: this.checkProfilName(this.studentObj.fachnoten.nawi),
        profil: this.checkKey(this.studentObj.fachnoten.nawi),
        fE: this.checkInput(this.studentObj.fehlE),
        fUE: this.checkInput(this.studentObj.fehlUE),
        versetzung: `${this.checkInput(this.studentObj.vorname)} wird ${this.studentObj.versetzung ? 'versetzt.' : 'nicht versetzt.'}`,
        bemerkungen: '',
        // gta: this.transform(this.studentObj.gta),
        gta: this.studentObj.gta,
        religionText: this.studentObj.rel !== undefined ? this.studentObj.rel.text : '',
        // relName: this.studentObj.rel !== undefined ? this.studentObj.rel.name : '',
        // rel: this.studentObj.rel !== undefined ? this.studentObj.rel.value : '',
        datum: this.checkInput(this.reportObj.datum)
        // fusznote: this.checkStern(this.studentObj.stern)
      })
      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render()
      } catch (error) {
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        console.log(JSON.stringify({ error: error }, replaceErrors))

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation
            })
            .join('\n')
          console.log('errorMessages', errorMessages)
          // errorMessages is a humanly readable message looking like this :
          // 'The tag beginning with "foobar" is unopened'
        }
        throw error
      }
      const out = doc.getZip().generate({
        type: 'blob',
        compression: 'DEFLATE',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      })
      // https://stackoverflow.com/questions/64345139/how-do-i-create-a-zip-file-with-multiple-docx-files-generated-by-the-docxtempla
      // Output the document using Data-URI
      let filename
      if (this.reportObj.klasse !== undefined && this.studentObj.name !== undefined) {
        const firstName = this.studentObj.vorname.toLowerCase()
        const name = this.studentObj.name.toLowerCase()
        filename = `${this.reportObj.klasse}${this.reportObj.gymOrOs}_${name}_${firstName}`
      } else {
        filename = 'output'
      }
      saveAs(out, `${filename}.docx`)
    }
  }
}

function replaceErrors (key, value) {
  if (value instanceof Error) {
    return Object.getOwnPropertyNames(value).reduce(function (
      error,
      key
    ) {
      error[key] = value[key]
      return error
    },
    {})
  }
  return value
}
</script>
