<template>
  <table v-if="componentType === 'grade' || componentType === 'meta' || componentType === 'head'">
    <tbody>
      <tr v-if="componentType === 'grade' || componentType === 'meta'">
        <td :colspan="isSek2 ? 16 : 6" v-if="type === '' || isBB">{{ topic }}</td>
        <template v-else>
          <td :colspan="isSek2 ? 8 : 3"><strong>{{ type }}</strong></td>
          <td :colspan="isSek2 ? 8 : 3"><em>{{ topic }}</em></td>
        </template>
      </tr>

      <tr v-if="componentType === 'grade' || componentType === 'meta'">
        <td :colspan="isSek2 ? 8 : 3">Lehrer:</td>
        <td :colspan="isSek2 ? 8 : 3">{{ firstName }} {{ lastName }}</td>
      </tr>

      <tr v-if="(componentType === 'grade' || componentType === 'meta') && note !== undefined">
        <td :colspan="isSek2 ? 16 : 6"><em>{{ note }}</em></td>
      </tr>

      <tr v-if="componentType === 'grade' || componentType === 'meta'">
        <td :colspan="isSek2 ? 8 : 3">geschrieben:</td>
        <td :colspan="isSek2 ? 8 : 3"><em>{{ new Date(testedAt).toLocaleString().split(',')[0] }}</em></td>
      </tr>

      <tr v-if="componentType === 'grade' || componentType === 'meta'">
        <td :colspan="isSek2 ? 8 : 3">zurückgegeben:</td>
        <td :colspan="isSek2 ? 8 : 3"><em>{{ new Date(backAt).toLocaleString().split(',')[0] }}</em></td>
        <!-- <em :style="`color: ${getTooLong(header.testedAt, header.backAt)};`"> -->
      </tr>

      <tr v-if="showOverview() && !isSek2">
        <td width="25">1</td><td width="25">2</td><td width="25">3</td><td width="25">4</td><td width="25">5</td><td width="25">6</td>
      </tr>

      <tr v-if="showOverview() && !isSek2">
        <td>{{ classOverview['1'] }}x</td>
        <td>{{ classOverview['2'] }}x</td>
        <td>{{ classOverview['3'] }}x</td>
        <td>{{ classOverview['4'] }}x</td>
        <td>{{ classOverview['5'] }}x</td>
        <td>{{ classOverview['6'] }}x</td>
      </tr>
      <tr v-if="showOverview() && !isSek2">
        <td colspan="3"/>
        <td colspan="1"><strong>&empty;</strong></td>
        <td colspan="2">
          <!-- <strong :style="`color: ${getColor(classMedian)};`"> -->
          <strong :style="`color: ${classMedianColor};`">
            {{ classMedian }}
          </strong>
        </td>
      </tr>

      <tr v-if="showOverview() && isSek2">
        <td width="25">15</td><td width="25">14</td><td width="25">13</td><td width="25">12</td><td width="25">11</td><td width="25">10</td><td width="25">9</td><td width="25">8</td><td width="25">7</td><td width="25">6</td><td width="25">5</td><td width="25">4</td><td width="25">3</td><td width="25">2</td><td width="25">1</td><td width="25">0</td>
      </tr>

      <tr v-if="showOverview() && isSek2">
        <td>{{ classOverview['15'] }}x</td>
        <td>{{ classOverview['14'] }}x</td>
        <td>{{ classOverview['13'] }}x</td>
        <td>{{ classOverview['12'] }}x</td>
        <td>{{ classOverview['11'] }}x</td>
        <td>{{ classOverview['10'] }}x</td>
        <td>{{ classOverview['9'] }}x</td>
        <td>{{ classOverview['8'] }}x</td>
        <td>{{ classOverview['7'] }}x</td>
        <td>{{ classOverview['6'] }}x</td>
        <td>{{ classOverview['5'] }}x</td>
        <td>{{ classOverview['4'] }}x</td>
        <td>{{ classOverview['3'] }}x</td>
        <td>{{ classOverview['2'] }}x</td>
        <td>{{ classOverview['1'] }}x</td>
        <td>{{ classOverview['0'] }}x</td>
      </tr>
      <tr v-if="showOverview() && isSek2">
        <td colspan="8"/>
        <td colspan="1"><strong>&empty;</strong></td>
        <td colspan="7">
          <!-- <strong :style="`color: ${getColor(classMedian)};`"> -->
          <strong :style="`color: ${classMedianColor};`">
            {{ classMedian }}
          </strong>
        </td>
      </tr>

      <tr v-if="dontShowOverview()">
        <td colspan="6" style="text-align: center;">keine erteilten Noten</td>
      </tr>

    </tbody>
  </table>
  <!-- <span v-else-if="componentType === 'external'"> -->
  <span v-else-if="componentType === 'externalGrade'">
    externe Note<br>
    keine Daten verfügbar
  </span>
</template>

<script>

export default {
  name: 'GradeOverviewTable',

  props: {
    // external: Boolean,
    componentType: String,
    type: String,
    topic: String,
    firstName: String,
    lastName: String,
    note: { default: undefined, type: String },
    testedAt: String,
    backAt: String,
    value: { default: 0, type: Number },
    classOverview: Object,
    classMedian: String,
    classMedianColor: String,
    isSek2: Boolean,
    isBB: Boolean
  },
  methods: {
    showOverview () {
      return this.value !== null || (this.value === null && this.classMedian !== 'NaN')
    },
    dontShowOverview () {
      return this.value === null && this.classMedian === 'NaN'
    }
  }
}
</script>
