<template>
  <v-container v-if="$keycloak !== undefined && $keycloak.authenticated">
    <v-row>
      <v-col class="mt-4">
        <v-card>
          <v-card-title class="d-flex justify-end">
            Anwesenheit
            <v-spacer />
            <div>
              <v-menu
                ref="dateSelector"
                v-model="show"

                :return-value.sync="myDate"
                width="300px"
                transition="scroll-x-reverse-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    append-icon="mdi-calendar"
                    readonly
                    style="width: 290px;"
                    label="Datum:"
                    hide-details

                    v-on="on"
                    v-bind="attrs"
                    v-model="myDate"
                    :value="selectedDatum.datum"
                    return-object

                  ></v-text-field>

                </template>
                <v-date-picker
                  v-model="myDate"
                  first-day-of-week="1"
                  show-week
                  :allowed-dates="allowedDates"
                  scrollable
                  @click:date="clicked"
                  :min="datumListe.map(e => e.date).reduce((a, b) => (b < a) ? b : a)"
                  :max="datumListe.map(e => e.date).reduce((a, b) => (b > a) ? b : a)"
                >
                  <v-spacer></v-spacer>
                  <!-- <v-btn color="error" icon @click="show = false" :disabled="entry[which].isSending"> -->
                  <v-btn color="error" icon @click="show = false">
                    <v-icon>mdi-cancel</v-icon>
                  </v-btn>
                  <!-- <v-btn color="success" icon @click="() => clicked(which)" :disabled="entry[which].isSending"> -->
                  <v-btn color="success" icon @click="clicked">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
            <!-- <v-select
              class="v-select"
              hide-details
              hidden
              v-model="selectedDatum"
              :items="datumListe"
              return-object
              @change="() => selectedFach = selectedDatum.faecher[0]"
            ></v-select> -->
          </v-card-title>
          <v-card-subtitle class="d-flex justify-end">
            <div>
            <v-select
              v-if="selectedDatum.value !== undefined"
              label="Stunde:"
              style="width: 290px;"
              v-model="selectedFach"
              :items="datumListe[selectedDatum.value].faecher"
              return-object
            ></v-select>
            </div>
          </v-card-subtitle>
          <v-container>
            <v-row>
              <v-col xl="10" lg="9" cols="12">
                <v-card outlined>
                  <v-container v-if="isReady">
                    <v-row v-for="i in [0, 1, 2]" :key="`row${i}`">
                      <v-col v-for="j in [0, 1, 2, 3, 4, 5, 6, 7]" :key="`col${8*i+j}`" class="d-flex justify-center align-center">
                        <v-hover v-if="getSchuelerIndex(i,j) !== -1">
                          <template v-slot:default="{ hover }">
                            <v-card
                              class="d-flex justify-center align-center"
                              shaped
                              :style="setStyle(hover, getSchueler(i, j).isPresent)"
                              :color="getSchueler(i, j).isPresent ? 'primary' : 'error'"
                              :height="getCardSize()"
                              :width="getCardSize()"
                              @click="toggleIsPresent(i, j)">
                              <v-avatar :size="getAvatarSize(getSchueler(i, j).isPresent)" :key="`av${8*i+j}`">
                                <!-- <v-img :src="`https://randomuser.me/api/portraits/women/${56+8*i+j}.jpg`" :key="`im${8*i+j}`">
                                </v-img> -->
                                <v-img v-if="getSchueler(i,j).src !== undefined" :src="getSchueler(i, j).src" :key="`im${8*i+j}`">
                                  <span class="d-flex justify-start align-end ml-1 mb-1">
                                    {{ `${getSchueler(i, j).lastName.substr(0,4)}${getSchueler(i, j).firstName.substr(0,3)}` }}
                                  </span>
                                </v-img>
                                <span v-else :class="getSchueler(i, j).isPresent ? 'white--text' : 'black--text'" v-html="`${getSchueler(i,j).firstName}<br>${getSchueler(i,j).lastName}`"/>
                              </v-avatar>
                            </v-card>
                          </template>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col xl="2" lg="3" cols="4">
                <v-card outlined class=" d-flex justify-center">
                  <v-select
                    v-if="isReady"
                    class="v-select"
                    hide-details
                    label="Abwesenheitsliste"
                    multiple
                    v-model="datumListe[selectedDatum.value].faecher[selectedFach.value].selectedSchueler"
                    :items="datumListe[selectedDatum.value].faecher[selectedFach.value].schueler"
                    return-object
                    @change="listChanged"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @mousedown.prevent
                        @click="toggleAll"
                      >
                        <v-list-item-action>
                          <v-icon :color="datumListe[selectedDatum.value].faecher[selectedFach.value].selectedSchueler.length > 0 ? 'primary' : ''">
                            {{ icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            ALLE Abwesend
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-chip pill>
                        <v-avatar>
                          <v-img :src="item.src">
                          </v-img>
                        </v-avatar>
                        {{ `${item.lastName}, ${item.firstName}` }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ChangeAttandanceFL',
  data: function () {
    return {
      isReady: false,
      show: false,
      myDate: '',
      selectedDatum: {},
      datumListe: [
        {
          text: '2022-02-20',
          date: '2022-02-20',
          value: 0,
          faecher: [
            { text: '1. Mathe 9OS___', value: 0, schueler: [], selectedSchueler: [] },
            { text: '3. Physik 7OS___', value: 1, schueler: [], selectedSchueler: [] },
            { text: '7. Physik 7GYM___', value: 2, schueler: [], selectedSchueler: [] }
          ]
        }, {
          text: '2022-02-21',
          date: '2022-02-21',
          value: 1,
          faecher: [
            { text: '5. Mathe 9OS___', value: 0, schueler: [], selectedSchueler: [] }
          ]
        }, {
          text: '2022-02-22',
          date: '2022-02-22',
          value: 2,
          faecher: [
            { text: '3. Physik 9OS___', value: 0, schueler: [], selectedSchueler: [] },
            { text: '5. Physik 10OS___', value: 1, schueler: [], selectedSchueler: [] },
            { text: '7. Physik 10GYM___', value: 2, schueler: [], selectedSchueler: [] }
          ]
        }
      ],
      selectedFach: {}
    }
  },
  mounted: function () {
    this.initialize()
  },
  computed: {
    allStudentsAbsent () {
      return this.getSelectedFach().selectedSchueler.length === this.selectedFach.schueler.length
    },
    someStudentsAbsent () {
      return this.getSelectedFach().selectedSchueler.length > 0 && !this.allStudentsAbsent
    },
    icon () {
      if (this.allStudentsAbsent) return 'mdi-close-box'
      if (this.someStudentsAbsent) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    initialize () {
      this.selectedDatum = this.datumListe[1]
      this.myDate = this.selectedDatum.date
      this.selectedFach = this.selectedDatum.faecher[0]
      for (const datum of this.datumListe) {
        for (const fach of datum.faecher) {
          const random = Math.floor(Math.random() * 100)
          for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 8; j++) {
              const value = 8 * i + j
              fach.schueler.push({
                firstName: `Vorname_${value}`,
                lastName: `Nachname_${value}`,
                text: `s${value}`,
                value,
                i,
                j,
                isPresent: true,
                src: `https://randomuser.me/api/portraits/women/${random + value}.jpg`
              })
            }
          }
        }
      }
      this.isReady = true
    },
    allowedDates (date) {
      return this.datumListe.map(e => e.date)
    },
    clicked () {
      const date = this.myDate
      this.$refs.dateSelector.save(date)
      this.selectedDatum = this.datumListe.find(e => e.date === date)
      this.selectedFach = this.selectedDatum.faecher[0]
    },
    getSchuelerIndex (i, j) {
      return this.selectedFach.schueler.findIndex(e => e.i === i && e.j === j)
    },
    getSchueler (i, j) {
      return this.selectedFach.schueler.find(e => e.i === i && e.j === j)
    },
    getSelectedSchuelerIndex (i, j) {
      return this.getSelectedFach().selectedSchueler.findIndex(e => e.i === i && e.j === j)
    },
    getSelectedFach () {
      return this.datumListe[this.selectedDatum.value].faecher[this.selectedFach.value]
    },
    setStyle (hover, isPresent) {
      const color = isPresent ? '#72ab12' : 'red'
      return hover ? `box-shadow: 0px 0px 30px 2px ${color};` : `box-shadow: 0px 0px 15px 1px ${color};`
    },
    toggleIsPresent (i, j) {
      if (this.getSchueler(i, j).isPresent) {
        this.getSelectedFach().selectedSchueler.push(this.getSchueler(i, j))
        this.getSelectedFach().selectedSchueler.sort((a, b) => a.value - b.value)
      } else {
        this.getSelectedFach().selectedSchueler.splice(this.getSelectedSchuelerIndex(i, j), 1)
      }
      this.getSchueler(i, j).isPresent = !this.getSchueler(i, j).isPresent
    },
    toggleAll () {
      this.$nextTick(() => {
        this.getSelectedFach().selectedSchueler = this.allStudentsAbsent ? [] : [...this.selectedFach.schueler]
        for (const schueler of this.selectedFach.schueler) {
          if (this.allStudentsAbsent) schueler.isPresent = false
          else schueler.isPresent = true
        }
      })
    },
    listChanged (schuelerListe) {
      const presentStudentsList = [...this.selectedFach.schueler]
      for (const schueler of schuelerListe) {
        this.getSchueler(schueler.i, schueler.j).isPresent = false
        presentStudentsList.splice(presentStudentsList.findIndex(e => e.i === schueler.i && e.j === schueler.j), 1)
      }
      schuelerListe.sort((a, b) => a.value - b.value)
      for (const schueler of presentStudentsList) {
        this.getSchueler(schueler.i, schueler.j).isPresent = true
      }
    },
    getWindowWidth () {
      return window.innerWidth
    },
    getCardSize () {
      return (this.getWindowWidth() / 16)
    },
    getAvatarSize (isPresent) {
      return (isPresent) ? (this.getWindowWidth() / 16) : (this.getWindowWidth() / 48)
    }
  }
}
</script>

<style .scoped>
.v-select {
  max-width: max-content;
}

.v-card-green-shadow {
  box-shadow: 0px 0px 20px #72ab12
}

</style>
