var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.note.show),callback:function ($$v) {_vm.$set(_vm.note, "show", $$v)},expression:"note.show"}},[_c('v-card',[(_vm.type==='new')?_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" neue Note definieren ")]):_vm._e(),(_vm.type==='change')?_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Notendefinition ändern ")]):_vm._e(),_c('v-card-text',[_c('v-container',[_c('v-row',[(_vm.isAB())?_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{staticClass:"maxWidth",attrs:{"items":['A', 'B']},on:{"input":function () { return _vm.checkTopic(_vm.type); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v("A/B"),_c('sup',[_vm._v("*")])])]},proxy:true}],null,false,4087523049),model:{value:(_vm.note.aOrB),callback:function ($$v) {_vm.$set(_vm.note, "aOrB", $$v)},expression:"note.aOrB"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.isAB() ? 8 : (_vm.isBB() ? 12 : 8)}},[_c('v-text-field',{attrs:{"error-messages":_vm.note.error.topic},on:{"input":function () { return _vm.checkTopic(_vm.type); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v("Thema"),_c('sup',[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.note.topic),callback:function ($$v) {_vm.$set(_vm.note, "topic", $$v)},expression:"note.topic"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{ref:"dialogGeschriebenNewCal",attrs:{"return-value":_vm.note.geschrieben,"width":"300px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.note, "geschrieben", $event)},"update:return-value":function($event){return _vm.$set(_vm.note, "geschrieben", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","hide-details":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v("geschrieben am")])]},proxy:true}],null,true),model:{value:(_vm.note.geschrieben),callback:function ($$v) {_vm.$set(_vm.note, "geschrieben", $$v)},expression:"note.geschrieben"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.note.showGeschriebenCal),callback:function ($$v) {_vm.$set(_vm.note, "showGeschriebenCal", $$v)},expression:"note.showGeschriebenCal"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","allowed-dates":function (date) { return _vm.allowedDates('tested', date); },"scrollable":""},on:{"input":function () {
                  if (_vm.checkTimelineError(_vm.type) === '') {
                    _vm.$refs.dialogGeschriebenNewCal.save(_vm.note.geschrieben)
                    _vm.note.showGeschriebenCal = false
                  }
                }},model:{value:(_vm.note.geschrieben),callback:function ($$v) {_vm.$set(_vm.note, "geschrieben", $$v)},expression:"note.geschrieben"}},[(_vm.checkTimelineError(_vm.type) !== '')?_c('v-alert',{attrs:{"dense":"","outlined":"","elevation":"5","type":"warning"}},[_vm._v(" "+_vm._s(_vm.checkTimelineError(_vm.type))+" ")]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.note.geschrieben !== undefined && _vm.note.zurueckgegeben !== undefined)?_c('v-dialog',{ref:"dialogZurueckgegebenNewCal",attrs:{"return-value":_vm.note.zurueckgegeben,"width":"300px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.note, "zurueckgegeben", $event)},"update:return-value":function($event){return _vm.$set(_vm.note, "zurueckgegeben", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"zurueckgegeben am","append-icon":"mdi-calendar","readonly":"","error-messages":_vm.note.error.timelineError},model:{value:(_vm.note.zurueckgegeben),callback:function ($$v) {_vm.$set(_vm.note, "zurueckgegeben", $$v)},expression:"note.zurueckgegeben"}},'v-text-field',attrs,false),on))]}}],null,false,2545848758),model:{value:(_vm.note.showZurueckgegebenCal),callback:function ($$v) {_vm.$set(_vm.note, "showZurueckgegebenCal", $$v)},expression:"note.showZurueckgegebenCal"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","allowed-dates":function (date) { return _vm.allowedDates('back', date); },"scrollable":""},on:{"input":function () {
                  if (_vm.checkTimelineError(_vm.type) === '') {
                    _vm.$refs.dialogZurueckgegebenNewCal.save(_vm.note.zurueckgegeben)
                    _vm.note.showZurueckgegebenCal = false
                  }
                }},model:{value:(_vm.note.zurueckgegeben),callback:function ($$v) {_vm.$set(_vm.note, "zurueckgegeben", $$v)},expression:"note.zurueckgegeben"}},[(_vm.checkTimelineError(_vm.type) !== '')?_c('v-alert',{attrs:{"dense":"","outlined":"","elevation":"5","type":"warning"}},[_vm._v(" "+_vm._s(_vm.checkTimelineError(_vm.type))+" ")]):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('small',{class:_vm.checkNull(_vm.type)},[_c('sup',[_vm._v("*")]),_vm._v("Feld wird benötigt")]),_c('v-spacer'),(_vm.type === 'new')?_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":_vm.resetNewGrade}},[_c('v-icon',[_vm._v("mdi-cancel")])],1):(_vm.type === 'change')?_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){_vm.note.show = false}}},[_c('v-icon',[_vm._v("mdi-cancel")])],1):_vm._e(),_c('v-btn',{attrs:{"color":"success","disabled":_vm.hasErrors(_vm.type) || (_vm.type === 'new' && _vm.note.isCreating),"icon":""},on:{"click":_vm.changeOrCreateGradeMeta}},[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }