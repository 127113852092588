<template>
  <v-container v-if="$keycloak !== undefined && $keycloak.authenticated">
    <v-row>
      <v-col class="mt-4">
        <v-card>
          <v-card-title class="d-flex justify-end">
            Sitzplan
            <v-spacer />
          </v-card-title>
          <v-container>
            <v-row>
              <!-- <v-col xl="10" lg="9" cols="12"> -->
              <v-col cols="12">
                <v-card outlined>
                  <v-container v-if="isReady">
                    <draggable v-for="i in [0, 1, 2]" v-model="sitzplan[i]" v-bind="dragOptions" :move="handleMove" @choose="handleDragChoose" @end="handleDragEnd"  class="row" :key="`row${i}`">
                      <v-col v-for="schueler in sitzplan[i]" :key="schueler.value" class="pt-0 pb-1 px-0 d-flex justify-center">
                        <v-card class="pt-1 pb-1 px-1">
                          <v-avatar
                            :size="getCardSize()"
                            :key="`av${schueler.value}`"
                            color="primary">
                            <v-img
                              v-if="schueler.src !== undefined"
                              :src="schueler.src"
                              :key="`im${schueler.value}`"
                            ></v-img>
                            <span
                              v-else
                              v-html="`${schueler.firstName[0]}${schueler.lastName[0]}`"/>
                          </v-avatar><br>
                          <v-toolbar flat dense>
                            {{schueler.firstName}}
                          </v-toolbar>
                        </v-card>
                      </v-col>
                    </draggable>

                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'ChangeAttandanceFL',
  components: {
    draggable
  },
  data: function () {
    return {
      isReady: false,
      faecher: [
        { text: 'Mathe 9OS', value: 0, schueler: [] },
        { text: 'Mathe 9OS', value: 1, schueler: [] },
        { text: 'Physik 7OS', value: 2, schueler: [] },
        { text: 'Physik 7GYM', value: 3, schueler: [] },
        { text: 'Physik 9OS', value: 4, schueler: [] },
        { text: 'Physik 10OS', value: 5, schueler: [] },
        { text: 'Physik 10GYM', value: 6, schueler: [] }
      ],
      selectedFach: {},
      sitzplan: [
        [{}, {}, {}, {}, {}, {}, {}, {}],
        [{}, {}, {}, {}, {}, {}, {}, {}],
        [{}, {}, {}, {}, {}, {}, {}, {}]
      ],
      drag: {
        firstElement: { src: '' },
        moving: { i: 0, j: 0, element: {} },
        future: { i: 0, j: 0, element: {} }
      }
    }
  },
  mounted: function () {
    this.initialize()
  },
  computed: {
    dragOptions () {
      return {
        animation: 100,
        group: 'sitzplan',
        disabled: false,
        // ghostClass: 'ghost',
        chosenClass: 'chosen',
        dragClass: 'drag'
      }
    }
  },
  methods: {
    initialize () {
      this.selectedFach = this.faecher[3]
      for (const fach of this.faecher) {
        const random = Math.floor(Math.random() * 100)
        for (let i = 0; i < 3; i++) {
          for (let j = 0; j < 8; j++) {
            const value = 8 * i + j
            const currentSchueler = {
              firstName: `Vorname_${value}`,
              lastName: `Nachname_${value}`,
              text: `s${value}`,
              value,
              i,
              j,
              src: `https://randomuser.me/api/portraits/women/${random + value}.jpg`
            }
            const leererPlatz = {
              firstName: 'Frei',
              lastName: 'Platz',
              text: `frei${value}`,
              value,
              i,
              j
            }
            fach.schueler.push(random + value > 80 ? leererPlatz : currentSchueler)
            this.sitzplan[i].splice(j, 1, random + value > 80 ? leererPlatz : currentSchueler)
          }
        }
      }

      this.isReady = true
    },
    handleMove ({ draggedContext, relatedContext }) {
      const { index: movingIndex, element: movingElement } = draggedContext
      this.drag.moving.element = movingElement
      this.drag.moving.i = movingElement.i
      this.drag.moving.j = movingIndex

      const { index: futureIndex, element: futureElement } = relatedContext
      this.drag.future.element = futureElement
      this.drag.future.i = futureElement.i
      this.drag.future.j = futureIndex

      draggedContext.element.src = relatedContext.element.src
      return false // disable sort
    },
    handleDragChoose (e) {
      const node = e.item.childNodes[0].childNodes[0].childNodes[0]
      if (node.childNodes[1] !== undefined) {
        this.drag.firstElement.src = node.childNodes[1].style['background-image']
      }
    },
    handleDragEnd () {
      const mI = this.drag.moving.i
      const mJ = this.drag.moving.j
      const mE = this.drag.moving.element
      mE.src = this.drag.firstElement.src.split('"')[1]
      const fI = this.drag.future.i
      const fJ = this.drag.future.j
      const fE = this.drag.future.element
      if (mI !== fI || mJ !== fJ) {
        mE.i = fI
        mE.value = mE.j = fJ
        fE.i = mI
        fE.value = fE.j = mJ
        this.selectedFach.schueler.splice(this.getSchuelerIndex(mI, mJ), 1, fE)
        this.sitzplan[mI].splice(mJ, 1)
        this.sitzplan[mI].splice(mJ, 0, fE)
        this.selectedFach.schueler.splice(this.getSchuelerIndex(fI, fJ), 1, mE)
        this.sitzplan[fI].splice(fJ, 1)
        this.sitzplan[fI].splice(fJ, 0, mE)
      }
    },
    getSchuelerIndex (i, j) {
      return this.selectedFach.schueler.findIndex(e => e.i === i && e.j === j)
    },
    getWindowWidth () {
      return window.innerWidth
    },
    getCardSize () {
      return (this.getWindowWidth() / 12)
    }
  }
}
</script>

<style .scoped>
.v-select {
  max-width: max-content;
}

.v-card-green-shadow {
  box-shadow: 0px 0px 20px #72ab12
}

.chosen {
  opacity: 0.75;
}

.drag {
  opacity: 100;
  box-shadow: 0px 0px 50px #72ab12
}
</style>
