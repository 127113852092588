var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$keycloak !== undefined && _vm.$keycloak.authenticated)?_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-4 mb-4"},[_c('v-card',[(_vm.possibleSelections !== undefined)?_c('v-card-title',{staticClass:"d-flex justify-end"},[_vm._v(" Notenübersicht "),_c('v-spacer'),_c('SaveAllGradesButton',{attrs:{"grades":_vm.grades,"allMetas":_vm.allMetas,"headers":_vm.headers,"selectionData":_vm.possibleSelections,"disabled":_vm.isLoading}}),(Object.keys(_vm.possibleSelections).length >= 1)?_c('v-select',{ref:"select1",staticClass:"v-select-fach mr-3",attrs:{"hide-details":"","items":Object.keys(_vm.possibleSelections),"label":"Schule:"},on:{"change":_vm.setClasses},model:{value:(_vm.select.school),callback:function ($$v) {_vm.$set(_vm.select, "school", $$v)},expression:"select.school"}}):_vm._e(),(_vm.possibleSelections[_vm.select.school] !== undefined &&
              Object.keys(_vm.possibleSelections[_vm.select.school]).length >= 1)?_c('v-select',{ref:"select2",staticClass:"v-select-fach mr-3",attrs:{"hide-details":"","items":Object.keys(_vm.possibleSelections[_vm.select.school]),"label":_vm.isBB() ? 'Jahrgang:' : 'Klasse:'},on:{"change":_vm.setCourses},model:{value:(_vm.select.class),callback:function ($$v) {_vm.$set(_vm.select, "class", $$v)},expression:"select.class"}}):_vm._e(),(_vm.possibleSelections[_vm.select.school] !== undefined &&
              _vm.possibleSelections[_vm.select.school][_vm.select.class] !== undefined &&
              _vm.possibleSelections[_vm.select.school][_vm.select.class].courses.length >= 1)?_c('v-select',{ref:"select3",staticClass:"v-select-fach mr-3",attrs:{"hide-details":"","items":_vm.possibleSelections[_vm.select.school][_vm.select.class].courses,"item-text":"courseName","item-value":"schoolClassCourseAbbr","return-object":"","label":"Fach:"},on:{"change":_vm.getHeaders},model:{value:(_vm.select.course),callback:function ($$v) {_vm.$set(_vm.select, "course", $$v)},expression:"select.course"}}):_vm._e()],1):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-center ml-1 mr-1 mt-1 mb-5"}):_vm._e(),(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_vm._e(),(!_vm.isLoading && _vm.select.class !== undefined && _vm.select.course !== undefined)?_c('v-data-table',{key:"key",attrs:{"name":"nw_data_table","height":_vm.windowHeight-330,"fixed-header":"","items-per-page":_vm.isPhone ? 1 : -1,"footer-props":{
            itemsPerPageOptions: _vm.isPhone ? [1, -1] : [3, 6, -1],
            itemsPerPageText: 'Schüler pro Seite:',
            itemsperpagealltext: 'Jeder'
          },"headers":_vm.currentHeader,"search":_vm.select.course.schoolClassCourseAbbr,"custom-filter":_vm.filterPerfectMatch,"items":_vm.grades,"sort-by":['lastName', 'firstName'],"multi-sort":"","loading":_vm.isLoading,"loading-text":"Ich lade die Noten.","no-data-text":"Es wurden noch keine Noten eingetragen!","no-results":"Es wurden keine Noten gefunden!"},scopedSlots:_vm._u([_vm._l((_vm.allMetas),function(meta){return {key:_vm.getHeaderSlotName(meta),fn:function(ref){
          var header = ref.header;
return [_c('MetaGradeHeader',{key:meta.id,attrs:{"header":header,"classOverview":header.overview,"classMedian":header.metaMedian,"classMedianColor":_vm.getColor(_vm.select.level, header.metaMedian),"isSek2":_vm.isSek2(),"isBB":_vm.isBB()}})]}}}),{key:"header.median",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',[_vm._v(" Schüler ∅ ")])])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.select.course.weights.A * 100)),_c('sub',[_vm._v("A")]),_vm._v(" ÷ "+_vm._s(_vm.select.course.weights.B * 100)),_c('sub',[_vm._v("B")])])]}},{key:"item.bild",fn:function(ref){
          var item = ref.item;
return [_c('div',{on:{"dblclick":function($event){if (_vm.isHeadMaster()) { _vm.$router.push({ name: 'ShowGradesPrismaSLExtension', params: { email: item.email } }) }}}},[_c('ProtectedAvatar',{key:item.id,staticClass:"mt-1 mb-1",style:(_vm.isHeadMaster() ? 'cursor: pointer;' : ''),attrs:{"sex":item.sex,"vintage":item.vintage,"oid":item.oid,"size":"40","random":_vm.isPlayground,"showContacts":""}})],1)]}},_vm._l((_vm.possibleSelections[_vm.select.school][_vm.select.class].courses),function(course){return {key:("item." + (course.schoolClassCourseAbbr)),fn:function(ref){
          var item = ref.item;
return [(item !== undefined && item[course.schoolClassCourseAbbr] && item[course.courseAbbr] !== 'OView')?_c('v-chip',{key:course.schoolClassCourseAbbr,attrs:{"label":"","color":_vm.getColor(_vm.select.level, item[course.schoolClassCourseAbbr]),"text-color":_vm.getTextColor(_vm.select.level, item[course.schoolClassCourseAbbr])}},[_vm._v(" "+_vm._s(Number(item[course.schoolClassCourseAbbr]).toFixed(1))+" ")]):_vm._e()]}}}),_vm._l((_vm.possibleSelections[_vm.select.school][_vm.select.class].courses),function(course){return {key:("item." + (course.schoolClassCourseAbbrEJ)),fn:function(ref){
          var item = ref.item;
return [(item !== undefined && item[course.schoolClassCourseAbbrEJ] && item[course.courseAbbrEJ] !== 'OView_EJ')?_c('v-chip',{key:course.schoolClassCourseAbbrEJ,attrs:{"label":"","color":_vm.getColor(_vm.select.level, item[course.schoolClassCourseAbbrEJ]),"text-color":_vm.getTextColor(_vm.select.level, item[course.schoolClassCourseAbbrEJ])}},[_vm._v(" "+_vm._s(item[course.schoolClassCourseAbbrEJ])+" ")]):_vm._e()]}}}),{key:"item.median",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(_vm.select.level, item.median),"text-color":_vm.getTextColor(_vm.select.level, item.median)}},[_vm._v(" "+_vm._s(item.median)+" ")])]}}],null,true)}):_vm._e()],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }