<template>
  <v-container v-if="$keycloak !== undefined && $keycloak.authenticated">
    <v-row>
      <v-col class="mt-4 mb-4">
        <v-card>
          <v-card-title>
            <span v-if="$refs.calendar !== undefined && isReady && events.length > 0">
              Stundenplan - {{ $refs.calendar.title }}
            </span>
            <span v-else-if="!isReady">
              Stundenplan - loading
              <v-progress-circular
                :size="20"
                color="primary"
                indeterminate
                class="ml-1"
              ></v-progress-circular>
            </span>
            <span v-else-if="isReady && events.length === 0">
              Stundenplan - derzeit keine Pläne verfügbar
            </span>
            <v-spacer />
            <v-btn
              color="primary"
              class="mr-3"
              @click="() => stepWeek('-')"
              >
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="mr-3"
              @click="() => stepWeek(null)"
              >
              <v-icon >
                mdi-calendar-today
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="mr-3"
              @click="() => stepWeek('+')"
              >
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-calendar
            ref="calendar"
            :now="today"
            :events="events"
            color="primary"
            type="week"
            :weekdays="weekday"
            :max-days="day"
            first-interval="7"
            interval-count="10"
            :start="start"
          >
            <template slot='event' slot-scope="props">
              <!-- <div style="background-color: props.backgroundColor; padding-left: 5px; padding-right: 5px"> -->
              <div :style="`color: ${props.eventParsed.input.textColor}; padding-left: 5px; padding-right: 5px;`">
                <span>
                  {{ props.eventParsed.start.time }} - {{ props.eventParsed.end.time }} {{ getName(props.eventParsed.input) }}
                </span>
                <span style="float: right">
                  {{ getRoom(props.eventParsed.input) }}
                </span>

                <br>
                <!-- <span style="float: left">
                  {{ props.eventParsed.input.room }}
                </span> -->
                <span style="float: right">
                  {{ props.eventParsed.input.teacher }}
                </span>
              </div>
            </template>
          </v-calendar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const config = require(`/config/${process.env.VUE_APP_CONFIG_NAME}`)

export default {
  data () {
    return {
      isReady: false,
      weekday: [1, 2, 3, 4, 5],
      // today: '2022-04-11',
      today: this.getTodayDateISOString(),
      day: 5,
      // start: '2022-04-11',
      start: this.getStartDateISOString(),
      // end: '2022-04-15',
      events: [
        // {
        //   name: 'Weekly Meeting',
        //   start: '2022-04-11 08:00',
        //   end: '2022-04-11 09:30',
        //   teacher: 'BauAn',
        //   room: '1.08'
        // },
        // {
        //   name: 'Weekly Meeting',
        //   start: '2022-04-18 09:00',
        //   end: '2022-04-18 10:00',
        //   teacher: 'abc',
        //   room: '1.08'
        // }
      ],
      loadedEvents: [],

      odataEvents: [],
      prismaEvents: [],
      loadedOdataEvents: [],
      loadedPrismaEvents: []
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    getName (input) {
      return input.name.length > 10 ? input.abbr : input.name
    },
    getRoom (input) {
      const splittedString = input.room.split(' ')
      return splittedString[0] !== input.room ? splittedString[0] : input.room
    },
    async initialize () {
      this.initTimetableFromPrisma()
      this.initTimetableFromOdata()
      // this.$refs.calendar.checkChange() - what is this going to do?
      this.isReady = true
    },
    concatPrismaEvents (events) {
      for (const event of events) {
        const start = event.start.split('T')
        const end = event.end.split('T')
        const teacher = `${event.teacher.lastName}, ${event.teacher.firstName}`
        this.prismaEvents.push({
          id: event.oid,
          name: event.courseType.name,
          abbr: event.courseType.abbr,
          start: `${start[0]} ${start[1].substring(0, 5)}`,
          end: `${end[0]} ${end[1].substring(0, 5)}`,
          teacher,
          room: event.room
        })
      }
    },
    concatODataEvents (events) {
      for (const event of events) {
        const id = event._OID
        const name = event.assFach?.Name ?? '-'
        const abbr = event.assFach?.Kuerzel ?? '-'
        const start = `${event.Datum ?? '-'} ${event.ZeitVon ?? '-'}`
        const end = `${event.Datum ?? '-'} ${event.ZeitBis ?? '-'}`
        const person = event.assDozent?.assPerson
        const teacher = `${person?.Name ?? '-'}, ${person?.Vorname ?? '-'}`
        const room = event.assRaum?.Bez ?? '-'
        const color = event.assFach !== null ? this.convertColor(event.assFach.Farbe) : 'primary'
        const textColor = 'black'
        this.odataEvents.push({ id, name, abbr, start, end, teacher, room, color, textColor })
      }
    },
    async initTimetableFromPrisma () {
      const url = config.api_get_timetable
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        const res = await this.$axios.get(url, axiosConfig)
        this.concatPrismaEvents(res.data)
        const startDate = new Date(this.start)
        const loadedDates = { before: this.getISODate(this.getWeekBefore(startDate)), start: this.start, after: this.getISODate(this.getWeekAfter(startDate)) }
        this.loadedPrismaEvents = this.loadedEvents.concat([loadedDates.before, loadedDates.start, loadedDates.after])
        if (!this.loadedOdataEvents.includes(loadedDates.before) ||
          !this.loadedOdataEvents.includes(loadedDates.start) ||
          !this.loadedOdataEvents.includes(loadedDates.after)
        ) {
          this.events = this.prismaEvents
        }
      } catch (err) {
        console.error(err)
      }
    },
    async initTimetableFromOdata () {
      const res = await this.getTimetableFromOData()
      this.concatODataEvents(res.data)
      const startDate = new Date(this.start)
      this.loadedOdataEvents = this.loadedEvents.concat([
        this.getISODate(this.getWeekBefore(startDate)),
        this.start,
        this.getISODate(this.getWeekAfter(startDate))
      ])
      for (const odataEvent of this.odataEvents) {
        const exitingEvent = this.events.find(event => event.id === odataEvent.id)
        if (exitingEvent !== undefined) {
          this.updateEvent(exitingEvent, odataEvent)
        } else {
          this.events.push(odataEvent)
          // TODO ... this should not be done, because every student is able to set the Timetable!
          // instead this should be done in BE
          // post event to prisma
        }
      }
      // find events (only in range week before until week after) which are not in odataEvents
      // delete those in prisma
    },

    /**
     * updates the old event if the new event has at least one different value
     */
    updateEvent (oldEvent, newEvent) {
      for (const prop of ['start', 'end', 'abbr', 'name', 'teacher', 'room', 'color']) {
        if (oldEvent[prop] !== newEvent[prop]) {
          oldEvent[prop] = newEvent[prop]
          // update event in prisma
        }
      }
    },
    async getTimetableFromOData (dateISOString) {
      dateISOString = dateISOString !== undefined ? `${dateISOString}` : 'start'
      const url = `${config.api_get_odata_timetable}/${dateISOString}`
      const axiosConfig = { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
      try {
        return await this.$axios.get(url, axiosConfig)
      } catch (err) {
        console.error(err)
      }
    },
    convertColor (integer) {
      let retString = ''
      for (let zeroCount = 1; zeroCount <= 6 - integer.toString(16).length; zeroCount++) {
        retString = `0${retString}`
      }
      return `#${retString}${integer.toString(16)}AA`
    },
    getStartDateISOString () {
      const weekday = new Date().getDay()
      if (weekday === 1) return this.getTodayDateISOString()
      else return this.getISODate(this.getLastMonday(new Date(this.getTodayDateISOString()), weekday))
    },
    getTodayDateISOString () {
      return this.getISODate(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
    },
    async stepWeek (which) {
      if (which === null) {
        this.start = this.getStartDateISOString()
      }
      if (which === '+') {
        // this.start = new Date(new Date(this.start).getTime() + 1000 * 60 * 60 * 24 * 7).toISOString().split('T')[0]
        this.start = this.getISODate(this.getWeekAfter(new Date(this.start)))
      } else if (which === '-') {
        // this.start = new Date(new Date(this.start).getTime() - 1000 * 60 * 60 * 24 * 7).toISOString().split('T')[0]
        this.start = this.getISODate(this.getWeekBefore(new Date(this.start)))
        const weekBeforeStartISOString = this.getISODate(this.getWeekBefore(new Date(this.start)))
        if (!this.loadedEvents.includes(weekBeforeStartISOString)) {
          this.isReady = false
          this.loadedEvents.push(weekBeforeStartISOString)
          const res = await this.getTimetableFromOData(weekBeforeStartISOString)
          this.concatODataEvents(res.data)
          this.isReady = true
        }
      }
    },
    getISODate (date) {
      return date.toISOString().split('T')[0]
    },
    getWeekAfter (date) {
      return new Date(date.getTime() + this.oneWeekInMilliseconds())
    },
    getWeekBefore (date) {
      return new Date(date.getTime() - this.oneWeekInMilliseconds())
    },
    getLastMonday (date, weekday) {
      return new Date(date.getTime() - this.calcMilliseconds(weekday))
    },
    oneWeekInMilliseconds () {
      return this.oneDayInMs() * 7
    },
    calcMilliseconds (weekday) {
      weekday = weekday === 0 ? 7 : weekday
      return (weekday - 1) * this.oneDayInMs()
    },
    oneDayInMs () { return 1000 * 60 * 60 * 24 }
  }
}
</script>

<style scope>
.theme--light.v-calendar-events .v-event-timed {
  color: black !important;
  /* border-color: black !important; */
}
</style>
